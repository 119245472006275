import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import './InvestmentDetail.scss';
import common from '../../common';
import TButton from '../../components/TButton';
import images from "../../images";
import axios from 'axios';
import { CSVLink, CSVDownload } from "react-csv";
import ContactSendMessageModal  from '../../components/ContactSendMessageModal';
import { setSummaryInvestmentData  } from '../../SummaryStartups/actions/summaryStartups';
class InterestedInvestmentDetailUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOptions: [
                { label: common.investmentStatuses.interested, value: common.investmentStatuses.interested },
                { label: common.investmentStatuses.notInterested, value: common.investmentStatuses.notInterested },
                { label: common.investmentStatuses.cancelled, value: common.investmentStatuses.cancelled }
            ],
            currentStatus: { label: common.investmentStatuses.interested, value: common.investmentStatuses.interested },
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
                amount: 'down',
                cancelledDate: 'down',
                notInterestedDate: 'down',
                interestedDate: 'down',
            },
            $sort: null,
            listOfUsers:[],
            exportLoading:false,
        }
        this.csvLink = React.createRef();
    }

    componentDidMount() {
        
        this.props.resetCheckBox();
        this.props.setSummaryInvestmentData(this.props.investmentData);
        if (this.props.id) {
            this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=interested`)
        }
        window.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.props.pagination.currentPage < this.props.pagination.totalPages)) {
            this.props.getUsersByStatus(this.state.currentStatus.value, true, this.state.currentStatus.value);
        }
    };
    
    mapDateFormat(mongoDate) {
        if (!mongoDate) return null;
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }

    handleOnDropdownChanged(event) {
        const status = event.value;
        this.props.getUsersByStatus(status, false, this.state.currentStatus);
        const arrowType = {
            name: 'down',
            emailAddress: 'down',
            company: 'down',
            amount: 'down',
            cancelledDate: 'down',
            notInterestedDate: 'down',
            interestedDate: 'down',
        };
        this.setState({ currentStatus: event, arrowType,  $sort: null });
    }

    onRemindClick(userId) {
        this.props.remindInterestedInvestors([{ _id: userId }]);
    }
    onReInviteClick(data) {
        this.props.onReInviteInvestment(data, 'interested')
    }

    getPreviousStatus = (status) => {
        switch (status) {
            case 'Participate':
                return 'Marked Interested';

            case 'Interested':
                return 'Generated Docs';

            default:
                return status;
        }
    };
    cancelledBy(data) {
        if (data && data.isCancelledByTSG) {
            return 'Cancelled by TSG'
        } else {
            return 'Cancelled';
        }
    }
    toggleArrow(fieldType) {
        let arrowType  = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] =  -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        this.props.getUsersByStatus(this.state.currentStatus.value, false, this.state.currentStatus.value, $sort);
        this.setState({arrowType, $sort });
    }

    interestStatusExportCSV(){
        
        let tokenpass = window.localStorage.getItem('token');
        const status = this.state.currentStatus;
        const data = {
            status: status.value,
            investmentId:this.props.id
        }
        
        console.log(data);

        this.setState({ listOfUsers: [], exportLoading:true });

        let getCSVUrl = null;
        axios.post(`${common.path.base_url}/getInterestStatusExportCSV`, data, {
            headers: {
                "Authorization": tokenpass
            }
        
        }).then((response) => {
            const UserArray = response.data.data;
            //console.log(UserArray);
            //const UserArray = contacts.userArray;
            let arr = [];
            
            var userExData = new Promise((resolve, reject) => {
                UserArray.forEach(function(val, index, UserArray) {
                    
                    let ObjCode = { 'First Name': (val.firstName) ? val.firstName : "",
                                    'Middle Name': (val.middleName) ? val.middleName : "",
                                    'Last Name': (val.lastName) ? val.lastName : "",     
                                    Email: (val.emailAddress) ? val.emailAddress : "",
                                    Company: (val.company) ? val.company : "-",
                                    Amount: (val.formattedAmount) ? "$"+val.formattedAmount : "$0.0",
                                    Tags: (val.user.investorCategories) ? val.user.investorCategories.join(', ') : "",  
                                };
                    arr.push(ObjCode);
                    if (index === UserArray.length -1) resolve();
                });
                
            });
            userExData.then(() => {
                this.setState({ listOfUsers: arr, exportLoading:false });
                this.csvLink.current.link.click();
            });

        }).catch(function (error) {
            console.log(error);
            this.setState({ listOfUsers: [], exportLoading:false });
        });
    }

    sendAllMessage() {
        //console.log(this.state.allChkEmail, this.state.allChkMobile);
        document.getElementById('openModal-btn').click();
    }

    resetSMSEmailCard() {
        window.location.reload();
    } 

    render() {
        const areCheckboxActive = this.props.getSelectedUsersByStatus().length > 0 ? true : false;

        let emailArr = [];
        let mobileArr = [];
        
        if( this.props.getSelectedUsersByStatus().length > 0 ) {
            
            this.props.getSelectedUsersByStatus().map(userLoop => {
                const users = userLoop.user;
                if( users.emailAddress != '' && users.emailAddress != 'undefined' && users.emailAddress != null ) {
                    emailArr.push( users.emailAddress );
                }

                if( users.mobile != '' && users.mobile != 'undefined' && users.mobile != null ) {
                    mobileArr.push( users.mobile );
                }
                
            });
            //this.setState({ allChkEmail : emailArr, allChkMobile : mobileArr });
        }
        //console.log(emailArr);

        return (
            <div className="box-wrapper-main">

                <TButton onClick={() => { }} id='openModal-btn' dataTarget={`#sendMessageModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <ContactSendMessageModal 
                    allCheckbox= { emailArr }
                    allSelectMobile = { mobileArr }
                    resetSMSEmailCard = { () => this.resetSMSEmailCard()}
                    webinarType="YES"
                    getCheckBoxAll = { this.props.getCheckBoxAll }
                    getRemoveCheckBox = { this.props.getRemoveCheckBox }
                    getSelectedCheckBox = { this.props.getSelectedCheckBox }
                    pageLocation = "interested"
                    totalCount = { this.props.getSelectedUsersByStatus().length }
                />

                <div className="row searchpaddingall">
                    <Select
                        id='investmentStatus'
                        options={this.state.dropdownOptions}
                        value={this.state.currentStatus}
                        onChange={(event) => this.handleOnDropdownChanged(event)}
                        className="col-md-10 col-lg-8"
                        style={{ marginTop:'1px' }}
                    />

                    <div className='follow_up' style={{ width: '10%', display:'inline-block' }}>
                        <div className='follow_up' style={{ width: '100%' }}>
                            <a onClick={() => this.interestStatusExportCSV()} className="tsg-btn invite export-btn" >
                                {
                                    this.state.exportLoading ? 
                                    <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </>
                                    :
                                    <><i className="fa fa-file-text ex-icon" aria-hidden="true" />EXPORT </>
                                }
                            </a>
                            <CSVLink 
                                data={ this.state.listOfUsers } 
                                filename={"Doc-Created-Contacts.csv"}
                                className="hidden"
                                ref={this.csvLink}
                                target="_blank"
                            />
                        </div>    
                    </div>

                    <div style={{ width: '10%', display:'inline-block', padding:'2px 14px' }} >
                        <TButton
                            text='Remind Selected'
                            className='invite-btn'
                            onClick={() => this.props.remindMultipleUsersByStatus()}
                            dataTarget={`#followUpModal`}
                            dataToggle={`modal`}
                            disabled={!areCheckboxActive}
                            style={{ padding:'21px 10px' }}
                        />
                    </div>
                    <div style={{ width: '10%', display:'inline-block', marginBottom:'0px' }} >
                        <button type='button' href='javascript:void(0)' className="tsg-btn invite sendMessBtn" onClick={(e) => this.sendAllMessage(e) } disabled={!areCheckboxActive} style={{  marginLeft: '10px' }} >
                            SEND MESSAGE
                        </button>
                    </div>    
                </div>
                <div className="custom-table-view table-responsive">
                    <table className="table table-custom">
                        <thead>
                            <tr>
                                {
                                    this.state.currentStatus.value === this.state.dropdownOptions[0].value &&
                                    <th>
                                        <input
                                            type="checkbox"
                                            onChange={(event) => this.props.onCheckedAllUsersByStatus(event)}
                                            checked={this.props.isAllUserByStatusChecked}
                                        />
                                        <label>&nbsp; &nbsp;All</label>
                                    </th>
                                }
                                <th>NAME <i className={`arrow ${this.state.arrowType['name']}`} onClick={() => this.toggleArrow('name')}></i></th>
                                <th>EMAIL <i className={`arrow ${this.state.arrowType['emailAddress']}`} onClick={() => this.toggleArrow('emailAddress')}></i></th>
                                <th>DATE

                                    {
                                        this.state.currentStatus.value === this.state.dropdownOptions[2].value ?
                                        <i className={`arrow ${this.state.arrowType['cancelledDate']}`} onClick={() => this.toggleArrow('cancelledDate')}></i>
                                        : this.state.currentStatus.value === this.state.dropdownOptions[1].value ?
                                        <i className={`arrow ${this.state.arrowType['notInterestedDate']}`} onClick={() => this.toggleArrow('notInterestedDate')}></i>:
                                        <i className={`arrow ${this.state.arrowType['interestedDate']}`} onClick={() => this.toggleArrow('interestedDate')}></i>
                                    }
                                </th>
                                     
                                <th>COMPANY <i className={`arrow ${this.state.arrowType['company']}`} onClick={() => this.toggleArrow('company')}></i></th>
                                <th>AMOUNT <i className={`arrow ${this.state.arrowType['amount']}`} onClick={() => this.toggleArrow('amount')}></i></th>
                                {
                                    this.state.currentStatus.value !== 'Cancelled' &&
                                    <th style={{ 'width': '120px' }}>CANCEL</th>
                                }
                                
                                {
                                    this.state.currentStatus.value === this.state.dropdownOptions[0].value &&
                                    <th>
                                        
                                    </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.usersByStatus.length > 0 &&
                                this.props.usersByStatus.map(invite => {
                                    return (
                                        <tr key={invite._id}>
                                            {
                                                this.state.currentStatus.value === this.state.dropdownOptions[0].value &&
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(event) => this.props.onCheckedSingleUserByStatus(event, invite.user._id)}
                                                        checked={invite.isChecked}
                                                    />
                                                </td>
                                            }
                                            <td className="username-row" 
                                            // onClick={() => this.props.history.push(`/viewProfile?id=${invite.user._id}`)}
                                            onClick={() => { this.props.showDataInPopup(invite.user._id) }}>
                                                {
                                                    this.state.currentStatus.value === this.state.dropdownOptions[2].value
                                                        ? `${invite.firstName} ${invite.lastName} (${this.getPreviousStatus(invite.previousStatus)})-${this.cancelledBy(invite)}`
                                                        : `${invite.firstName} ${invite.lastName}`
                                                }
                                            </td>
                                            <td>
                                                {invite.emailId}
                                            </td>
                                            <td>
                                                {
                                                    this.state.currentStatus.value === this.state.dropdownOptions[2].value
                                                        ? this.mapDateFormat(invite.cancelledDate)
                                                        : this.state.currentStatus.value === this.state.dropdownOptions[1].value ?
                                                        invite.notInterestedDate ? this.mapDateFormat(invite.notInterestedDate) : this.mapDateFormat(invite.createdAt):
                                                        this.mapDateFormat(invite.interestedDate)
                                                }
                                            </td>
                                            <td>
                                                {invite.investorCompanyName ? invite.investorCompanyName : invite.companyName ? invite.companyName : '-'}
                                            </td>
                                            <td>
                                                <span>
                                                    <span style={{marginLeft: '10px'}}>
                                                        <NumberFormat
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            value={invite.amount}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                        />
                                                        {/* <br /> */}
                                                    </span>
                                                    <span style={{ "display":'block', marginLeft: '10px' }}>
                                                        <a style={{ color: '#ef534a', cursor: 'pointer' }} onClick={() => this.props.adjustExceedAmount(invite)}>ADJUST</a>
                                                    </span>
                                                    {
                                                        invite.isRequestExceedAmount && 
                                                        <>
                                                            <img style={{width: '20px', height: '20px', objectFit: 'scale-down'}} src={images.path.upArrow} alt='' />
                                                            <br/>
                                                            <span>
                                                                <a style={{color: '#ef534a', cursor: 'pointer'}} onClick={() => this.props.adjustExceedAmount(invite)}>ADJUST</a> &nbsp; &nbsp;
                                                                <a style={{color: 'green', cursor: 'pointer'}} onClick={() => this.props.acceptExceedAmount(invite._id)}>ACCEPT</a>

                                                            </span>

                                                        </>
                                                    }
                                                </span>
                                            </td>
                                            <td>
                                                {
                                                    this.state.currentStatus.value !== this.state.dropdownOptions[2].value &&
                                                    <>
                                                        <TButton text='CANCEL' className='invite-btn'
                                                        onClick={() => this.props.onCancelInvestment(invite._id, this.state.currentStatus.value)}
                                                        />
                                                        
                                                        {/* <TButton text='Adjust Amount' className='invite-btn' style={{ 'text-transform':'uppercase','margin-left':'10px' }} onClick={() => this.props.adjustExceedAmount(invite)}
                                                        /> */}
                                                    </>  
                                                }
                                            </td>
                                            {
                                                this.state.currentStatus.value === this.state.dropdownOptions[0].value &&
                                                <td><TButton text='Remind' className='invite-btn' onClick={() => this.onRemindClick(invite.user._id)} /></td>
                                            }
                                            {
                                                this.state.currentStatus.value === 'Cancelled' &&
                                                <td>
                                                    <TButton text='RE-INVITE' className='invite-btn' onClick={() => this.onReInviteClick(invite)} />
                                                </td>

                                            }
                                            
                                        </tr>
                                    )
                                })
                            }
                            {
                                this.props.usersByStatus.length === 0 &&
                                <tr>
                                    <td> No investors found for invite!</td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSummaryInvestmentData: bindActionCreators(setSummaryInvestmentData, dispatch),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(InterestedInvestmentDetailUsers));