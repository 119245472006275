import React, { Component } from 'react';
import _ from 'lodash';
import '../../InvestmentDetail/InvestmentDetail.scss';
import axios from 'axios';
import common from '../../../common';
import swal from 'sweetalert';
import search from '../../../images/icons/search.svg';
import HeaderImage from '../../../components/HeaderImage';
import Header from '../../../components/Header';
import "./communication.scss";

class CommunicationFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            communicationHistory: [],
        }
    }

    componentWillMount() {
        this.setState({ userName: window.localStorage.getItem('username'), })
        this.setState({ Role: window.localStorage.getItem('role') });
        this.setState({ investmentcompanyname: window.localStorage.getItem('investmentCompanyName') });
        const url = new URL(window.location.href);
        this.InvestmentId = url.searchParams.get('InvestmentID');
        this.cronJobId = url.searchParams.get('communicationId');
    }

    componentDidMount() {
        if(this.InvestmentId){
            this.getInvestmentCommunicationsList();
        } else {
            this.getMCLCommunicationsList();
        }
    }

    getInvestmentCommunicationsList() {
        let roleCode = window.localStorage.getItem('roleCode');
        if (roleCode === '003' || roleCode === '004' || roleCode === '005') {
            window.location.href = '/';
            return;
        }

        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });

        const data = {
            investmentId : this.InvestmentId,
            cronJobId: this.cronJobId
        }

        axios.post(`${common.path.base_url}/getInvestmentCommunicationsList`, data, {
            headers: { "Authorization": token }
        }).then((response) => {
            temp.setState({ loading: false, communicationHistory: response.data.data});
        }).catch(function (error) {
            temp.setState({ loading: false });
            if (common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });
            } else {
                swal({
                    title: "Network Error!",
                    text: "Something went wrong",
                    icon: "error",
                    button: "Ok!",
                });
            }
            window.location.href = '/';
        });
    }
    getMCLCommunicationsList() {
        let roleCode = window.localStorage.getItem('roleCode');
        if (roleCode === '003' || roleCode === '004' || roleCode === '005') {
            window.location.href = '/';
            return;
        }

        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });

        const data = {
            cronJobId: this.cronJobId
        }

        axios.post(`${common.path.base_url}/getMCLCommunicationsList`, data, {
            headers: { "Authorization": token }
        }).then((response) => {
            temp.setState({ loading: false, communicationHistory: response.data.data});
        }).catch(function (error) {
            temp.setState({ loading: false });
            if (common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });
            } else {
                swal({
                    title: "Network Error!",
                    text: "Something went wrong",
                    icon: "error",
                    button: "Ok!",
                });
            }
            window.location.href = '/';
        });
    }

    searchInvestmentCommunicationsList = _.debounce((key) => {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });

        const data = { 
            investmentId : this.InvestmentId,
            cronJobId: this.cronJobId,
            searchValue: key 
        };

        axios.post(`${common.path.base_url}/getInvestmentCommunicationsList`, data, {
            headers: {
                "Authorization": token
            }
        }).then((response) => {
            temp.setState({ loading: false, communicationHistory: response.data.data });
        }).catch(function (error) {
            temp.setState({ loading: false });
            if (common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });
            } else {
                swal({
                    title: "Network Error!",
                    text: "Something went wrong",
                    icon: "error",
                    button: "Ok!",
                });
            }
            window.location.href = '/';
        });
    }, 800);

    searchMCLCommunicationsList = _.debounce((key) => {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });

        const data = { 
            cronJobId: this.cronJobId,
            searchValue: key 
        };

        axios.post(`${common.path.base_url}/getMCLCommunicationsList`, data, {
            headers: {
                "Authorization": token
            }
        }).then((response) => {
            temp.setState({ loading: false, communicationHistory: response.data.data });
        }).catch(function (error) {
            temp.setState({ loading: false });
            if (common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });
            } else {
                swal({
                    title: "Network Error!",
                    text: "Something went wrong",
                    icon: "error",
                    button: "Ok!",
                });
            }
            window.location.href = '/';
        });
    }, 800);

    render() {
        return (
            <div>
                <Header username={this.state.userName} role={this.state.Role}/>
                <HeaderImage heading={this.state.Role}/>
                <div className="wrapper">
                    <div className="management-main-content header-section dashboad-header-section">
                        <div className="management-title-content" style={{ alignContent: "end" }}>
                            <label className="header-title-txt">{this.InvestmentId ? "Investment" : "MCL"} Communication Histroy</label>
                            <h4 className="header-txt">{this.InvestmentId && this.state.investmentcompanyname}</h4>
                        </div>

                        <button className='btn btn-danger'
                            style={{ postion: "absolute", right: "0px", margin: "15px", }}
                            onClick={() => { this.InvestmentId ? this.getInvestmentCommunicationsList() : this.getMCLCommunicationsList() }}>
                            REFRESH
                        </button>
                    </div>
                </div>
                <div className="box-wrapper-main wrapper">
                    <div className='search addsearchposition'>
                        <form className="form-group" onSubmit={(event)=> event.preventDefault()}>
                            <input className="form-control mr-sm-2 custominput"
                                onChange={(event) => this.InvestmentId ? this.searchInvestmentCommunicationsList(event.target.value) : this.searchMCLCommunicationsList(event.target.value) }
                                type="search"
                                placeholder="Search"
                                aria-label="Search" />
                            <img alt="" src={search} className="search-icon" />
                        </form>
                    </div>
                    <div className="custom-table-view">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>EMAIL</th>
                                    <th>MOBILE</th>
                                    <th>EMAIL STATUS</th>
                                    <th>SMS STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.communicationHistory.length > 0 && this.state.communicationHistory.map(user => {
                                        return (
                                            <tr key={user._id}>
                                                <td className="username-row" onClick={() => {
                                                    this.props.history.push(`/viewProfile?id=${user.userId}`)
                                                }}> {user.name} </td>
                                                <td> {user.emailAddress} </td>
                                                <td> {user.mobile} </td>
                                                <td className={ user.emailStatus === common.communicationStatus.Delivered ? "delivered" : 
                                                    user.emailStatus === common.communicationStatus.Failed ? "failed" :
                                                    user.emailStatus === common.communicationStatus.Sending ? "sending" : "not-sent" }>
                                                {user.emailStatus.toUpperCase()} </td>
                                                <td className={ user.smsStatus === common.communicationStatus.Delivered ? "delivered" : 
                                                    user.smsStatus === common.communicationStatus.Failed ? "failed" :
                                                    user.smsStatus === common.communicationStatus.Sending ? "sending" : "not-sent" }
                                                > {user.smsStatus.toUpperCase()} </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.state.communicationHistory.length === 0 &&
                                    <tr>
                                        <td>No Communication found!</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommunicationFeed;