import axios from 'axios';
import React, { Component } from 'react';
import LaddaButton, { EXPAND_RIGHT, XS } from 'react-ladda';
import swal from 'sweetalert';
import common from '../../common';
import RequiredSign from '../RequiredSign';



class ReferFriend extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            loadinglada: false,
        }
    }
    componentWillReceiveProps(nextProps) {
        nextProps.onRef && nextProps.onRef(this);

    }
    toInputUppercase = (e) => {
        const sentence = e.target.value
        this.setState({ name: sentence });
    };
    resetData() {
        this.setState({ name: '', email: '', emailValidation: '' })
    }
    referFriend() {
        const temp = this;
        let token = window.localStorage.getItem('token');
        this.setState({ loadinglada: true });
        axios.post(`${common.path.base_url}/referFriend`, {
            email: this.state.email,
            name: this.state.name,
        }, {
            headers: {
                "Authorization": token
            }
        })
            .then(function (response) {

                swal({
                    text: 'Thank you!',
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        document.getElementById('cancelReferFriend').click();
                        temp.resetData()

                    });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });

            });

    }
    render() {
        return (
            <div id="referFriendModal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content invite-contact">
                        <div className="modal-header">
                            <h4 className="modal-title">Refer a Member</h4>
                        </div>
                        <div className="modal-body">
                            {/* <form className="form-horizontal" id='myform'>
                                <div className="form-group">
                                    <label className="col-lg-2 col-sm-3  col-md-3 control-label">Name :</label>
                                    <div className="col-lg-10 col-sm-8 col-md-8">
                                        <input type="text" value={this.state.name} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-2 col-sm-3  col-md-3 control-label">Email :</label>
                                    <div className="col-lg-10 col-sm-8 col-md-8">
                                            <input type="text" value={this.state.email} />
                                    </div>
                                </div>
                            </form> */}
                            <form className="form-horizontal recommand_form">
                                <div className="row">

                                    <div className="col-sm-7 col-md-12">
                                        <div className="form-group">
                                            <label style={{ fontWeight: "600" }} htmlFor="name">Name <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.name}
                                                    onChange={(event) => this.toInputUppercase(event)}
                                                    name="name" className="form-control" id="name" placeholder=""
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-sm-7 col-md-12">
                                        <div className="form-group">
                                            <label style={{ fontWeight: "600" }} htmlFor="name">Email <RequiredSign /> :</label>
                                            <div className='recommendation-input'>
                                                <input type="text"
                                                    value={this.state.email}
                                                    onChange={(event) => {
                                                        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                        let res = emailRegex.test(event.target.value)
                                                        if (event.target.value === '') {
                                                            this.setState({
                                                                emailValidation: "Email should not be empty*",
                                                                email: ''
                                                            })
                                                        } else if (res !== true) {
                                                            this.setState({
                                                                emailValidation: "Please enter valid email*",
                                                                email: event.target.value
                                                            })
                                                        } else {
                                                            this.setState({
                                                                emailValidation: "",
                                                                email: event.target.value
                                                            })
                                                        }

                                                    }}
                                                    name="email" className="form-control" id="email" placeholder=""
                                                />
                                                <p >{this.state.emailValidation}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <input type="button" id="cancelReferFriend" className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => { this.resetData() }} />
                            <LaddaButton
                                id='lada-btn'
                                style={{ width: '25%' }}
                                loading={this.state.loadinglada}
                                onClick={() => this.referFriend()}
                                data-color="#eee"
                                data-size={XS}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                                data-dismiss="modal"
                                className={this.state.name && this.state.email && !this.state.emailValidation ? '' : 'disable'}

                            >
                                {'REFER A MEMBER'}
                            </LaddaButton>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ReferFriend;