import React, { Component } from 'react';
import ReactTags from 'react-tag-autocomplete'
// Importing Custom Components
import TButton from '../../components/TButton';
import ContactList from '../../components/Contact List';
import CustomModal from '../../components/CustomModal';
import ContactSendMessageModal  from '../../components/ContactSendMessageModal';
import Header from '../../components/Header';
import HeaderImage from '../../components/HeaderImage';
import LoadingSpinner from '../../components/Loader';

// Imported Utility Libraries
import common from '../../common';
import _ from 'lodash';
import axios from 'axios';
import { CSVLink, CSVDownload } from "react-csv";
import Select from 'react-select';

// Importing Styles
import './Master Contact List.scss';
import swal from 'sweetalert';
import { Accordion } from 'react-bootstrap';
import ContactDetailsModal from '../Contact List/ContactDetailsModal';
import UserSelectedList from '../ContactUserList/UserSelectedList';

let source = axios.CancelToken.source();
class MasterContactList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            data: [],
            role: '',
            roleCode: '000',
            loading: false,
            headerShow: 'block',
            selectedUser: null,
            selectedHideUser: null,
            showTsg: false,
            showStartup: false,
            showPartner: false,
            companyTag: false,
            showAll: true,
            contacts: {
                tsg: [],
                startup: [],
                investor: [],
                allusers: []
            },
            contactsUI: {
                tsg: [],
                startup: [],
                investor: []
            },
            investorPagination: {
                currentPage: 0,
                totalPages: 1,
                total: 0,
            },
            contactsCSV: {
                tsg: [],
                startup: [],
                investor: []
            },
            searchText: '',
            tags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Unsubscribed', name: 'Unsubscribed' },
                { id: 'Blacklist', name: 'Blacklist' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' },
                { id: 'Spire One', name: 'Spire One' },
                { id: 'International', name: 'International' },
                { id: 'Federal', name: 'Federal' },
                { id: 'CXO', name: 'CXO' },
            ],
            filterValue: null,
            listOfUsers:[],
            exportLoading:false,
            allSelectCard:[],
            allSelectMobile:[],
            countSelectedUser:0,
            allSelectedUser:[],
            allListPopUser:[],
            category: [{ value: 'ALL', label: 'ALL' },{ value: 'YPO', label: 'YPO' },{ value: 'Whitelist', label: 'Whitelist' }, { value: 'Institutional', label: 'Institutional' }, { value: 'Strategic', label: 'Strategic' }, { value: 'Unsubscribed', label: 'Unsubscribed' }, { value: 'Blacklist', label: 'Blacklist' }, { value: 'Preferred', label: 'Preferred' }, { value: 'Investment(s)', label:'Investment(s)'}, { value: 'Spire One', label: 'Spire One' }, { value: 'International', label: 'International' }, { value: 'Federal', label: 'Federal' }, { value: 'CXO', label: 'CXO' }],
            selectCategory:null,  
            contactsEmail : [ { value: 'All', label: 'All Contacts' }, { value: 'No_Email', label: 'No Email Contacts' }, { value: 'Bounce_Email', label: 'Bounce Contacts' } ], 
            selectCheckEmail:null,
            tokenRequest : null 
        }
        this.csvLink = React.createRef();
        this.checkboxRef = React.createRef();
        // this.handleChange = this.handleChange.bind(this);
        this.searchTags = this.searchTags.bind(this);
        source = axios.CancelToken.source();
    }

    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags });
        this.searchTags(tags);
    }

    handleAddition(tag) {
        const tags = [].concat(this.state.tags, tag)
        this.setState({ tags })
        this.searchTags(tags);
    }
    componentWillMount() {
        const userRole = window.localStorage.getItem('role');
        const userRoleCode = window.localStorage.getItem('roleCode');
        this.setState({ role: userRole, roleCode: userRoleCode })
        this.getProfileData();
        this.getContactList();
        const url = new URL(window.location.href);
        this.companyId = url.searchParams.get('companyId');
        this.search = url.searchParams.get('search');
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    

    searchTags(tags, isFromLoadMore) {
        let cancelToken
        cancelToken = axios.CancelToken.source();
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        let CancelToken = axios.CancelToken;
        let cancel;    
        
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const userCompanyId = window.localStorage.getItem('companyId');
        tags = tags.map(tag => tag.name);
        let apiUrl = null;
        
        // if (cancel != undefined) {
        //     cancel();
        // }
        
        if (tags.length === 0 && !this.state.selectCategory && !this.state.selectCheckEmail ) {
            
            let paginationLimit = common.paginationLimit;

            let queryString = `?companyId=${userCompanyId}&page=${1}&limit=${paginationLimit}`;
            if ( this.state.filterValue && this.state.selectCategory ) {
                queryString = `?companyId=${userCompanyId}&page=${1}&limit=${paginationLimit}&status=${this.state.filterValue}&category=${this.state.selectCategory}`;
            
            } else if ( this.state.filterValue ) {
                queryString = `?companyId=${userCompanyId}&page=${1}&limit=${paginationLimit}&status=${this.state.filterValue}`;
            
            } else if ( this.state.selectCategory ) {
                queryString = `?companyId=${userCompanyId}&page=${1}&limit=${paginationLimit}&category=${this.state.selectCategory}`;
            }

            // let cancelToken
            // 
            if (source !== undefined) {
                console.log(source);
                source.cancel("Landing Component got unmounted");
            }
            source = axios.CancelToken.source();

            apiUrl = axios.get(`${common.path.base_url}/getMasterList${queryString}`, {  
                cancelToken: source.token,
                headers: {
                    "Authorization": tokenpass
                }
            });
            if (cancel !== undefined) cancel();
            //
        } else {
            let status = null;
            if (this.state.filterValue) {
                status = this.state.filterValue;
            }
            let category = null;
            if (this.state.selectCategory) {
                category = this.state.selectCategory;
            }

            let email_check = null;
            if (this.state.selectCheckEmail) {
                email_check = this.state.selectCheckEmail;
            }

            // const source = this.state.tokenRequest;
            // source.cancel();

            const currentPage = this.state.investorPagination && isFromLoadMore ? this.state.investorPagination.currentPage + 1 : 1;
            const limit=100; // {common.paginationLimit}
            
            if (source !== undefined) {
                source.cancel("Landing Component got unmounted");
            }
            source = axios.CancelToken.source();

            apiUrl = axios.post(`${common.path.base_url}/getMasterListSearch?page=${currentPage}&limit=${limit}`, { companyId: userCompanyId, tags, status, category, email_check }, {
                cancelToken: source.token,
                headers: {
                    "Authorization": tokenpass
                }
            });
            
            
            
        }
        apiUrl.then((response) => {
            const contacts = response.data.data;

            temp.setState({ loading: false });
            temp.setState({
                contacts:
                {
                    tsg: contacts.tsg,
                    startup: contacts.startup,
                    investor: isFromLoadMore ? [...this.state.contacts.investor, ...contacts.investers] : contacts.investers,
                    allusers: contacts.allusers,
                },
                contactsUI:
                {
                    tsg: contacts.tsg,
                    startup: contacts.startup,
                    investor: isFromLoadMore ? [...this.state.contacts.investor, ...contacts.investers] : contacts.investers,
                },
                investorPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, total: response.data.total },
            });
        })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.code && error.response.data.code === 204) {
                    // window.localStorage.clear();
                    // window.location.href = '/';
                }
                temp.setState({ loading: false });
            });
    }
    // ENCODE UTF8
    filterString(str)
    {
        str = str.replace(/\r?\n|\r/g, " ");

        return str;
    }
    exportData() {
        let temp = this;
        var tags = this.state.tags;
        tags = tags.map(tag => tag.name);
        let tokenpass = window.localStorage.getItem('token');
        let status = null;
        if (this.state.filterValue) {
            status = this.state.filterValue;
        }
        
        this.setState({ listOfUsers: [], exportLoading:true });
        const userCompanyId = window.localStorage.getItem('companyId');
        
        let tabCode = 'showAll';
        if(this.state.showAll) {
            tabCode = 'showAll';
        } else if(this.state.showTsg) {
            tabCode = 'showTsg';
        } else if(this.state.showStartup) {
            tabCode = 'showStartup';
        } else if(this.state.showPartner) {
            tabCode = 'showInvestor';
        }
        
        let category = null;
        if (this.state.selectCategory) {
            category = this.state.selectCategory;
        }
        
        let email_check = null;
        if (this.state.selectCheckEmail) {
            email_check = this.state.selectCheckEmail;
        }
        var self = this;
        //let getCSVUrl = null;
        axios.post(`${common.path.base_url}/getUserCSV`, { companyId: userCompanyId, tags: tags, status, tabCode:tabCode, category, email_check }, {
            headers: {
                "Authorization": tokenpass
            }
        
        }).then((response) => {
            const contacts = response.data.data;
            const UserArray = contacts.userArray;
            let arr = [];
            
            var userExData = new Promise((resolve, reject) => {
                UserArray.forEach(function(val, index, UserArray) {
                    let fullAddress = (val.address) ? `${val.address}` : "";
                        fullAddress = fullAddress.concat("" ,(val.address2) ? `, `+val.address2 : "" );
                        fullAddress = fullAddress.concat("" ,(val.city) ? `, `+val.city : "" );
                        fullAddress = fullAddress.concat("" ,(val.state) ? `, `+val.state : "" );
                        fullAddress = fullAddress.concat("" ,(val.country) ? `, `+val.country : "" );
                        fullAddress = fullAddress.concat("" ,(val.zipcode) ? `, `+val.zipcode : "" );
                    
                    let ObjCode = { 'First Name': (val.firstName) ? val.firstName : "",
                                    'Middle Name': (val.middleName) ? val.middleName : "",
                                    'Last Name': (val.lastName) ? val.lastName : "",     
                                    'Preferred Name': (val.preferred) ? val.preferred : "",     
                                    Title: (val.title) ? val.title : "",
                                    Address: (fullAddress) ? fullAddress : "", 
                                    Company: (val.myCompanyName) ? val.myCompanyName : "",
                                    Email: (val.emailAddress) ? val.emailAddress : "",
                                    Mobile: (val.mobile) ? val.mobile : "",
                                    Office: (val.office) ? val.office : "",
                                    Notes: (val.notes) ? self.filterString(val.notes) : "", 
                                    Tags: (val.investorCategories) ? val.investorCategories.join(', ') : "",  
                                };
                    arr.push(ObjCode);
                    if (index === UserArray.length -1) resolve();
                });
                
            });
            userExData.then(() => {
                this.setState({ listOfUsers: arr, exportLoading:false });
                this.csvLink.current.link.click();
            });

        }).catch(function (error) {
            this.setState({ listOfUsers: [], exportLoading:false });
        });
    }

    resetSMSEmailCard() {
        this.setState({ allSelectCard:[], allSelectMobile:[], allSelectedUser:[], countSelectedUser:0 });
        if(this.checkboxRef.current.checked){
            this.checkboxRef.current.click();
        }
    }

    getProfileData() {
        let temp = this;
        //temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        axios.get(`${common.path.base_url}/getUserById`, {
            params: {
                _id: id
            }
        })
            .then((response) => {
                //temp.setState({ loading: false });
                temp.setState({ name: response.data.data.name });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else { }
                //temp.setState({ loading: false });
            });
    }
    
    getContactList(isFromLoadMore) {
        let temp = this;
        let cancelToken
        cancelToken = axios.CancelToken.source();

        temp.setState({ loading: true, tags: [], tokenRequest: cancelToken });
        let tokenpass = window.localStorage.getItem('token');
        const userCompanyId = window.localStorage.getItem('companyId');
        const currentPage = this.state.investorPagination && isFromLoadMore ? this.state.investorPagination.currentPage + 1 : 1;
        let paginationLimit = common.paginationLimit
        let queryString = `?companyId=${userCompanyId}&page=${currentPage}&limit=${paginationLimit}`;
        if (this.state.filterValue) {
            queryString = `?companyId=${userCompanyId}&page=${currentPage}&limit=${paginationLimit}&status=${this.state.filterValue}`;
        }
        axios.get(`${common.path.base_url}/getMasterList${queryString}`, { cancelToken: source.token,
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                //console.log("govinddd");
                const contacts = response.data.data;
                //temp.setState({ loading: false });
                temp.setState({
                    loading: false,
                    contacts:
                    {
                        tsg: contacts.tsg,
                        startup: contacts.startup,
                        investor: isFromLoadMore ? [...this.state.contacts.investor, ...contacts.investers] : contacts.investers,
                        allusers: contacts.allusers,
                    },
                    contactsUI:
                    {
                        tsg: contacts.tsg,
                        startup: contacts.startup,
                        investor: isFromLoadMore ? [...this.state.contacts.investor, ...contacts.investers] : contacts.investers,
                    },
                    investorPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, total: response.data.total },
                });
                if (this.companyId && this.search) {
                    // this.setState({
                    //     showAll: false,
                    //     showStartup: true,
                    //     searchText: this.search,
                    // });
                    // this.searchOnKeyChange(this.search);
                    // this.companyId = null;
                    // this.search = null;
                    //console.log("sadasdasd",this.search)
                    const search = {name: this.search}
                    const tags = [].concat(this.state.tags, search)
                    this.setState({ tags })
                    this.searchTags(tags);
                }
            })
            .catch(function (error) {
                if ( error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response && error.response.data && error.response.code && error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                temp.setState({ loading: false });
            });
    }
    
    resetContactCardsPopups(contactList) {

        contactList.tsg.map((contact) => {
            contact.showMore = false;
        });

        contactList.startup.map((company) => {
            company.users && company.users.map((contact) => {
                contact.showMore = false;
            })
        });

        contactList.investor.map((contact) => {
            contact.showMore = false;
        });

        return contactList;

    }

    toggleShowMoreCard(popupType, event, contactType, cardIndex, companyIndex) {
        event.stopPropagation();
        let showMoreStateOpen = false;
        let menuStateOpen = false;
        let contactList = this.state.contactsUI;

        if (popupType === 1) {
            switch (contactType) {
                case 'tsg':
                    showMoreStateOpen = contactList.tsg[cardIndex].showMore;
                    break;

                case 'startup':
                    showMoreStateOpen = contactList.startup[companyIndex].users[cardIndex].showMore;
                    break;

                case 'investor':
                    showMoreStateOpen = contactList.investor[cardIndex].showMore;
                    break;
            }

            if (showMoreStateOpen) {
                showMoreStateOpen = false;
            } else {
                contactList.tsg.map((contact) => {
                    contact.showMore = false;
                    contact.showMenu = false;

                });

                contactList.startup.map((company) => {
                    company.users && company.users.map((contact) => {
                        contact.showMore = false;
                        contact.showMenu = false;

                    })
                });

                contactList.investor.map((contact) => {
                    contact.showMore = false;
                    contact.showMenu = false;

                });
                // contactList = this.resetContactCardsPopups(contactList);
                showMoreStateOpen = true;
            }

        } else if (popupType === 2) {
            switch (contactType) {
                case 'tsg':
                    menuStateOpen = contactList.tsg[cardIndex].showMenu;
                    break;

                case 'startup':
                    menuStateOpen = contactList.startup[companyIndex].users[cardIndex].showMenu;
                    break;

                case 'investor':
                    menuStateOpen = contactList.investor[cardIndex].showMenu;
                    break;
            }

            if (menuStateOpen) {
                menuStateOpen = false;
            } else {
                contactList.tsg.map((contact) => {
                    contact.showMore = false;
                    contact.showMenu = false;

                });

                contactList.startup.map((company) => {
                    company.users && company.users.map((contact) => {
                        contact.showMore = false;
                        contact.showMenu = false;

                    })
                });

                contactList.investor.map((contact) => {
                    contact.showMore = false;
                    contact.showMenu = false;

                });
                // contactList = this.resetContactCardsPopups(contactList);
                menuStateOpen = true;
            }

        }

        switch (contactType) {
            case 'tsg':
                contactList.tsg[cardIndex].showMore = showMoreStateOpen;
                contactList.tsg[cardIndex].showMenu = menuStateOpen;
                break;

            case 'startup':
                contactList.startup[companyIndex].users[cardIndex].showMore = showMoreStateOpen;
                contactList.startup[companyIndex].users[cardIndex].showMenu = menuStateOpen;
                break;

            case 'investor':
                contactList.investor[cardIndex].showMore = showMoreStateOpen;
                contactList.investor[cardIndex].showMenu = menuStateOpen;
                break;
        }


        this.setState({ contactsUI: contactList });


    }

    resetShowMoreCards(contactType) {
        let contactList = this.state.contactsUI;

        switch (contactType) {
            case 'tsg':
                {
                    contactList.tsg.forEach((contact) => {
                        contact.showMenu = false;
                        contact.showMore = false;
                    })
                    break;
                }

            case 'startup':
                {
                    contactList.startup.forEach((company) => {
                        company.users && company.users.forEach((contact) => {
                            contact.showMenu = false;
                            contact.showMore = false;
                        })
                    })
                    break;
                }

            case 'investor':
                {
                    contactList.investor.forEach((contact) => {
                        contact.showMenu = false;
                        contact.showMore = false;
                    })
                    break;
                }
        }
        this.setState({ contactsUI: contactList });
    }

    hideContact(contactType, cardIndex, companyIndex) {
        swal({
            title: "Are you sure?",
            text: "You, want to Hide this contact ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {


                const temp = this;
                this.setState({ loadinglada: true });
                let tokenpass = window.localStorage.getItem('token');

                let contactList = this.state.contactsUI;
                let userId = null;
                if (willDelete) {
                    switch (contactType) {
                        case 'tsg':
                            {
                                userId = contactList.tsg[cardIndex]._id;
                                break;
                            }

                        case 'startup':
                            {
                                userId = contactList.startup[companyIndex].users[cardIndex]._id;
                                break;
                            }

                        case 'investor':
                            {
                                userId = contactList.investor[cardIndex]._id;
                                break;
                            }
                        default:
                            break;

                    }

                    axios.put(`${common.path.base_url}/deactivateUser`, { id: userId }, {
                        headers: {
                            "Authorization": tokenpass
                        }
                    })
                        .then((response) => {
                            temp.getContactList();
                            swal({
                                title: "Contact Hidden",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(function () {
                                    temp.setState({ loadinglada: false });
                                });
                        })
                        .catch((error) => {
                            temp.setState({ loadinglada: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else if (error.response.data.code === 204) {
                                window.localStorage.clear();
                                window.location.href = '/';
                            }
                            if (error.response) {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.response,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                            else {
                                swal({
                                    title: "Network Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }

            });
    }

    discardContact(contactType, index, companyIndex) {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this contact",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                let newList = this.state.contactsUI;
                let userId = null;
                // const deleteContact = newList[index];
                const token = window.localStorage.getItem('token');
                if (willDelete) {
                    switch (contactType) {
                        case 'tsg':
                            {
                                userId = newList.tsg[index]._id;
                                break;
                            }

                        case 'startup':
                            {
                                userId = newList.startup[companyIndex].users[index]._id;
                                break;
                            }

                        case 'investor':
                            {
                                userId = newList.investor[index]._id;
                                break;
                            }

                    }
                    //@TODO CALL DELETE CONTACT API HERE WITH NECESSARY CHECKS
                    this.setState({ loading: true })
                    axios.delete(`${common.path.base_url}/deleteUser`, { headers: { "Authorization": token }, data: { id: userId } }
                    )
                        //@TODO CALL ON SUCCESS OF API
                        .then((response) => {
                            this.setState({ loading: false })
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                                .then(() => {
                                    this.getContactList();
                                });
                            this.setState({ contactsUI: newList });

                        })
                        .catch((error) => {
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else if (error.response.data.code === 204) {
                                window.localStorage.clear();
                                window.location.href = '/';
                            } else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });
                }
                else {
                    swal({ text: `This contact is safe!`, icon: 'info' });
                }
            });
    }
    redirectToInvestorDashboard(investorId) {
        this.props.history.push(`/dashboard?investorId=${investorId}`);
    }
    editContact(contactData) {
        this.props.history.push(`/profile?id=${contactData._id}`);
    }
    viewProfile(contactData) {
        this.props.history.push(`/viewProfile?id=${contactData._id}`);
    }
    selectCardBox(selectCard, event) {
        
        const selectEmail = selectCard.emailAddress;
        const selectMobile = selectCard.mobile;
        const checked = event.target.checked;
        //console.log(selectCard);
        if (checked) {

            if(selectEmail != "" && selectEmail != null && selectEmail != undefined) {
                if( !this.state.allSelectCard.includes(selectEmail) ) {
                    this.setState({
                        allSelectCard: [...this.state.allSelectCard, selectEmail]
                    });
                    //console.log("email");
                } else {
                    
                }
            }
            //console.log(this.state.allSelectMobile);
            if(selectMobile != "" && selectMobile != null && selectMobile != undefined) {
                if(!this.state.allSelectMobile.includes(selectMobile)) {
                    
                    let mobile = selectMobile;
                    mobile = mobile.replace(/ /g, '');
                    mobile = mobile.replace(/[\])}[{(]/g, ''); 
                    this.setState({
                        allSelectMobile: [...this.state.allSelectMobile, mobile]
                    });
                } else {
                    
                }
            }

            if( (selectEmail != "" && selectEmail != null && selectEmail != undefined) || (selectMobile != "" && selectMobile != null && selectMobile != undefined) ) { 
                const allUserId = this.state.allSelectedUser;
                
                if( !allUserId.includes(selectCard._id) ) {
                    this.setState({ 'allSelectedUser': [...this.state.allSelectedUser, selectCard._id] });
                    this.setState({ 'countSelectedUser' : ( this.state.allSelectedUser.length + 1 ) });
                    this.setState({ 'allListPopUser' : [ ...this.state.allListPopUser, { 'user_id':selectCard._id, 'name': selectCard.name, 'email': selectCard.emailAddress, 'mobile': selectCard.mobile } ] });
                }
            }
            
            
        } else {

            const allSelectCard = this.state.allSelectCard;
            var index = allSelectCard.indexOf(selectEmail)
            if (index !== -1) {
                allSelectCard.splice(index, 1);
                this.setState({ allSelectCard })
            }

            const allSelectMobile = this.state.allSelectMobile;
            var index = allSelectMobile.indexOf(selectMobile)
            if (index !== -1) {
                allSelectMobile.splice(index, 1);
                this.setState({ allSelectMobile });
            }

            const allSelectedUser = this.state.allSelectedUser;
            var index = allSelectedUser.indexOf(selectCard._id)
            if (index !== -1) {
                allSelectedUser.splice(index, 1);
                this.setState({ allSelectedUser });
            }
            const allListPopUser = this.state.allListPopUser;
            var userIndex = allListPopUser.findIndex(user => user.user_id === selectCard._id);
            if (userIndex !== -1) {
                allListPopUser.splice(userIndex, 1);
                this.setState({ allListPopUser });
            }

            this.setState({ 'countSelectedUser' : ( this.state.allSelectedUser.length ) })
        }
    }

    selectAllCard(event) {
        
        if(event.target.checked){

            //showTsg: false, showStartup: false, showPartner: true, companyTag: false, showAll: false
            const allUsers = this.state.contacts;
            const allSelectCard = [];
            const allSelectMobile = [];
            const allSelectedUser = [];
            const allListPopUser = [];
            
            this.setState({ 'allSelectedUser': [] });

            if(allUsers.allusers && (this.state.showAll || this.state.showPartner) ) {
                allUsers.allusers.map((users, index) => 
                {   
                    let loopArr = []; 
                    if( users.emailAddress != '' && users.emailAddress != 'undefined' && users.emailAddress != null ) {
                        if(!allSelectCard.includes(users.emailAddress)) {
                            allSelectCard.push(users.emailAddress)
                        }
                        loopArr.email = users.emailAddress;    
                    } else {
                        loopArr.email = "";
                    }

                    if( users.mobile != '' && users.mobile != 'undefined' && users.mobile != null ) {
                        let mobile = users.mobile;
                        mobile = mobile.replace(/ /g, '');
                        mobile = mobile.replace(/[\])}[{(]/g, '');

                        if(!allSelectMobile.includes(users.mobile)) {
                            allSelectMobile.push(mobile)
                        }
                        loopArr.mobile = mobile;
                    } else {
                        loopArr.mobile ="";
                    }

                    if( ( users.emailAddress != '' && users.emailAddress != 'undefined' && users.emailAddress != null ) || (users.mobile != '' && users.mobile != 'undefined' && users.mobile != null) ) {
                        
                        allSelectedUser.push(users._id);
                        allListPopUser.push({ 'user_id':users._id, 'name': users.name, 'email': users.emailAddress, 'mobile': users.mobile });
                    }
                });
            }

            if(allUsers.startup && (this.state.showAll || this.state.showStartup) ) {
                allUsers.startup.map((data, index) => 
                {   
                    if( data && data.users && data.users != 'undefined' && data.users.length > 0 ) {
                        data.users.map((users, index) => {
                            let loopArr = []; 
                            
                            if(users.emailAddress !="" && users.emailAddress != 'undefined' && users.emailAddress != null) {
                                if(!allSelectCard.includes(users.emailAddress)) {
                                    allSelectCard.push(users.emailAddress);
                                }
                                loopArr.email = users.emailAddress;
                            } else {
                                loopArr.email = "";
                            }
                            
                            if(users.mobile !="" && users.mobile != 'undefined' && users.mobile != null) {
                                let mobile = users.mobile;
                                    mobile = mobile.replace(/ /g, '');
                                    mobile = mobile.replace(/[\])}[{(]/g, '');
                                if(!allSelectMobile.includes(users.mobile)) {
                                    allSelectMobile.push(mobile);
                                }
                                loopArr.mobile = mobile;
                            } else {
                                loopArr.mobile ="";
                            }

                            if( ( users.emailAddress != '' && users.emailAddress != 'undefined' && users.emailAddress != null ) || (users.mobile != '' && users.mobile != 'undefined' && users.mobile != null) ) {

                                allSelectedUser.push(users._id);
                                allListPopUser.push({ 'user_id':users._id, 'name': users.name, 'email': users.emailAddress, 'mobile': users.mobile });
                            }

                        });
                    }
                });
            }
            
            if(allUsers.tsg && (this.state.showAll || this.state.showTsg) ) {
                allUsers.tsg.map((users, index) => 
                {       
                    let loopArr = [];
                    if( users.emailAddress != '' && users.emailAddress != 'undefined' && users.emailAddress != null ) {
                        if(!allSelectCard.includes(users.emailAddress)) {
                            allSelectCard.push(users.emailAddress);
                        }
                        loopArr.email = users.emailAddress;
                    } else {
                        loopArr.email = "";
                    }

                    if( users.mobile != '' && users.mobile != 'undefined' && users.mobile != null ) {
                        let mobile = users.mobile;
                            mobile = mobile.replace(/ /g, '');
                            mobile = mobile.replace(/[\])}[{(]/g, ''); 
                        
                        if(!allSelectMobile.includes(users.mobile)) {
                            allSelectMobile.push(mobile);
                        }
                        loopArr.mobile = mobile;
                    } else {
                        loopArr.mobile ="";
                    }
                    
                    if( ( users.emailAddress != '' && users.emailAddress != 'undefined' && users.emailAddress != null ) || (users.mobile != '' && users.mobile != 'undefined' && users.mobile != null) ) {
                        allSelectedUser.push(users._id);
                        allListPopUser.push({ 'user_id':users._id, 'name': users.name, 'email': users.emailAddress, 'mobile': users.mobile });
                    }
                });
            }
            this.setState({ allSelectCard, allSelectMobile, allSelectedUser, allListPopUser });  
            this.setState({ 'countSelectedUser' : ( allSelectedUser.length) });
            
        } else {
            this.setState({ 
                allSelectCard:[], 
                allSelectMobile:[], 
                allSelectedUser:[],
                allListPopUser:[], 
                'countSelectedUser':0 });
        }    
    }

    selectedSendMessagePopup() {
        document.getElementById("pop_cancel_list").click();
        document.getElementById("send_message").click();
    }

    selectedRemovePopup(data) {
        
        const UserId = data.user_id;
        const selectEmail = data.email;
        const selectMobile = data.mobile;
        
        
        const allSelectCard = this.state.allSelectCard;
        var index = allSelectCard.indexOf(selectEmail)
        if (index !== -1) {
            allSelectCard.splice(index, 1);
            this.setState({ allSelectCard })
        }

        const allSelectMobile = this.state.allSelectMobile;
        var index = allSelectMobile.indexOf(selectMobile)
        if (index !== -1) {
            allSelectMobile.splice(index, 1);
            this.setState({ allSelectMobile });
        }

        const allSelectedUser = this.state.allSelectedUser;
        var index = allSelectedUser.indexOf(UserId)
        if (index !== -1) {
            allSelectedUser.splice(index, 1);
            this.setState({ allSelectedUser });
        }
        
        const allListPopUser = this.state.allListPopUser;
        var userIndex = allListPopUser.findIndex(user => user.user_id === UserId);
        if (userIndex !== -1) {
            allListPopUser.splice(userIndex, 1);
            this.setState({ allListPopUser });
        }

        this.setState({ 'countSelectedUser' : ( allSelectedUser.length) });
    } 

    renderContactCards(contactType) {
        let renderedContacts = null;
        let currntUser = window.localStorage.getItem('id');

        switch (contactType) {
            case 'tsg':
                {
                    if (this.state.contactsUI.tsg.length > 0) {
                        renderedContacts =
                            <Accordion> {
                                this.state.contactsUI.tsg.map((data, cardIndex) => <ContactList
                                    contactData={data}
                                    toggleShowMoreCard={(type, event) => this.toggleShowMoreCard(type, event, contactType, cardIndex)}
                                    hideContact={() => this.hideContact(contactType, cardIndex)}
                                    resendInvite={() => this.resendInvite(data._id)}
                                    showMenu={this.state.roleCode <= data.role ? true : false}
                                    canResend={data.invitationAccepted != "1" ? true : false}
                                    onActionClick={() => this.discardContact(contactType, cardIndex)}
                                    oneditClick={() => this.editContact(data)}
                                    viewProfile={() => this.viewProfile(data)}
                                    selectCardBox={(data, event) => this.selectCardBox(data, event) }
                                    showDataInPopup={(data) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(data._id, null), this.openContactDetailModal())}
                                    currentUser={currntUser}
                                    allSelectCard={this.state.allSelectCard}
                                    allSelectMobile={this.state.allSelectMobile}
                                    allSelectedUser={this.state.allSelectedUser}  
                                    {...this.props}
                                />)}
                            </Accordion>
                    } else {
                        renderedContacts = <div></div>
                    }
                    break;
                }
            case 'investor':
                {
                    if (this.state.contactsUI.investor.length > 0) {
                        renderedContacts =
                            <Accordion> {
                                this.state.contactsUI.investor.map((data, cardIndex) => <ContactList
                                    contactData={data}
                                    toggleShowMoreCard={(type, event) => this.toggleShowMoreCard(type, event, contactType, cardIndex)}
                                    hideContact={() => this.hideContact(contactType, cardIndex)}
                                    resendInvite={() => this.resendInvite(data._id)}
                                    showMenu={this.state.roleCode <= data.role ? true : false}
                                    canResend={data.invitationAccepted != "1" ? true : false}
                                    onActionClick={() => this.discardContact(contactType, cardIndex)}
                                    oneditClick={() => this.editContact(data)}
                                    viewProfile={() => this.viewProfile(data)}
                                    currentUser={currntUser}
                                    selectCardBox={(data, event) => this.selectCardBox(data, event) }
                                    showDataInPopup={(data) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(data._id, null), this.openContactDetailModal())}
                                    redirectToInvestorDashboard={(investorId) => this.redirectToInvestorDashboard(investorId)}
                                    allSelectCard={this.state.allSelectCard}
                                    allSelectMobile={this.state.allSelectMobile}
                                    allSelectedUser={this.state.allSelectedUser}   
                                    {...this.props}
                                />)
                            } </Accordion>
                    } else {
                        renderedContacts = <div></div>
                    }
                    break;
                }

            default:
                renderedContacts = null;
        }

        return renderedContacts;
    }

    renderStartupCards(companyIndex) {

        let renderedContacts = [];
        let currntUser = window.localStorage.getItem('id');

        if (this.state.contactsUI.startup[companyIndex] && this.state.contactsUI.startup[companyIndex].users.length > 0) {
            let users = this.state.contactsUI.startup[companyIndex].users;
            renderedContacts =
                <Accordion> {
                    users.map((userData, cardIndex) => <ContactList
                        contactData={userData}
                        toggleShowMoreCard={(type, event) => this.toggleShowMoreCard(type, event, 'startup', cardIndex, companyIndex)}
                        hideContact={() => this.hideContact('startup', cardIndex, companyIndex)}
                        resendInvite={() => this.resendInvite(userData._id)}
                        showMenu={this.state.roleCode <= userData.role ? true : false}
                        canResend={userData.invitationAccepted != "1" ? true : false}
                        onActionClick={() => this.discardContact('startup', cardIndex, companyIndex)}
                        oneditClick={() => this.editContact(userData)}
                        viewProfile={() => this.viewProfile(userData)}
                        selectCardBox={(data, event) => this.selectCardBox(data, event) }
                        showDataInPopup={(data) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(data._id, null), this.openContactDetailModal())}
                        currentUser={currntUser}
                        allSelectCard={this.state.allSelectCard}
                        allSelectMobile={this.state.allSelectMobile}
                        allSelectedUser={this.state.allSelectedUser}  
                        {...this.props}
                    />)
                } </Accordion>
        } else {
            renderedContacts = <div></div>
        }

        return renderedContacts;
    }

    resendInvite(contactId) {
        const temp = this;
        this.setState({ loadinglada: true });
        let tokenpass = window.localStorage.getItem('token');

        axios.put(`${common.path.base_url}/resendInvite`, { id: contactId }, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                temp.getContactList();
                swal({
                    title: "Email Sent",
                    text: response.data.message,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        temp.setState({ loadinglada: false });
                    });
            })
            .catch((error) => {
                temp.setState({ loadinglada: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response.data.code === 204) {
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Error! #48755ER",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }


    tabSelection(tabValue) {
        switch (tabValue) {
            case 'all':
                this.setState({ showAll: true });
                break;

            case 'tsg':
                this.setState({ showTsg: true, showStartup: false, showPartner: false, companyTag: false, showAll: false });
                break;

            case 'startup':
                this.setState({ showTsg: false, showStartup: true, showPartner: false, companyTag: false, showAll: false });
                break;

            case 'investor':
                this.setState({ showTsg: false, showStartup: false, showPartner: true, companyTag: false, showAll: false });
                break;
        }
    }


    renderStartupSections() {
        let renderedSections = [];
        this.state.contactsUI.startup.sort((a, b) => {
            let nameA = a.companyName && a.companyName.toLowerCase(), nameB = b.companyName && b.companyName.toLowerCase();
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            return 0;
        })
        renderedSections = this.state.contactsUI.startup.map((startup, companyIndex) => {
            if (startup.users && startup.users.length > 0) {
                return (<div>
                    <h4>{this.state.roleCode === '003' || this.state.roleCode === '004' ? `MY COMPANY (${this.state.contactsUI.startup[companyIndex].users.length})` : startup.companyName ? `${startup.companyName.toUpperCase()} (${this.state.contactsUI.startup[companyIndex].users.length})` : ''}</h4>
                    <div>

                        <div className="contact-card row">
                            {this.renderStartupCards(companyIndex)}
                        </div>
                    </div>
                </div>);
            } 
            // else {
            //     return (<div>
            //         <h4>{this.state.roleCode === '003' || this.state.roleCode === '004' ? 'MY COMPANY' : startup.companyName ? startup.companyName.toUpperCase() : ''}</h4>
            //         <div>
            //             <div className="contact-card row">
            //                 <div className="user-not-added">User Not added Yet!</div>
            //             </div>
            //         </div>

            //     </div>)
            // }
        })
        return renderedSections;
    }


    getMatchArrObj(key, arr, type, startup) {
        let startupCompanies = [];
        if (type === 'investor' && startup.length > 0) {
            startupCompanies = startup.map(company => company._id);
        }
        key = key && key.toLowerCase();
        let result = [];
        if (startupCompanies.length > 0 && this.state.showAll === false) {
            arr.forEach((obj) => {
                if (startupCompanies.length > 0) {
                    startupCompanies.forEach(ele => {
                        if (obj && obj.companies && obj.companies.length > 0) {
                            if (obj.companies.indexOf(ele) !== -1) {
                                result.push(obj)
                            }
                        }
                    });
                }
            });

        } else {
            arr.forEach((obj) => {
                let values = Object.values(obj);
                let found = false;

                values.forEach((ele) => (ele && ele.toString().toLowerCase().includes(key)) ? found = true : null);
                if (found) {
                    result.push(obj)
                } else {
                    if (startupCompanies.length > 0) {
                        startupCompanies.forEach(ele => {
                            if (obj && obj.companies && obj.companies.length > 0) {
                                if (obj.companies.indexOf(ele) !== -1) {
                                    result.push(obj)
                                }
                            }
                        });
                    }
                }
            });
        }
        // arr.forEach((obj) => {
        //     let values = Object.values(obj);
        //     let found = false;
        //     values.forEach((ele) => (ele && ele.toString().toLowerCase().includes(key)) ? found = true : null);
        //     if (found) { 
        //         result.push(obj) 
        //     } else {
        //         if (startupCompanies.length > 0) {
        //             startupCompanies.forEach(ele => {
        //                 console.log(ele,obj);
        //                 if (obj && obj.companies && obj.companies.length > 0){
        //                     if (obj.companies.indexOf(ele) !== -1) {
        //                         // result.push(obj) 
        //                     }
        //                 }
        //             });
        //         }
        //     }
        // });

        return result;
    }
    getMatchCompanyName(key, arr) {
        if (!key) return undefined;
        key = key.toLowerCase();
        let result = [];
        arr.forEach((obj) => {
            if (obj.companyName && obj.companyName.toLowerCase().includes(key)) {
                result.push(obj);
            }
        });
        return result;
    }



    searchOnKeyChange = _.debounce((key, callback) => {
        let filteredContacts = { tsg: [], startup: [], investor: [] };
        if (this.state.contacts.tsg.length > 0) {
            filteredContacts.tsg = this.getMatchArrObj(key, this.state.contacts.tsg);
        }
        let companyNameArray = [];

        if (this.state.contacts.startup.length > 0) {
            const result = this.getMatchCompanyName(key, this.state.contacts.startup);

            if (result && result.length > 0) {
                filteredContacts.startup = result.map((startup, startupIndex) => {
                    return ({
                        _id: startup._id,
                        companyName: startup.companyName,
                        users: startup.users,
                    });
                });
                companyNameArray = filteredContacts.startup;
            } else {
                filteredContacts.startup = this.state.contacts.startup.map((startup, startupIndex) => {
                    return ({
                        _id: startup._id,
                        companyName: startup.companyName,
                        users: this.getMatchArrObj(key, startup.users)
                    });
                })
            }
        }
        if (this.state.contacts.investor.length > 0) {
            filteredContacts.investor = this.getMatchArrObj(key, this.state.contacts.investor, 'investor', companyNameArray);
        }

        this.setState({ contactsUI: filteredContacts, searchText: key })
        if (callback) {
            callback();
        }

    }, 500);

    checkIfNoContacts() {
        let dataFound = false;
        if (this.state.contactsUI.tsg.length > 0 || this.state.contactsUI.investor.length > 0) {
            dataFound = true;
        }

        if (this.state.contactsUI.investor.length > 0) {
            dataFound = true;
        }

        this.state.contactsUI.startup.map((startup) => {
            if (startup.users && startup.users.length > 0) {
                dataFound = true;
            }
        });
        // }

        return dataFound;
    }

    useTagContacts(filterValue){
        if (filterValue.length > 0) {
            filterValue = (filterValue != 'ALL') ? filterValue : null;
            this.setState({ selectCategory: filterValue }, () => {
                this.searchTags(this.state.tags, false)
            });
        } else {
            this.setState({ selectCategory: null }, () => {
                this.searchTags(this.state.tags, false)
            });
        }    
    }
    
    contactsEmailCheck(filterValue) {
        if (filterValue.length > 0) {
            filterValue = (filterValue != 'All') ? filterValue : null;
            this.setState({ selectCheckEmail: filterValue }, () => {
                this.searchTags(this.state.tags, false)
            });
        } else {
            this.setState({ selectCheckEmail: null }, () => {
                this.searchTags(this.state.tags, false)
            });
        }
    }

    filterContacts(filterType, filterValue) {
        if (filterValue.length > 0) {
            this.setState({ filterValue }, () => {
                this.searchTags(this.state.tags, false)
            })
        } else {
            this.setState({ filterValue: null }, () => {
                this.searchTags(this.state.tags, false)
            })
        }
        this.setState({})
        // console.log(filterValue);

        // let companyStartupList = [];
        // let contactList = this.state.contacts;
        // let newContactList = { tsg: [], startup: [], investor: [] };
        // if (filterValue.length > 0) {
        //     // eslint-disable-next-line default-case
        //     switch (filterType) {
        //         case 'status': {
        //             contactList.tsg.forEach((contact) => {
        //                 if (contact.invitationAccepted == filterValue) {
        //                     newContactList.tsg.push(contact);
        //                 }
        //             });

        //             contactList.startup.forEach((company) => {
        //                 companyStartupList.push({ '_id': company._id, 'companyName': company.companyName, 'users': [] });
        //             });

        //             contactList.startup.forEach((company, companyIndex) => {
        //                 company.users && company.users.forEach((contact) => {
        //                     if (contact.invitationAccepted == filterValue) {
        //                         companyStartupList[companyIndex].users.push(contact);
        //                     }
        //                 })
        //             });

        //             newContactList.startup = companyStartupList;


        //             contactList.investor.forEach((contact) => {
        //                 if (contact.invitationAccepted == filterValue) {
        //                     newContactList.investor.push(contact);
        //                 }
        //             });


        //             break;
        //         }
        //     }

        // } else {
        //     newContactList = this.state.contacts;
        // }

        // this.setState({ contactsUI: newContactList })
    }


    filterInvestorCategory(filterType, filterValue) {
        this.searchOnKeyChange(this.state.searchText, () => {
            const newContactList = this.state.contactsUI;
            const investors = this.state.contactsUI.investor;
            let filterInvestors = []
            if (investors && investors.length > 0 && filterValue !== 'All') {
                filterInvestors = investors.filter(investor => {
                    if (investor.investorCategories && investor.investorCategories.length > 0) {
                        if (investor.investorCategories.indexOf(filterValue) !== -1) {
                            return investor;
                        }
                    }
                });

            } else {
                filterInvestors = investors;
            }
            newContactList.investor = filterInvestors;
            this.setState({ contactsUI: newContactList });

        });


    }
    searchTest(key, callback) {
        let filteredContacts = { tsg: [], startup: [], investor: [] };
        if (this.state.contacts.tsg.length > 0) {
            filteredContacts.tsg = this.getMatchArrObj(key, this.state.contacts.tsg);
        }
        let companyNameArray = [];

        if (this.state.contacts.startup.length > 0) {
            const result = this.getMatchCompanyName(key, this.state.contacts.startup);

            if (result && result.length > 0) {
                filteredContacts.startup = result.map((startup, startupIndex) => {
                    return ({
                        _id: startup._id,
                        companyName: startup.companyName,
                        users: startup.users,
                    });
                });
                companyNameArray = filteredContacts.startup;
            } else {
                filteredContacts.startup = this.state.contacts.startup.map((startup, startupIndex) => {
                    return ({
                        _id: startup._id,
                        companyName: startup.companyName,
                        users: this.getMatchArrObj(key, startup.users)
                    });
                })
            }
        }
        if (this.state.contacts.investor.length > 0) {
            filteredContacts.investor = this.getMatchArrObj(key, this.state.contacts.investor, 'investor', companyNameArray);
        }

        // this.setState({ contactsUI: filteredContacts, searchText: key })
        if (callback) {
            callback(filteredContacts);
        }
    }
    filterInvestorCategory2(tag, callback) {
        this.searchTest(tag, (list) => {
            const newContactList = list
            const investors = this.state.contactsUI.investor;
            let filterInvestors = []
            if (investors && investors.length > 0) {
                filterInvestors = investors.filter(investor => {
                    if (investor.investorCategories && investor.investorCategories.length > 0) {
                        if (investor.investorCategories.indexOf(tag) !== -1) {
                            return investor;
                        }
                    }
                });

            } else {
                filterInvestors = investors;
            }
            if (filterInvestors && filterInvestors.length === 0) {
                filterInvestors = investors;
            }
            newContactList.investor = filterInvestors;
            this.setState({ contactsUI: newContactList });

        });
    }
    addInviteSuccessHandler() {
        this.getContactList();
    }
    resetCard(event) {
        this.resetShowMoreCards('tsg');
        this.resetShowMoreCards('startup')
        this.resetShowMoreCards('investor')
    }
    
    handleScroll = () => {
        if ((window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight)
            && (this.state.investorPagination.currentPage < this.state.investorPagination.totalPages && (this.state.showPartner || this.state.showAll))) {
            if ( this.state.tags.length === 0 && !this.state.selectCategory && !this.state.selectCheckEmail ) {
                this.getContactList(true);
            } else {
                this.searchTags(this.state.tags, true);
            }
        }
    };
    openContactDetailModal() {
        document.getElementById('contactDetails-btn').click();
        this.setState({ loading: false });
    }

    render() {
        const companyName = window.localStorage.getItem('companyName');
        const companyNickname = window.localStorage.getItem('companyNickname');
        return (
            <>            <div data-key="main-click" >
                {this.state.loading ? <LoadingSpinner /> : ""}
                <Header username={this.state.name} role={this.state.role} />
                {/* <HeaderImage heading={this.state.role} /> */}
                {this.state.roleCode === '003' || this.state.roleCode === '004' ? (
                    <HeaderImage
                        heading={`${companyNickname ? companyNickname : companyName ? companyName : ''}`}
                    />) :
                    <HeaderImage
                        heading={this.state.role}
                    />
                }
                <CustomModal onRef={(ref) => this.customModalChild = ref}
                    addInviteSuccess={() => this.addInviteSuccessHandler(this)}
                    {...this.props}
                />
                <ContactDetailsModal
                    onRef={(ref) => this.viewContactUserDetailsChild = ref}
                    addInviteSuccess={() => this.addInviteSuccessHandler(this)}
                    {...this.props}
                />
                <ContactSendMessageModal 
                    onRef={(ref) => this.sendMessageModalChild = ref}
                    allCheckbox= { this.state.allSelectCard }
                    allSelectMobile = { this.state.allSelectMobile }
                    resetSMSEmailCard = {() => this.resetSMSEmailCard()}
                    // getCheckBoxAll = { this.state.getCheckBoxAll }
                    // getRemoveCheckBox = { this.state.getRemoveCheckBox }
                    getSelectedCheckBox = { this.state.allSelectedUser }
                    pageLocation = "mcl"
                    {...this.props}
                    totalCount = {this.state.countSelectedUser}
                />
                <UserSelectedList 
                    onRef={(ref) => this.userSelectedListChild = ref}
                    allSelectedUser = { this.state.allSelectedUser }
                    allListPopUser = { this.state.allListPopUser }
                    selectedSendMessagePopup = {() => this.selectedSendMessagePopup() }
                    selectedRemovePopup = {(e) => this.selectedRemovePopup(e) }
                    {...this.props}
                />
                <TButton onClick={() => { }} id='contactDetails-btn' dataTarget={`#contactDetailsModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <div className='contact-main' onClick={(event) => this.resetCard(event)}>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                            <div className='contact-header mar-top-15'>
                            <nav className="contact-navbar ">
                                <button className={`navbtn ${this.state.showAll ? 'active' : ''}`} onClick={() => this.tabSelection('all')}>ALL</button>
                                <button className={`navbtn ${this.state.showTsg && !this.state.showAll ? 'active' : ''}`} onClick={() => this.tabSelection('tsg')}>TSG</button>
                                <button id='mycompany' className={`navbtn ${this.state.showStartup && !this.state.showAll ? 'active' : ''}`} onClick={() => this.tabSelection('startup')}>{this.state.roleCode === '003' || this.state.roleCode === '004' ? 'MY COMPANY' : 'STARTUPS'}</button>
                                <button className={`navbtn ${this.state.showPartner && !this.state.showAll ? 'active' : ''}`} onClick={() => this.tabSelection('investor')}>{this.state.roleCode === '003' || this.state.roleCode === '004' ? 'PARTNERS' : 'INVESTORS'}</button>
                            </nav>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div className='invite-section mar-top-15'>
                                { (this.state.countSelectedUser > 0) &&
                                <div className='tot-selected-count mar-right-15'>
                                    <a href='javascript:void(0);' style={{ 'color':'#2F3951' }}  data-target={`#userSelectedModal`} data-toggle={`modal`} >
                                        Total Selected: 
                                        <span style={{ 'color':'#F04242' }} >{ this.state.countSelectedUser }</span>
                                    </a>
                                </div>
                                }
                                
                                <TButton 
                                    onClick={() => this.props.history.push(`/mcl-communication`)}
                                    text={`COMMUNICATION`}
                                    className='invite mar-right-15'
                                    id="communication"
                                />

                                { ((this.state.roleCode !== '005' && common.allowedRolesToModifyCompany.includes(this.state.roleCode)))?<>
                                <a onClick={() => this.exportData()} className="tsg-btn invite export-btn mar-right-15" >
                                    {
                                        this.state.exportLoading ? 
                                        <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </>
                                        :
                                        <><i className="fa fa-file-text ex-icon" aria-hidden="true" />EXPORT </>
                                    }
                                </a></>:""}
                                {/* {this.state.listOfUsers.length > 0 ? <CSVDownload  filename="master_contacts_list.csv" data={this.state.listOfUsers} target="_blank" /> : null } */}
                                <CSVLink 
                                    data={ this.state.listOfUsers } 
                                    filename={"TSG-Contacts.csv"}
                                    className="hidden"
                                    ref={this.csvLink}
                                    target="_blank"
                                />
                                
                                {/* <a onClick={() => this.allEmailMessage()} className="tsg-btn invite export-btn marg-btn" >
                                    {
                                        <><i className="fa fa-file-text ex-icon" aria-hidden="true" />SEND MESSAGE </>
                                    }
                                </a> */}
                                { ((this.state.roleCode !== '005' && common.allowedRolesToModifyCompany.includes(this.state.roleCode)))?<><TButton
                                    onClick={() => this.sendMessageModalChild.resetMessageData() }
                                    text={`SEND MESSAGE`}
                                    dataTarget={`#sendMessageModal`}
                                    dataToggle={`modal`}
                                    className='invite mar-right-15'
                                    id="send_message"
                                /></>:""}
                                <TButton
                                    onClick={() => this.customModalChild.resetInviteData()}
                                    text={`ADD CONTACT`}
                                    dataTarget={`#inviteModal`}
                                    dataToggle={`modal`}
                                    className='invite mar-right-15'
                                    id="add_contact"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='search-status'>
                        <div className='search'>
                            {/* <input className='search-box' type='text' placeholder='Search by Name, Title, Company, Email Address, Mobile, Office' onChange={(e) => this.searchOnKeyChange(e.target.value)} defaultValue={this.state.searchText} /> */}
                            {/* <TagsInput value={this.state.tags} 
                                onChange={this.handleChange}
                                renderInput={autocompleteRenderInput}
                                inputProps={{
                                    className: 'react-tagsinput-input',
                                    placeholder: 'Search adding Tag'
                                }}
                             /> */}
                            <div className='checkbox-all'>
                            <label className="checkbox-container">
                                <input type='checkBox' name="userCheckbox" onClick={(event) => { this.selectAllCard(event) }} ref={this.checkboxRef} />
                                <span className={`checkmark`} style={{ 'opacity':1, 'top':'auto', 'right':'auto' }}></span>
                                <span className='all-checktxt'>ALL</span>
                            </label>
                            </div> 
                            <ReactTags
                                allowNew={true}
                                placeholder='Search'
                                tags={this.state.tags}
                                suggestions={this.state.suggestions}
                                handleDelete={this.handleDelete.bind(this)}
                                handleAddition={this.handleAddition.bind(this)}
                                />

                        </div>
                        { ((this.state.roleCode !== '005' && common.allowedRolesToModifyCompany.includes(this.state.roleCode)))?<>
                        <div className='tag-status'  >
                            <Select
                                id='category'
                                options={ _.sortBy(this.state.category, ['label']) }
                                className={'status-box'}
                                onChange={(event) => this.useTagContacts(event.value)}
                            />
                        </div>
                        <div className='tag-status'  >
                            <Select
                                id='contactView'
                                options={this.state.contactsEmail}
                                className={'status-box'}
                                onChange={(event) => this.contactsEmailCheck(event.value)}
                            />
                        </div>
                        <div className='status'   >
                            <select className='status-box' onChange={(event) => this.filterContacts('status', event.target.value)}>
                                <option value="" disabled selected>Select Status</option>
                                <option value="0">Email Unread</option>
                                <option value="2">Email Read</option>
                                <option value="1">Invitation Accepted</option>
                                <option value="3">Previously Invested</option>
                                <option value="">All</option>

                            </select>
                        </div>
                        </>:""}
                        {/* <div className='status'>
                            <select className='status-box' onChange={(event) => this.filterInvestorCategory('category', event.target.value)}>
                                <option value="" disabled selected>Select Category</option>
                                <option value="Strategic">Strategic</option>
                                <option value="Institutional">Institutional</option>
                                <option value="YPO">YPO</option>
                                <option value="Friends and Family">Friends and Family</option>
                                <option value="All">All</option>

                            </select>
                        </div> */}
                    </div>

                    {/* ------IF NO RESULTS FOUND ON SEARCH START------*/}
                    {!this.checkIfNoContacts() ? <div className="no-result-found">

                        {`No Results to Show`}
                    </div> : null}
                    {/* ------IF NO RESULTS FOUND ON SEARCH END------*/}



                    {(this.state.showTsg || this.state.showAll) && this.state.contactsUI.tsg.length > 0 ? (<div><h4>TSG {this.state.contactsUI.tsg.length > 0 && `(${this.state.contactsUI.tsg.length})`}</h4>
                        <div className="contact-card row">
                            {this.renderContactCards('tsg')}
                        </div></div>) : null}

                    {this.state.showStartup || this.state.showAll ? (<div>
                        {this.renderStartupSections()}
                    </div>) : null}

                    {(this.state.showPartner || this.state.showAll || this.state.companyTag) && this.state.contactsUI.investor.length > 0 ? (<div><h4>{this.state.roleCode === '003' || this.state.roleCode === '004' ? `PARTNERS (${this.state.investorPagination.total})` : `INVESTORS (${this.state.investorPagination.total})`}</h4>
                        <div className="contact-card row" >
                            {this.renderContactCards('investor')}
                        </div></div>) : null}
                    {/* {(this.state.companyTag) && this.state.contactsUI.investor.length > 0 ? (<div><h4>{this.state.roleCode === '003' || this.state.roleCode === '004' ? 'PARTNERS' : 'INVESTORS'}</h4>
                    <div className="contact-card row" >
                        {this.renderContactCards('investor')}
                    </div></div>) : null} */}

                </div>
            </div>
            </>
        )
    }
}
export default MasterContactList;