import axios from 'axios';
import _ from 'lodash';
import React, { Component } from 'react';
import swal from 'sweetalert';
import ContactDetailsModal from '../../../src/components/Contact List/ContactDetailsModal';
import LedgerComponent from '../../Ledger/containers';
import SummaryStartupsComponent from '../../SummaryStartups/components/SummaryStartups';
import common from '../../common';
import Header from '../../components/Header';
import HeaderImage from '../../components/HeaderImage';
import InvestorInvestmentModal from '../../components/InvestorInvestmentModal/InvestorInvestment';
import LoadingSpinner from '../../components/Loader';
import TButton from '../../components/TButton';
import WebinarInvestmentModal from '../../components/WebinarInvestmentModal/WebinarInvestment';
import AdjustInvestorAmountModal from './AdjustAmountModal';
import CompletedInvestmentDetailUsers from './CompletedInvestmentDetailUsers';
import ExecutedInvestmentDetailUsers from './ExecutedInvestmentDetailUsers';
import ExitedInProcessUsers from './ExitInProcess';
import ExitedUsers from './Exited';
import FundedInvestmentDetailUsers from './FundedInvestmentDetailUsers';
import InterestedInvestmentDetailUsers from './InterestedInvestmentDetailUsers';
import './InvestmentDetail.scss';
import InviteInvestmentDetailUsers from './InviteInvestmentDetailUsers';
import InvitedInvestmentDetailUsers from './InvitedInvestmentDetailUsers';

let userRole = '000';
class InvestmentDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            Role: '',
            loading: false,
            userId: null,
            showInvite: false,
            showInvited: false,
            showInterested: false,
            showExecuted: false,
            showFunded: false,
            showCompleted: false,
            showSummary: false,
            showLedger : false,
            showExitInProcess: false,
            showExited: false,
            id: null,
            inviteUsers: [],
            inviteUserPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isAllInviteUserChecked: false,
            investmentData: null,
            invitedUsers: [],
            invitedUsersPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isAllInvitedUserChecked: false,
            usersByStatus: [],
            usersByStatusPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isAllUserByStatusChecked: false,
            executedUsers: [],
            executedUsersPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            fundedUsers: [],
            fundedUsersPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isAllFundedUsersChecked: false,
            isAllowExitInProcessUsersChecked: false,
            exitInProcessUsers: [],
            exitInProcessEscrowUsers: [],
            exitInProcessDisbursemenUsers: [],
            exitInProcessUsersPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            isAllowExitedUsersChecked: false,
            exitedUsers: [],
            exitedUsersPagination: {
                currentPage: 0,
                totalPages: 1,
            },
            getCheckBoxAll: false,
            getRemoveCheckBox : [],
            getSelectedCheckBox : []
        }
    }

    componentWillMount() {
        const url = new URL(window.location.href);
        this.id = url.searchParams.get('id');
        window.localStorage.setItem('investmentForVIPAccess', this.id);
        userRole = window.localStorage.getItem('role');
        this.setState({
            name: window.localStorage.getItem('username'),
            userId: window.localStorage.getItem('id')
        });
        this.setState({ Role: userRole });
        this.getData();
    }

    getData() {
        let roleCode = window.localStorage.getItem('roleCode');
        if (roleCode === '003' || roleCode === '004' || roleCode === '005') {
            window.location.href = '/';
            return;
        }
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });
        const id = window.localStorage.getItem('id');
        this.setState({ id: id })
        axios.get(`${common.path.base_url}/investmentById/${this.id}`, {
            headers: {
                "Authorization": token
            }
        })
            .then((response) => {
                temp.setState({ loading: false, investmentData: response.data.data }, () => {
                    const url = new URL(window.location.href);
                    if (url.searchParams.get('activeTab')) {
                        temp.tabSelection(url.searchParams.get('activeTab'))
                    } else {
                        temp.setState({ showInvite: true })
                        temp.tabSelection('invite')
                    }

                });

                // temp.getInteresteddUsers();
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else if (error.response) {
                    swal({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                else {
                    swal({
                        title: "Network Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
                window.location.href = '/';
            });
    }
    getInviteUser(isFromLoadMore, filter, searchTags, tags, $sort, userIndicatorVal=0) {
        if (!this.state.investmentData) return;
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.inviteUserPagination && isFromLoadMore ? this.state.inviteUserPagination.currentPage + 1 : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            companyId: this.state.investmentData.companyId._id,
            userIndicator : userIndicatorVal
        }
        if (filter && filter.length > 0) {
            data.filter = filter;
        }
        if (searchTags && searchTags.length > 0) {
            data.searchTags = searchTags.map(tag => tag.name);
        }

        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        if ($sort) {
            data.$sort = $sort;
        }
        axios.post(`${common.path.base_url}/getInviteUsers/${this.id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                let users = response.data.data;
                if (response.data.data && response.data.data.length > 0) {
                    users = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                temp.setState({
                    loading: false,
                    inviteUsers: isFromLoadMore ? [...this.state.inviteUsers, ...users] : users,
                    inviteUserPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                    isAllInviteUserChecked: false,
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });

    }
    getInvitedUser(isFromLoadMore, tags, $sort, followType=0, userIndicator=0) {
        //console.log("asdsad1111");
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.invitedUsersPagination && isFromLoadMore ? this.state.invitedUsersPagination.currentPage + 1 : 1;
        //console.log(followType);
        // let queryString = `page=${currentPage}&limit=${common.paginationLimit}`;
        const data = {
            page: currentPage,
            limit: 200,//common.paginationLimit, //GKTest
            companyId: this.state.investmentData.companyId._id,
            followType: followType,
            userIndicator: userIndicator
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        if ($sort) {
            data.$sort = $sort;
        }
        axios.post(`${common.path.base_url}/getInvitedUsers/${this.id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                //console.log("sssssss44444444444444444",response.data);
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                temp.setState({
                    loading: false,
                    invitedUsers: isFromLoadMore ? [...this.state.invitedUsers, ...response.data.data] : response.data.data,
                    invitedUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                    isAllInvitedUserChecked: false,
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });

    }
    getInteresteddUsers() {
        //console.log("asdsad2222");
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        axios.get(`${common.path.base_url}/getInvitedUsers/${this.id}`, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                temp.setState({ loading: false, invitedUsers: response.data.data });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });

    }

    inviteInvestor(user, callback) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            investmentId: this.id,
            userId: user._id,
        };
        axios.post(`${common.path.base_url}/inviteUserForInvestment`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                swal({
                    title: "Email Invitation Sent",
                    text: `Invitation has been sent to ${user.emailAddress}`,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {

                    });
                temp.setState({ loading: false });
                temp.getInviteUser();
                if (callback) {
                    callback(true)
                }

            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    filterInvestorsHandler(filter, search, tags, $sort, userIndicatorVal) {
        if (!tags) tags = [];
        this.getInviteUser(false, filter, search, tags, $sort, userIndicatorVal)
        // let temp = this;
        // temp.setState({ loading: true });
        // let tokenpass = window.localStorage.getItem('token');
        // const data = {
        //     companyId: this.state.investmentData.companyId._id,
        //     investmentId: this.id,
        //     filter,
        //     search,
        //     tags,
        // };
        // axios.post(`${common.path.base_url}/filterInvestors`, data, {
        //     headers: {
        //         "Authorization": tokenpass
        //     }
        // })
        //     .then((response) => {
        //         temp.setState({ loading: false, inviteUsers: response.data.data });
        //         // temp.getInviteUser();
        //     })
        //     .catch(function (error) {
        //         temp.setState({ loading: false });
        //         swal({
        //             title: "Error!",
        //             text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
        //             icon: "error",
        //             button: "Ok!",
        //         });

        //     });
    }


    acceptParticipation(investmentDetailId, callback) {
        let temp = this;

        swal({
            title: "Are you sure?",
            text: "You want to accept participation for this user",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((accept) => {
                if (accept) {
                    temp.setState({ loading: true });
                    let tokenpass = window.localStorage.getItem('token');
                    axios.post(`${common.path.base_url}/acceptParticipation/${investmentDetailId}`, {}, {
                        headers: {
                            "Authorization": tokenpass
                        }
                    })
                        .then((response) => {
                            temp.setState({ loading: false });
                            temp.getExecutedUsers(false);
                            if (callback) {
                                callback(true);
                            }
                        })
                        .catch(function (error) {
                            temp.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });

                }
            })

    }
    acceptExceedAmount(investmentDetailId, callback) {
        let temp = this;

        swal({
            title: "Are you sure?",
            text: "You want to approve exceed max amount for this user",
            icon: "warning",
            buttons: ['Cancel', 'Yes'],
            dangerMode: true,
        })
            .then((accept) => {
                if (accept) {
                    temp.setState({ loading: true });
                    let tokenpass = window.localStorage.getItem('token');

                    axios.post(`${common.path.base_url}/acceptExceedAmount/${investmentDetailId}`, {}, {
                        headers: {
                            "Authorization": tokenpass
                        }
                    })
                        .then((response) => {
                            temp.setState({ loading: false });
                            temp.getInvitedUsersByStatus(common.investmentStatuses.interested, false);
                            if (callback) {
                                callback(true);
                            }
                        })
                        .catch(function (error) {
                            temp.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });

                }
            })

    }

    confirmDateHandler(investmentDetailId, fundedDate, callback) {
        if (!investmentDetailId || !fundedDate) return;
        let temp = this;

        swal({
            title: "Are you sure?",
            text: "You want to accept participation for this user",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((accept) => {
                if (accept) {
                    temp.setState({ loading: true });
                    let tokenpass = window.localStorage.getItem('token');
                    axios.post(`${common.path.base_url}/confirmInvestment/${investmentDetailId}`, { fundedDate }, {
                        headers: {
                            "Authorization": tokenpass
                        }
                    })
                        .then((response) => {
                            temp.setState({ loading: false });
                            temp.getFundedUsers(false);
                            if (callback) {
                                callback(true);
                            }
                        })
                        .catch(function (error) {
                            temp.setState({ loading: false });
                            if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                window.localStorage.clear();
                                window.location.href = '/';
                                return;
                            }
                            else {
                                swal({
                                    title: "Error!",
                                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                                    icon: "error",
                                    button: "Ok!",
                                });
                            }
                        });

                }
            })

    }
    getInvestmentTitle() {
        let title = '';
        if (this.state.investmentData) {
            const investmentData = this.state.investmentData;
            if (investmentData.companyId && investmentData.companyId.companyNickname) {
                // title += investmentData.companyId.companyNickname;
            } else {
                // title += investmentData.companyId.companyName;
            }
            window.localStorage.setItem('investmentCompanyName', investmentData.name);
            title += ' ' + investmentData.name;
            return title;
        }
        return '';
    }

    getInvestmentCompanyName() {
        let LLCName = '';
        if (this.state.investmentData) {
            const investmentData = this.state.investmentData;
            if (investmentData && investmentData.llcName) {
                LLCName += investmentData.llcName;
            }
            return LLCName;
        }
        return '';
    }

    showLoadingHandler(loading) {
        this.setState({ loading })
    }
    sendDocumentHandler(users) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            investmentId: this.id,
            users,
        };
        axios.post(`${common.path.base_url}/sendDocConfirmationEmail`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                swal({
                    title: "Doc Confirmation Email",
                    text: `Doc confirmation email has been sent`,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {

                    });
                temp.setState({ loading: false });
                temp.getInvitedUsersByStatus(common.investmentStatuses.complete, false);
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: "Email address is not available for this investor.",
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    inviteMultipleInvestor(users, filter, search, tags, $sort, userIndicatorVal) {
        console.log("inpp", userIndicatorVal);
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            investmentId: this.id,
            users,
        };
        axios.post(`${common.path.base_url}/inviteMultipleUserForInvestment`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                swal({
                    title: "Email Invitation Sent",
                    text: `Invitation has been sent to investors`,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {
                        
                    });
                
                temp.setState({ loading: false });
                temp.getInviteUser(false, filter, search, tags, $sort, userIndicatorVal);
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    getSelectedInvitees() {
        return this.state.inviteUsers.filter(user => user.isChecked === true);
    };

    filterCheckedUsers() {
        const users = this.getSelectedInvitees();
        return users.length === this.state.inviteUsers.length ? true : false;
    };

    onCheckedAllInviteeUser(event) {
        const users = this.state.inviteUsers && this.state.inviteUsers.length > 0 && this.state.inviteUsers.map((user, i) => {
            user.isChecked = event.target.checked;
            return user;
        });
        this.setState({ inviteUsers: users, isAllInviteUserChecked: this.filterCheckedUsers() });
    };

    onCheckedSingleInviteeUser(event, id) {
        const users = this.state.inviteUsers && this.state.inviteUsers.length > 0 && this.state.inviteUsers.map(user => {
            if (id === user._id)
                user.isChecked = event.target.checked;
            return user;
        });
        this.setState({ inviteUsers: users, isAllInviteUserChecked: this.filterCheckedUsers() });
    };

    inviteMultipleInvitees(filter, search, tags, $sort, userIndicatorVal) {
        let usersToBeInvited = this.getSelectedInvitees();
        if (usersToBeInvited.length > 0) {
            if (usersToBeInvited.length > common.inviteSendLimit) {
                usersToBeInvited = usersToBeInvited.slice(0, common.inviteSendLimit);
            }
            console.log("pp", userIndicatorVal);
            this.inviteMultipleInvestor(usersToBeInvited, filter, search, tags, $sort, userIndicatorVal);
        }
    };

    inviteUserSearchOnKeyChange = _.debounce((key, filter, tags) => {
        let tagsIds = []
        if (tags && tags.length > 0) {
            tagsIds = tags.map(tag => tag.id);
        }
        this.filterInvestorsHandler(filter, key, tagsIds)
        // key = key && key.toLowerCase();
        // let inviteUsers = [];
        // if (key) {
        //     this.state.inviteUsers.forEach((obj) => {
        //         let values = Object.values(obj);
        //         let found = false;
        //         values.forEach((ele) => (ele && ele.toString().toLowerCase().includes(key)) ? found = true : null);
        //         if (found) {
        //             inviteUsers.push(obj)
        //         }
        //     });
        //     this.setState({ inviteUsers })
        // } else {
        //     this.getInviteUser(false);
        // }

    }, 500);

    getInvitedUsersByStatus(status, isFromLoadMore, currentActiveStatus, $sort, tags) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.usersByStatusPagination
            && isFromLoadMore
            && currentActiveStatus === status
            ? this.state.usersByStatusPagination.currentPage + 1
            : 1;
        const data = {
            status,
            page: currentPage,
            limit: common.paginationLimit
        }
        if ($sort) {
            data.$sort = $sort;
        }
        if (tags && tags.length > 0) {
            data.tags = tags.map(tag => tag.name);
        }
        axios.post(`${common.path.base_url}/getInvitedUsersByStatus/${this.id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let usersByStatusArray = [];
                if (isFromLoadMore) {
                    usersByStatusArray = [...this.state.usersByStatus, ...response.data.data,];
                } else {
                    usersByStatusArray = response.data.data;
                }
                temp.setState({
                    loading: false,
                    usersByStatus: this.makeArrayUnique(usersByStatusArray),
                    usersByStatusPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                    isAllUserByStatusChecked: false,
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    getExecutedUsers(isFromLoadMore, tags, $sort) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.executedUsersPagination && isFromLoadMore
            ? this.state.executedUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        if ($sort) {
            data.$sort = $sort;
        }
        axios.post(`${common.path.base_url}/getExecutedUsers/${this.id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                let executedDataArray = [];
                if (isFromLoadMore) {
                    executedDataArray = [...this.state.executedUsers, ...response.data.data,];
                } else {
                    executedDataArray = response.data.data;
                }
                temp.setState({
                    loading: false,
                    executedUsers: this.makeArrayUnique(executedDataArray),
                    executedUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    getFundedUsers(isFromLoadMore, tags, $sort) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.fundedUsersPagination && isFromLoadMore
            ? this.state.fundedUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        axios.post(`${common.path.base_url}/getFundedUsers/${this.id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let fundedDataArray = [];
                if (isFromLoadMore) {
                    fundedDataArray = [...this.state.fundedUsers, ...response.data.data,];
                } else {
                    fundedDataArray = response.data.data;
                }
                temp.setState({
                    loading: false,
                    fundedUsers: this.makeArrayUnique(fundedDataArray),
                    fundedUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                    isAllFundedUsersChecked: false,
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }
    getExitInProcessUsers(isFromLoadMore, tags, $sort, filterType) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.exitInProcessUsersPagination && isFromLoadMore
            ? this.state.exitInProcessUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        data.filterType = filterType;
        // getCommunicationUsers
        axios.post(`${common.path.base_url}/getCommunicationUsers/${this.id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let exitInProcessUsers = [];
                if (isFromLoadMore) {
                    exitInProcessUsers = [...this.state.exitInProcessUsers, ...response.data.data,];
                } else {
                    exitInProcessUsers = response.data.data;
                }
                temp.setState({
                    loading: false,
                    exitInProcessUsers: this.makeArrayUnique(exitInProcessUsers),
                    exitInProcessUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                    isAllowExitInProcessUsersChecked: false,
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loading: false });
            });
    }

    getExitProcessEscrowUsers(isFromLoadMore, tags, $sort) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.exitInProcessUsersPagination && isFromLoadMore
            ? this.state.exitInProcessUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        // getCommunicationUsers
        axios.post(`${common.path.base_url}/getEscrowDisbursementUsers/${this.id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let exitInProcessEscrowUsers = [];
                if (isFromLoadMore) {
                    exitInProcessEscrowUsers = [...this.state.exitInProcessEscrowUsers, ...response.data.data,];
                } else {
                    exitInProcessEscrowUsers = response.data.data;
                }
                temp.setState({
                    loading: false,
                    exitInProcessEscrowUsers: this.makeArrayUnique(exitInProcessEscrowUsers),
                    exitInProcessUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                    isAllowExitInProcessUsersChecked: false,
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    getExitProcessInitialDisbursementUsers(isFromLoadMore, tags, $sort) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.exitInProcessUsersPagination && isFromLoadMore
            ? this.state.exitInProcessUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        // getCommunicationUsers
        axios.post(`${common.path.base_url}/getDisbursementUsers/${this.id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let exitInProcessDisbursemenUsers = [];
                if (isFromLoadMore) {
                    exitInProcessDisbursemenUsers = [...this.state.exitInProcessDisbursemenUsers, ...response.data.data,];
                } else {
                    exitInProcessDisbursemenUsers = response.data.data;
                }
                temp.setState({
                    loading: false,
                    exitInProcessDisbursemenUsers: this.makeArrayUnique(exitInProcessDisbursemenUsers),
                    exitInProcessUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                    isAllowExitInProcessUsersChecked: false,
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    getExitedUsers(isFromLoadMore, tags, $sort) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const currentPage = this.state.exitedUsersPagination && isFromLoadMore
            ? this.state.exitedUsersPagination.currentPage + 1
            : 1;
        const data = {
            page: currentPage,
            limit: common.paginationLimit,
            $sort: $sort ? $sort : null,
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        axios.post(`${common.path.base_url}/getExitedUsers/${this.id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data && response.data.data.length > 0 && response.data.data.map(user => {
                        user.isChecked = false;
                        return user;
                    });
                }
                let exitedUsers = [];
                if (isFromLoadMore) {
                    exitedUsers = [...this.state.exitInProcessUsers, ...response.data.data,];
                } else {
                    exitedUsers = response.data.data;
                }
                temp.setState({
                    loading: false,
                    exitedUsers: this.makeArrayUnique(exitedUsers),
                    exitedUsersPagination: { currentPage: response.data.currentPage, totalPages: response.data.totalPages, },
                    isAllowExitedUsersChecked: false,
                });
            })
            .catch(function (error) {
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
                temp.setState({ loadinglada: false });
            });
    }

    remindInvestors(users, investmentStatus) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            investmentId: this.id,
            users,
            investmentStatus
        };
        axios.post(`${common.path.base_url}/remindMultipleUserByStatus`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                swal({
                    title: "Email Invitation Sent",
                    text: `Reminder has been sent to investors`,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {

                    });
                temp.setState({ loading: false });
                if (investmentStatus === common.investmentStatuses.interested)
                    temp.getInvitedUsersByStatus(common.investmentStatuses.interested, false);
                else if (investmentStatus === common.investmentStatuses.executed)
                    temp.getFundedUsers(false);
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    sendfollowupToMultipleInvestors(users, message, tags, $sort, followType=0, subject, userIndicatorVal=0) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            investmentId: this.id,
            users,
            message,
            subject
        };
        axios.post(`${common.path.base_url}/followupMultipleInvestors`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                swal({
                    title: "Email Invitation Sent",
                    text: `Follow up has been sent to investors`,
                    icon: "success",
                    button: "Ok!",
                });
                //console.log(followType, ".....555.");
                temp.setState({ loading: false });
                temp.getInvitedUser(false, tags, $sort, followType, userIndicatorVal);
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Send Followup Error! (Multiple)",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    sendToMultipleInvestors(users, message) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            investmentId: this.id,
            users,
            message
        };
        axios.post(`${common.path.base_url}/followupMultipleInvestors`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                swal({
                    title: "Email Invitation Sent",
                    text: `Follow up has been sent to investors`,
                    icon: "success",
                    button: "Ok!",
                });
                temp.setState({ loading: false });
                temp.getInvitedUser(false);
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                }
                else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }

    remindInterestedInvestors(users) {
        if (users.length > 0) {
            this.remindInvestors(users, common.investmentStatuses.interested);
        }
    }

    tabSelection(tabValue) {
        switch (tabValue) {
            case 'invite':
                this.getInviteUser();
                this.setState({ showInvite: true, showInvited: false, showInterested: false, showExecuted: false, showFunded: false, showCompleted: false, showExitInProcess: false, showExited: false, showSummary:false, showLedger:false });
                break;
            case 'invited':
                //this.getInvitedUser();
                this.setState({ showInvite: false, showInvited: true, showInterested: false, showExecuted: false, showFunded: false, showCompleted: false, showExitInProcess: false, showExited: false, showSummary:false, showLedger:false });
                break;
            case 'interested':
                this.getInvitedUsersByStatus(common.investmentStatuses.interested, false);
                this.setState({ showInvite: false, showInvited: false, showInterested: true, showExecuted: false, showFunded: false, showCompleted: false, showExitInProcess: false, showExited: false, showSummary:false, showLedger:false });
                break;
            case 'executed':
                this.getExecutedUsers(false);
                this.setState({ showInvite: false, showInvited: false, showInterested: false, showExecuted: true, showFunded: false, showCompleted: false, showExitInProcess: false, showExited: false, showSummary:false, showLedger:false });
                break;
            case 'funded':
                this.getFundedUsers(false);
                this.setState({ showInvite: false, showInvited: false, showInterested: false, showExecuted: false, showFunded: true, showCompleted: false, showExitInProcess: false, showExited: false, showSummary:false, showLedger:false });
                break;
            case 'completed':
                this.getInvitedUsersByStatus(common.investmentStatuses.complete, false);
                this.setState({ showInvite: false, showInvited: false, showInterested: false, showExecuted: false, showFunded: false, showCompleted: true, showExitInProcess: false, showExited: false, showSummary:false, showLedger:false });
                break;
            case 'summary':
                //this.getInvitedUsersByStatus(common.investmentStatuses.complete, false);
                this.setState({ showInvite: false, showInvited: false, showInterested: false, showExecuted: false, showFunded: false, showCompleted: false, showExitInProcess: false, showExited: false, showSummary:true, showLedger:false });
                break;
            case 'ledger':
                this.setState({ showInvite: false, showInvited: false, showInterested: false, showExecuted: false, showFunded: false, showCompleted: false, showExitInProcess: false, showExited: false, showSummary:false, showLedger:true });
                break;
            case 'exitInProcess':
                this.getExitInProcessUsers(false);
                this.getExitProcessInitialDisbursementUsers(false);
                this.getExitProcessEscrowUsers(false);
                this.setState({ showInvite: false, showInvited: false, showInterested: false, showExecuted: false, showFunded: false, showCompleted: false, showExitInProcess: true, showExited: false, showSummary:false, showLedger:false });
                break;
            case 'exited':
                this.getExitedUsers(false);
                this.setState({ showInvite: false, showInvited: false, showInterested: false, showExecuted: false, showFunded: false, showCompleted: false, showExitInProcess: false, showExited: true, showSummary:false, showLedger:false });
                break;
            default:
                this.setState({ showInvite: true, showInvited: false, showInterested: false, showExecuted: false, showFunded: false, showCompleted: false, showExitInProcess: false, showExited: false, showSummary:false, showLedger:false });
                break;
        }
    }

    getSelectedInvitedUsers() {
        return this.state.invitedUsers.filter(user => user.isChecked === true);
    };

    filterCheckedInvitedUsers() {
        const users = this.getSelectedInvitedUsers();
        return users.length === this.state.invitedUsers.length ? true : false;
    };

    onCheckedAllInvitedUser(event) {

        const users = this.state.invitedUsers && this.state.invitedUsers.length > 0 && this.state.invitedUsers.map(user => {
            user.isChecked = event.target.checked;
            return user;
        });
        this.setState({ 
            invitedUsers: users, 
            isAllInvitedUserChecked: this.filterCheckedInvitedUsers(), 
            getCheckBoxAll: event.target.checked, 
            getSelectedCheckBox : [], 
            getRemoveCheckBox : []
        });


    };

    onCheckedSingleInvitedUser(event, id) {
        let removeCheckBox = [];
        let selectedCheckBox = [];
        const users = this.state.invitedUsers && this.state.invitedUsers.length > 0 && this.state.invitedUsers.map(user => {
            if (id === user._id) {
                
                if(user.user && user.user._id) {
                    const UserId = user.user._id;
                    
                    user.isChecked = event.target.checked;
                    
                    if( this.state.getCheckBoxAll ) {
                        let getRemoveCheckBox = this.state.getRemoveCheckBox; 

                        if( !getRemoveCheckBox.includes(UserId) ) {
                            let removeUserId = [...getRemoveCheckBox, UserId];
                            removeCheckBox = removeUserId;
                        } else {
                            
                            let allSelectCard = [...getRemoveCheckBox];
                            var index = allSelectCard.indexOf(UserId)
                            if (index !== -1) {
                                
                                allSelectCard.splice(index, 1);
                                if( allSelectCard.length > 0 ) {
                                    //this.setState({ getRemoveCheckBox: allSelectCard });
                                    removeCheckBox = allSelectCard;
                                } else {
                                    //this.setState({ getRemoveCheckBox: [] });
                                    removeCheckBox = [];
                                }
                            }
                        }
                    } else {
                        let getSelectedCheckBox = this.state.getSelectedCheckBox;
                        if( !getSelectedCheckBox.includes(UserId) ) {
                            let removeUserId = [...getSelectedCheckBox, UserId];
                            //this.setState({ getSelectedCheckBox: removeUserId });
                            selectedCheckBox = removeUserId;
                        } else {
                            
                            let allSelectCard = [...getSelectedCheckBox];
                            var index = allSelectCard.indexOf(UserId);
                            if (index !== -1) {
                                allSelectCard.splice(index, 1);
                                
                                if( allSelectCard.length > 0 ) {
                                    //this.setState({ getSelectedCheckBox: allSelectCard });
                                    selectedCheckBox = allSelectCard;
                                } else {
                                    //this.setState({ getSelectedCheckBox: [] });
                                    selectedCheckBox = [];
                                }
                            }
                        }
                    }
                }    
            }
            return user;
        });
        
        this.setState({ invitedUsers: users , getSelectedCheckBox : selectedCheckBox, getRemoveCheckBox : removeCheckBox});
    };

    followUpMultipleInvitedUsers(message, tags, $sort, followType=0, subject, userIndicatorVal=0) {
        const usersToBeFollowUp = this.getSelectedInvitedUsers();
        if (usersToBeFollowUp.length > 0 && message) {
            const users = usersToBeFollowUp.map(singleUserByStatus => { return { _id: singleUserByStatus.user._id } });
            console.log(followType, "......");
            this.sendfollowupToMultipleInvestors(users, message, tags, $sort, followType, subject, userIndicatorVal);
        }
    };
    sendMessageMultipleUsers(message, stateVariable) {
        // alert(stateVariable);
        const usersToBeSendMessage = this.getCheckedUsers(stateVariable);
        if (usersToBeSendMessage.length > 0 && message) {
            const users = usersToBeSendMessage.map(singleUserByStatus => singleUserByStatus.user._id);
            this.sendMessageUsers(users, stateVariable, message);
        }
    };

    getSelectedUsersByStatus() {
        return this.state.usersByStatus.filter(user => user.isChecked === true);
    };

    filterCheckedUsersByStatus() {
        const users = this.getSelectedUsersByStatus();
        return users.length === this.state.usersByStatus.length ? true : false;
    };

    onCheckedAllUsersByStatus(event) {
        const users = this.state.usersByStatus && this.state.usersByStatus.length > 0 && this.state.usersByStatus.map(user => {
            user.isChecked = event.target.checked;
            return user;
        });
        //this.setState({ userByStatus: users, isAllUserByStatusChecked: this.filterCheckedUsersByStatus() });
        this.setState({ 
            userByStatus: users, 
            isAllUserByStatusChecked: this.filterCheckedUsersByStatus(),
            getCheckBoxAll: event.target.checked, 
            getSelectedCheckBox : [], 
            getRemoveCheckBox : []
        });
    };

    getSelectedExecutedUsers() {
        return this.state.executedUsers.filter(user => user.isChecked === true);
    };

    onCheckedAllExecutedUsers(event) {
        const executedUsers = this.makeArrayUnique(this.state.executedUsers);

        const users = executedUsers && executedUsers.length > 0 && executedUsers.map(user => {
            user.isChecked = event.target.checked;
            return user;
        });
        this.setState({ 
            executedUsers: users,
            getCheckBoxAll: event.target.checked, 
            getSelectedCheckBox : [], 
            getRemoveCheckBox : []
        });
    };


    onCheckedSingleUserByStatus(event, id) {

        let removeCheckBox = [];
        let selectedCheckBox = [];

        const users = this.state.usersByStatus && this.state.usersByStatus.length > 0 && this.state.usersByStatus.map(singleUserByStatus => {
            if (id === singleUserByStatus.user._id) {
                
                console.log(id);

                singleUserByStatus.isChecked = event.target.checked;

                if( this.state.getCheckBoxAll ) {
                    let getRemoveCheckBox = this.state.getRemoveCheckBox; 

                    if( !getRemoveCheckBox.includes(id) ) {
                        let removeUserId = [...getRemoveCheckBox, id];
                        removeCheckBox = removeUserId;
                    } else {
                        
                        let allSelectCard = [...getRemoveCheckBox];
                        var index = allSelectCard.indexOf(id)
                        if (index !== -1) {
                            
                            allSelectCard.splice(index, 1);
                            if( allSelectCard.length > 0 ) {
                                //this.setState({ getRemoveCheckBox: allSelectCard });
                                removeCheckBox = allSelectCard;
                            } else {
                                //this.setState({ getRemoveCheckBox: [] });
                                removeCheckBox = [];
                            }
                        }
                    }
                } else {
                    let getSelectedCheckBox = this.state.getSelectedCheckBox;
                    if( !getSelectedCheckBox.includes(id) ) {
                        let removeUserId = [...getSelectedCheckBox, id];
                        //this.setState({ getSelectedCheckBox: removeUserId });
                        selectedCheckBox = removeUserId;
                    } else {
                        
                        let allSelectCard = [...getSelectedCheckBox];
                        var index = allSelectCard.indexOf(id);
                        if (index !== -1) {
                            allSelectCard.splice(index, 1);
                            
                            if( allSelectCard.length > 0 ) {
                                //this.setState({ getSelectedCheckBox: allSelectCard });
                                selectedCheckBox = allSelectCard;
                            } else {
                                //this.setState({ getSelectedCheckBox: [] });
                                selectedCheckBox = [];
                            }
                        }
                    }
                }
            }
            return singleUserByStatus;
        });
        this.setState({ userByStatus: users, getSelectedCheckBox : selectedCheckBox, getRemoveCheckBox : removeCheckBox });
    };

    onCheckedSingleExecutedUsers(event, id) {

        let removeCheckBox = [];
        let selectedCheckBox = [];
        const executedUsers = this.makeArrayUnique(this.state.executedUsers);

        const users = executedUsers && executedUsers.length > 0 && executedUsers.map(singleUser => {
            if (id === singleUser.user._id) {

                console.log(id);

                singleUser.isChecked = event.target.checked;

                if( this.state.getCheckBoxAll ) {
                    let getRemoveCheckBox = this.state.getRemoveCheckBox; 

                    if( !getRemoveCheckBox.includes(id) ) {
                        let removeUserId = [...getRemoveCheckBox, id];
                        removeCheckBox = removeUserId;
                    } else {
                        
                        let allSelectCard = [...getRemoveCheckBox];
                        var index = allSelectCard.indexOf(id)
                        if (index !== -1) {
                            
                            allSelectCard.splice(index, 1);
                            if( allSelectCard.length > 0 ) {
                                //this.setState({ getRemoveCheckBox: allSelectCard });
                                removeCheckBox = allSelectCard;
                            } else {
                                //this.setState({ getRemoveCheckBox: [] });
                                removeCheckBox = [];
                            }
                        }
                    }
                } else {
                    let getSelectedCheckBox = this.state.getSelectedCheckBox;
                    if( !getSelectedCheckBox.includes(id) ) {
                        let removeUserId = [...getSelectedCheckBox, id];
                        //this.setState({ getSelectedCheckBox: removeUserId });
                        selectedCheckBox = removeUserId;
                    } else {
                        
                        let allSelectCard = [...getSelectedCheckBox];
                        var index = allSelectCard.indexOf(id);
                        if (index !== -1) {
                            allSelectCard.splice(index, 1);
                            
                            if( allSelectCard.length > 0 ) {
                                //this.setState({ getSelectedCheckBox: allSelectCard });
                                selectedCheckBox = allSelectCard;
                            } else {
                                //this.setState({ getSelectedCheckBox: [] });
                                selectedCheckBox = [];
                            }
                        }
                    }
                }
            }
            return singleUser;
        });
        this.setState({ executedUsers: users, getSelectedCheckBox : selectedCheckBox, getRemoveCheckBox : removeCheckBox });
    };

    remindMultipleUsersByStatus() {
        const usersToBeReminded = this.getSelectedUsersByStatus();
        if (usersToBeReminded.length > 0) {
            const users = usersToBeReminded.map(singleUserByStatus => { return { _id: singleUserByStatus.user._id } });
            this.remindInterestedInvestors(users);
        }
    };
    openInvestorInvestmentModal() {
        document.getElementById('updateInvestment-btn').click();
        this.setState({ loading: false });
    }
    openInvestorAdjustAmountModal() {
        document.getElementById('adjustAmount-btn').click();
        this.setState({ loading: false });
    }
    openInvestorWebinarModal() {
        document.getElementById('webinarSetting-btn').click();
        this.setState({ loading: false });
    }
    addUpdateInvestorInvestmentModalCallback() {
        if (this.state.showInterested) {
            this.getInvitedUsersByStatus(common.investmentStatuses.interested, false);
        } else {
            this.getExecutedUsers(false);
        }
    }

    getCheckedFundedUsers() {
        return this.state.fundedUsers.filter(user => user.isChecked === true);
    };
    getNonFundedUsers() {
        return this.state.fundedUsers.filter(user => user.status === common.investmentStatuses.executed);
    }
    getCheckedExitInProcessUsers() {
        return this.state.exitInProcessUsers.filter(user => user.isChecked === true);
    };
    getCheckedExitedUsers() {
        return this.state.exitedUsers.filter(user => user.isChecked === true);
    };
    getNonExitInProcessUsers() {
        return this.state.exitInProcessUsers;
    }

    getCheckedUsers(stateVariable) {
        return this.state[stateVariable].filter(user => user.isChecked === true);

    }

    filterCheckedFundedUsers() {
        const checkedUsers = this.getCheckedFundedUsers();
        const totalNonFundedUsers = this.getNonFundedUsers();
        return checkedUsers.length === totalNonFundedUsers.length ? true : false;
    };
    filterCheckedExitInProcessUsers() {
        const checkedUsers = this.getCheckedExitInProcessUsers();
        const totalNonFundedUsers = this.getNonExitInProcessUsers();
        return checkedUsers.length === totalNonFundedUsers.length ? true : false;
    }

    _filterCheckedUsers(stateVariable) {
        const checkedUsers = this.getCheckedUsers(stateVariable);
        const totalNonFundedUsers = this.state[stateVariable];
        return checkedUsers.length === totalNonFundedUsers.length ? true : false;
    };

    onCheckedAllFundedUsers(event) {
        const users = this.state.fundedUsers && this.state.fundedUsers.length > 0 && this.state.fundedUsers.map(user => {
            if (user.status === common.investmentStatuses.executed) {
                user.isChecked = event.target.checked;
            }
            return user;
        });
        this.setState({ 
            fundedUsers: users, 
            isAllFundedUsersChecked: this.filterCheckedFundedUsers(), 
            getCheckBoxAll: event.target.checked, 
            getSelectedCheckBox : [], 
            getRemoveCheckBox : []
        });
    };

    onCheckedAllExitInProcessUsers(event) {
        const users = this.state.exitInProcessUsers && this.state.exitInProcessUsers.length > 0 && this.state.exitInProcessUsers.map(user => {
            user.isChecked = event.target.checked;
            return user;
        });
        this.setState({ exitInProcessUsers: users, isAllowExitInProcessUsersChecked: this._filterCheckedUsers('exitInProcessUsers') });
    };

    onCheckedAllExitedUsers(event) {
        const users = this.state.exitedUsers && this.state.exitedUsers.length > 0 && this.state.exitedUsers.map(user => {
            user.isChecked = event.target.checked;
            return user;
        });
        this.setState({ exitedUsers: users, isAllowExitedUsersChecked: this._filterCheckedUsers('exitedUsers') });
    };

    onCheckedSingleFundedUsers(event, id) {

        let removeCheckBox = [];
        let selectedCheckBox = [];

        const users = this.state.fundedUsers && this.state.fundedUsers.length > 0 && this.state.fundedUsers.map(singleFundedUser => {
            if (id === singleFundedUser.user._id && singleFundedUser.status === common.investmentStatuses.executed) {
                
                singleFundedUser.isChecked = event.target.checked;

                if( this.state.getCheckBoxAll ) {
                    let getRemoveCheckBox = this.state.getRemoveCheckBox; 

                    if( !getRemoveCheckBox.includes(id) ) {
                        let removeUserId = [...getRemoveCheckBox, id];
                        removeCheckBox = removeUserId;
                    } else {
                        
                        let allSelectCard = [...getRemoveCheckBox];
                        var index = allSelectCard.indexOf(id)
                        if (index !== -1) {
                            
                            allSelectCard.splice(index, 1);
                            if( allSelectCard.length > 0 ) {
                                //this.setState({ getRemoveCheckBox: allSelectCard });
                                removeCheckBox = allSelectCard;
                            } else {
                                //this.setState({ getRemoveCheckBox: [] });
                                removeCheckBox = [];
                            }
                        }
                    }
                } else {
                    let getSelectedCheckBox = this.state.getSelectedCheckBox;
                    if( !getSelectedCheckBox.includes(id) ) {
                        let removeUserId = [...getSelectedCheckBox, id];
                        //this.setState({ getSelectedCheckBox: removeUserId });
                        selectedCheckBox = removeUserId;
                    } else {
                        
                        let allSelectCard = [...getSelectedCheckBox];
                        var index = allSelectCard.indexOf(id);
                        if (index !== -1) {
                            allSelectCard.splice(index, 1);
                            
                            if( allSelectCard.length > 0 ) {
                                //this.setState({ getSelectedCheckBox: allSelectCard });
                                selectedCheckBox = allSelectCard;
                            } else {
                                //this.setState({ getSelectedCheckBox: [] });
                                selectedCheckBox = [];
                            }
                        }
                    }
                }

            }
            return singleFundedUser;
        });
        this.setState({ 
            fundedUsers: users, 
            //isAllFundedUsersChecked: this.filterCheckedFundedUsers() 
            getSelectedCheckBox : selectedCheckBox, 
            getRemoveCheckBox : removeCheckBox
        });
    };

    onCheckedSingleExitInProcessUser(event, id) {
        let checkedCheckboxCount = 0;
        const users = this.state.exitInProcessUsers && this.state.exitInProcessUsers.length > 0 && this.state.exitInProcessUsers.map(singleUser => {
            if (id === singleUser.user._id) {
                singleUser.isChecked = event.target.checked;

            }
            return singleUser;

        });
        this.setState({ exitInProcessUsers: users, isAllowExitInProcessUsersChecked: this._filterCheckedUsers('exitInProcessUsers') });
    };

    onCheckedSingleExitedUser(event, id) {
        let checkedCheckboxCount = 0;
        const users = this.state.exitedUsers && this.state.exitedUsers.length > 0 && this.state.exitedUsers.map(singleUser => {
            if (id === singleUser.user._id) {
                singleUser.isChecked = event.target.checked;

            }
            return singleUser;

        });
        this.setState({ exitedUsers: users, isAllowExitedUsersChecked: this._filterCheckedUsers('exitedUsers') });
    };

    remindFundedInvestors(users) {
        if (users.length > 0) {
            this.remindInvestors(users, common.investmentStatuses.executed);
        }
    }

    remindMultipleFundedUsers() {
        const usersToBeReminded = this.getCheckedFundedUsers();
        if (usersToBeReminded.length > 0) {
            const users = usersToBeReminded.map(singleFundedUser => { return { _id: singleFundedUser.user._id } });
            this.remindFundedInvestors(users);
        }
    };

    getCheckedCompletedUsers() {
        return this.state.usersByStatus.filter(user => user.isChecked === true);
    };

    getNonSubscriptionCompletedUsers() {
        return this.state.usersByStatus.filter(user => user.isSubcriptionComplete);
    }

    filterCheckedCompletedUsers() {
        const checkedUsers = this.getCheckedCompletedUsers();
        const totalNonSubComplUsers = this.getNonSubscriptionCompletedUsers();
        return checkedUsers.length === totalNonSubComplUsers.length ? true : false;
    };

    onCheckedAllCompletedUsers(event) {
        const users = this.state.usersByStatus && this.state.usersByStatus.length > 0 && this.state.usersByStatus.map(user => {
            if (user.isSubcriptionComplete) {
                user.isChecked = event.target.checked;
            }
            return user;
        });
        this.setState({ 
            userByStatus: users, 
            isAllUserByStatusChecked: this.filterCheckedCompletedUsers(),
            getCheckBoxAll: event.target.checked, 
            getSelectedCheckBox : [], 
            getRemoveCheckBox : [] 
        });
    };

    onCheckedSingleCompletedUsers(event, id) {
        
        let removeCheckBox = [];
        let selectedCheckBox = [];

        const users = this.state.usersByStatus && this.state.usersByStatus.length > 0 && this.state.usersByStatus.map(singleFundedUser => {
            if (id === singleFundedUser.user._id && singleFundedUser.isSubcriptionComplete) {
                singleFundedUser.isChecked = event.target.checked;
                //console.log(id);
                if( this.state.getCheckBoxAll ) {
                    let getRemoveCheckBox = this.state.getRemoveCheckBox; 

                    if( !getRemoveCheckBox.includes(id) ) {
                        let removeUserId = [...getRemoveCheckBox, id];
                        removeCheckBox = removeUserId;
                    } else {
                        
                        let allSelectCard = [...getRemoveCheckBox];
                        var index = allSelectCard.indexOf(id)
                        if (index !== -1) {
                            
                            allSelectCard.splice(index, 1);
                            if( allSelectCard.length > 0 ) {
                                //this.setState({ getRemoveCheckBox: allSelectCard });
                                removeCheckBox = allSelectCard;
                            } else {
                                //this.setState({ getRemoveCheckBox: [] });
                                removeCheckBox = [];
                            }
                        }
                    }
                } else {
                    let getSelectedCheckBox = this.state.getSelectedCheckBox;
                    if( !getSelectedCheckBox.includes(id) ) {
                        let removeUserId = [...getSelectedCheckBox, id];
                        //this.setState({ getSelectedCheckBox: removeUserId });
                        selectedCheckBox = removeUserId;
                    } else {
                        
                        let allSelectCard = [...getSelectedCheckBox];
                        var index = allSelectCard.indexOf(id);
                        if (index !== -1) {
                            allSelectCard.splice(index, 1);
                            
                            if( allSelectCard.length > 0 ) {
                                //this.setState({ getSelectedCheckBox: allSelectCard });
                                selectedCheckBox = allSelectCard;
                            } else {
                                //this.setState({ getSelectedCheckBox: [] });
                                selectedCheckBox = [];
                            }
                        }
                    }
                }
            }
            return singleFundedUser;
        });
        this.setState({ 
            userByStatus: users, 
            getSelectedCheckBox : selectedCheckBox, 
            getRemoveCheckBox : removeCheckBox
        });
    };

    sendDocument(users) {
        if (users.length > 0) {
            this.sendDocumentHandler(users);
        }
    }

    sendDocumentMultipleUser() {
        const usersToBeReminded = this.getCheckedCompletedUsers();
        if (usersToBeReminded.length > 0) {
            const users = usersToBeReminded.map(singleFundedUser => { return { _id: singleFundedUser.user._id } });
            this.sendDocument(users);
        }
    };
    cancelInvestment(id, type, callback) {
        if (id) {
            swal({
                title: "Are you sure?",
                text: "You want to cancel investor from current investment.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((action) => {
                    if (action) {
                        let temp = this;
                        temp.setState({ loading: true });
                        let token = window.localStorage.getItem('token');
                        axios.post(`${common.path.base_url}/cancelInvestorInvestment/${id}`, {}, {
                            headers: {
                                "Authorization": token
                            }
                        })
                            .then((response) => {
                                if (callback) {
                                    callback(true);
                                }
                                temp.setState({ loading: false });
                                if (type === 'invited') {
                                    temp.getInvitedUser(false);
                                } else if (type === 'Interested') {
                                    temp.getInvitedUsersByStatus(common.investmentStatuses.interested, false);
                                } else if (type === 'Not Interested') {
                                    temp.getInvitedUsersByStatus(common.investmentStatuses.notInterested, false);
                                } else if (type === 'executed') {
                                    temp.getExecutedUsers(false);
                                } else if (type === 'funded') {
                                    temp.getFundedUsers(false);
                                } else if (type === 'completed') {
                                    temp.getInvitedUsersByStatus(common.investmentStatuses.complete, false);
                                } else if (type === 'exitInProcess') {
                                    temp.getExitInProcessUsers(false);
                                    temp.getExitProcessInitialDisbursementUsers(false);
                                    temp.getExitProcessEscrowUsers(false);
                                }


                            })
                            .catch(function (error) {
                                temp.setState({ loading: false });
                                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                    window.localStorage.clear();
                                    window.location.href = '/';
                                    return;
                                }
                                else if (error.response) {
                                    swal({
                                        title: "Error!",
                                        text: error.response.data.message,
                                        icon: "error",
                                        button: "Ok!",
                                    });
                                }
                                else {
                                    swal({
                                        title: "Network Error!",
                                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                        icon: "error",
                                        button: "Ok!",
                                    });
                                }
                            });
                    }
                })
                .catch(error => {
                })

        }

    }
    reInviteInvestment(data, type) {
        if (data && data._id) {
            swal({
                title: `Are you sure you want to re-invite ${data.user.name} for this investment?`,
                text: "You can re-invite the investor from beginning or choose to resume where you've cancelled them before.",
                icon: "warning",
                confirmButtonColor: "#ef534a",
                buttons: {
                    catch: {
                        text: "Start Over",
                        value: "Start Over",
                    },
                    Resume: true,
                    cancel: "Cancel",
                },
                dangerMode: true,
            })
                .then((action) => {
                    let status = 'cancel';
                    switch (action) {
                        case "Resume":
                            status = 'resume';
                            break;

                        case "Start Over":
                            status = 'start';
                            break;
                        default:
                            status = 'cancel';
                    }
                    if (status !== 'cancel') {
                        let temp = this;
                        temp.setState({ loading: true });
                        let token = window.localStorage.getItem('token');
                        axios.post(`${common.path.base_url}/reInviteInvestment/${data._id}`, { status }, {
                            headers: {
                                "Authorization": token
                            }
                        })
                            .then((response) => {
                                temp.setState({ loading: false });
                                temp.getInvitedUsersByStatus(common.investmentStatuses.cancelled, false);

                            })
                            .catch(function (error) {
                                temp.setState({ loading: false });
                                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                                    window.localStorage.clear();
                                    window.location.href = '/';
                                    return;
                                }
                                else if (error.response) {
                                    swal({
                                        title: "Error!",
                                        text: error.response.data.message,
                                        icon: "error",
                                        button: "Ok!",
                                    });
                                }
                                else {
                                    swal({
                                        title: "Network Error!",
                                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                                        icon: "error",
                                        button: "Ok!",
                                    });
                                }
                            });
                    }
                })
                .catch(error => {
                })
        }
    }


    makeArrayUnique(array) {
        let uniqueIdsArray = [];
        let uniqueArray = [];
        if (array && array.length > 0) {
            array.forEach(item => {
                if (uniqueIdsArray.indexOf(item._id.toString()) === -1) {
                    uniqueIdsArray.push(item._id.toString());
                    uniqueArray.push(item);
                }
            });
        }
        return uniqueArray;
    }
    sendMessageUsers(users, type, message) {
        let temp = this;
        temp.setState({ loading: true });
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            users,
            type,
            message,
        };
        axios.post(`${common.path.base_url}/sendMessage/${this.id}`, data, {
            headers: {
                "Authorization": tokenpass
            }
        })
            .then((response) => {
                swal({
                    title: "Message Sent",
                    text: `Message has been sent to investors`,
                    icon: "success",
                    button: "Ok!",
                })
                    .then(function () {

                    });
                temp.setState({ loading: false });
                if (type === 'exitInProcess') {
                    temp.getExitInProcessUsers(false);
                    temp.getExitProcessInitialDisbursementUsers(false);
                    temp.getExitProcessEscrowUsers(false);
                } else {
                    temp.getExitedUsers(false);
                }
            })
            .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                    window.localStorage.clear();
                    window.location.href = '/';
                    return;
                } else {
                    swal({
                        title: "Error!",
                        text: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something Went Wrong',
                        icon: "error",
                        button: "Ok!",
                    });
                }
            });
    }
    cancelExitInProcess(id) {
    }
    adjustInvestmentAmountCallback(type) {
        if (type === 'executed') {
            this.getExecutedUsers(false);
        } else if (type === 'funded') {
            this.getFundedUsers(false);
        } else if (type === 'completed') {
            this.getInvitedUsersByStatus(common.investmentStatuses.complete, false);
        } else if (type === 'interested') {
            this.getInvitedUsersByStatus(common.investmentStatuses.interested, false);
        }
    }
    openContactDetailModal() {
        document.getElementById('contactDetails-btn').click();
        this.setState({ loading: false });
    }

    resetCheckBox() {
        console.log("asdasdsad");
        this.setState({ 
            getCheckBoxAll: false, 
            getSelectedCheckBox : [], 
            getRemoveCheckBox : []
        });
    }

    render() {
        return (

            <div>
                <InvestorInvestmentModal
                    onRef={(ref) => this.investorInvestmentModalChild = ref}
                    addUpdateInvestorInvestmentModalCallback={() => this.addUpdateInvestorInvestmentModalCallback()}
                />
                <AdjustInvestorAmountModal
                    onRef={(ref) => this.investorAdjustInvestmentModalChild = ref}
                    addUpdateInvestorInvestmentModalCallback={(type) => this.adjustInvestmentAmountCallback(type)}
                />
                <WebinarInvestmentModal 
                    onRef={(ref) => this.webinarInvestmentModalChild = ref}
                    investmentData = { this.state.investmentData }
                />
                <TButton onClick={() => { }} id='updateInvestment-btn' dataTarget={`#updateInvestmentModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { }} id='adjustAmount-btn' dataTarget={`#adjustInvestmentModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <TButton onClick={() => { }} id='webinarSetting-btn' dataTarget={`#webinarSettingModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <ContactDetailsModal
                    onRef={(ref) => this.viewContactUserDetailsChild = ref}
                    addInviteSuccess={() => this.addInviteSuccessHandler(this)}
                    {...this.props}
                />
                <TButton onClick={() => { }} id='contactDetails-btn' dataTarget={`#contactDetailsModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                {this.state.loading ? <LoadingSpinner /> : ""}
                <Header
                    username={this.state.name}
                    role={this.state.Role}
                />
                <HeaderImage
                    heading={this.state.Role}
                />
                <div className="main-container-cls">
                    {/* <div>
                        <div className="header-section wrapper dashboad-header-section">
                            <div>
                                <div className="header-txt">{this.getInvestmentTitle()}</div>
                                <div className="header-txt-small">{this.state.investmentData && this.state.showExitInProcess ? this.state.investmentData.exitProcessType === 'ipo' ? 'IPO' : 'Acquisition' : ''}</div>
                            </div> */}
                    {/* <div className="header-section wrapper dashboad-header-section">
                                <div className="header-txt">{this.getInvestmentTitle()}</div>
                                {this.state.investmentData && this.state.investmentData.stage === "closed" ?
                                    <TButton text='VIP ACCESS' className='invite-btn' onClick={() => this.props.history.push(`/investmentVIPAccess?InvestmentID=${this.id}`)} />
                                    : ''}
                            </div> */}
                    {/* <div className="hr-line wrapper"></div> */}
                    <div>
                        <div className='top-btn-head'>
                            <TButton text='COMMUNICATIONS' className='head-btn-top investmentdetail-btn' onClick={() => this.props.history.push(`/investment-communication?InvestmentID=${this.id}`)} />

                            <TButton text='MGMT FEES & CARRY' className='head-btn-top investmentdetail-btn' onClick={() => this.props.history.push(`/managementFees-Carry?InvestmentID=${this.id}`)} />
                                
                            <TButton text='WEBINAR REPORT' className='head-btn-top investmentdetail-btn' onClick={() => this.props.history.push(`/webinar-report?id=${this.id}`)} />

                            <TButton text='WEBINAR Setting' className='head-btn-top investmentdetail-btn' onClick={() => { this.setState(this.webinarInvestmentModalChild.setInvestment(this.state.investmentData, () => this.openInvestorWebinarModal())) }} />

                            {this.state.investmentData && this.state.investmentData.stage === "closed" ?
                                <TButton text='VIP ACCESS' className='head-btn-top investmentdetail-btn' onClick={() => this.props.history.push(`/investmentVIPAccess?InvestmentID=${this.id}`)} />
                                : ''}
                        </div>    

                        <div className="header-section wrapper dashboad-header-section">
                            <div>
                                <div className="header-txt">{this.getInvestmentTitle()}</div>
                                <div >{this.getInvestmentCompanyName()}</div>
                                <div className="header-txt-small">
                                    {this.state.investmentData && this.state.showExitInProcess &&
                                        this.state.investmentData.exitProcessType === 'ipo' ? 'IPO' :
                                        this.state.investmentData && this.state.showExitInProcess && this.state.investmentData.exitProcessType === 'acquisitionwithescrow' ? "Acquisition With Escrow" :
                                            this.state.investmentData && this.state.showExitInProcess && this.state.investmentData.exitProcessType === 'acquisition' ? 'Acquisition' : ''}
                                </div>
                            </div>
                            <div className='contact-header'>
                                <nav className="contact-navbar">
                                    <button className={`navbtn cust-nav-tab ${this.state.showInvite ? 'active' : ''}`} onClick={() => this.tabSelection('invite')}>CONTACTS</button>
                                    <button className={`navbtn cust-nav-tab ${this.state.showInvited ? 'active' : ''}`} onClick={() => this.tabSelection('invited')}>INVITED</button>
                                    <button className={`navbtn cust-nav-tab ${this.state.showInterested ? 'active' : ''}`} onClick={() => this.tabSelection('interested')}>DOCS CREATED</button>
                                    <button className={`navbtn cust-nav-tab ${this.state.showExecuted ? 'active' : ''}`} onClick={() => this.tabSelection('executed')}>NEED APPROVAL</button>
                                    <button className={`navbtn cust-nav-tab ${this.state.showFunded ? 'active' : ''}`} style={{ width: 155 }} onClick={() => this.tabSelection('funded')}>APPROVED TO FUND</button>
                                    <button className={`navbtn cust-nav-tab ${this.state.showCompleted ? 'active' : ''}`} onClick={() => this.tabSelection('completed')}>FUNDED</button>
                                    <button className={`navbtn cust-nav-tab ${this.state.showSummary ? 'active' : ''}`} onClick={() => this.tabSelection('summary')}>SUMMARY</button>
                                    <button className={`navbtn cust-nav-tab ${this.state.showLedger ? 'active' : ''}`} onClick={() => this.tabSelection('ledger')}>LEDGER</button>
                                    {/* <button className={`navbtn ${this.state.showExitInProcess ? 'active' : ''}`} onClick={() => this.tabSelection('exitInProcess')}>EXIT IN PROCESS</button>
                                    <button className={`navbtn ${this.state.showExited ? 'active' : ''}`} onClick={() => this.tabSelection('exited')}>EXITED</button> */}
                                </nav>
                            </div>
                        </div>
                        {/* <div className="hr-line wrapper"></div> */}
                        <div className="investmentdetailsbuttons-box">
                            {/* <TButton text='Summary' className='investmentdetail-btn' onClick={() => this.props.history.push(`/summaryDetail?InvestmentID=${this.id}`)} /> */}
                            
                            {/* <TButton text='MGMT FEES & CARRY' className='investmentdetail-btn' onClick={() => this.props.history.push(`/managementFees-Carry?InvestmentID=${this.id}`)} />
                            {this.state.investmentData && this.state.investmentData.stage === "closed" ?
                                <TButton text='VIP ACCESS' className='investmentdetail-btn' onClick={() => this.props.history.push(`/investmentVIPAccess?InvestmentID=${this.id}`)} />
                                : ''} */}
                        </div>


                        <div className="company-section wrapper">
                            {
                                this.state.showInvite && this.id &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <InviteInvestmentDetailUsers
                                            id={this.id}
                                            inviteUsers={this.makeArrayUnique(this.state.inviteUsers)}
                                            pagination={this.state.inviteUserPagination}
                                            inviteInvestor={(user, callback) => this.inviteInvestor(user, callback)}
                                            filterInvestors={(filter, search, tags, sort, userIndicatorVal) => this.filterInvestorsHandler(filter, search, tags, sort, userIndicatorVal)}
                                            getInviteUser={(isFromLoadMore, filter, searchTags, tags, sort) => this.getInviteUser(isFromLoadMore, filter, searchTags, tags, sort)}
                                            onCheckedAllInviteeUser={(event) => this.onCheckedAllInviteeUser(event)}
                                            isAllUserChecked={this.state.isAllInviteUserChecked}
                                            onCheckedSingleInviteeUser={(event, id) => this.onCheckedSingleInviteeUser(event, id)}
                                            inviteMultipleInvitees={(filter, search, tags, $sort, userIndicatorVal) => this.inviteMultipleInvitees(filter, search, tags, $sort, userIndicatorVal)}
                                            stage={this.state.investmentData && this.state.investmentData.stage}
                                            showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id, "fromInvestmentDetail"), this.openContactDetailModal())}
                                            {...this.props}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.showInvited &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <InvitedInvestmentDetailUsers
                                            id={this.id}
                                            showLoading={(loading) => this.showLoadingHandler(loading)}
                                            invitedUsers={this.makeArrayUnique(this.state.invitedUsers)}
                                            pagination={this.state.invitedUsersPagination}
                                            getInvitedUser={(isFromLoadMore, tags, sort, followType, userIndicator) => this.getInvitedUser(isFromLoadMore, tags, sort, followType, userIndicator)}
                                            onCheckedAllInvitedUser={(event) => this.onCheckedAllInvitedUser(event)}
                                            isAllInvitedUserChecked={this.state.isAllInvitedUserChecked}
                                            onCheckedSingleInvitedUser={(event, id) => this.onCheckedSingleInvitedUser(event, id)}
                                            followUpMultipleInvitedUsers={(message, tags, $sort, followType, subject, userIndicatorVal) => this.followUpMultipleInvitedUsers(message, tags, $sort, followType, subject, userIndicatorVal)}
                                            investmentData={this.state.investmentData}
                                            getSelectedInvitedUsers={() => this.getSelectedInvitedUsers()}
                                            onCancelInvestment={(id, type, callback) => this.cancelInvestment(id, type, callback)}
                                            showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id, "fromInvestmentDetail"), this.openContactDetailModal())}
                                            getCheckBoxAll = { this.state.getCheckBoxAll }
                                            getRemoveCheckBox = { this.state.getRemoveCheckBox }
                                            getSelectedCheckBox = { this.state.getSelectedCheckBox }
                                            //toggleFollowup ={(dataCard) => this.toggleFollowup(dataCard) }
                                            adjustExceedAmount={(data) => { this.setState(this.investorAdjustInvestmentModalChild.setInvestment(data, 'interested', () => this.openInvestorAdjustAmountModal(), this.state.investmentData )) }}
                                            {...this.props}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.showInterested &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <InterestedInvestmentDetailUsers
                                            id={this.id}
                                            usersByStatus={this.state.usersByStatus}
                                            pagination={this.state.usersByStatusPagination}
                                            getUsersByStatus={(status, isFromLoadMore, currentActiveStatus, sort) => this.getInvitedUsersByStatus(status, isFromLoadMore, currentActiveStatus, sort)}
                                            isAllUserByStatusChecked={this.state.isAllUserByStatusChecked}
                                            getSelectedUsersByStatus={() => this.getSelectedUsersByStatus()}
                                            onCheckedSingleUserByStatus={(event, id) => this.onCheckedSingleUserByStatus(event, id)}
                                            onCheckedAllUsersByStatus={(event) => this.onCheckedAllUsersByStatus(event)}
                                            remindInterestedInvestors={(users) => this.remindInterestedInvestors(users)}
                                            remindMultipleUsersByStatus={() => this.remindMultipleUsersByStatus()}
                                            onCancelInvestment={(id, type) => this.cancelInvestment(id, type)}
                                            onReInviteInvestment={(data, type) => this.reInviteInvestment(data, type)}
                                            adjustExceedAmount={(data) => { this.setState(this.investorAdjustInvestmentModalChild.setInvestment(data, 'interested', () => this.openInvestorAdjustAmountModal(), this.state.investmentData )) }}
                                            acceptExceedAmount={(investmentDetailId) => this.acceptExceedAmount(investmentDetailId)}
                                            showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id, "fromInvestmentDetail"), this.openContactDetailModal())}
                                            investmentData={this.state.investmentData}
                                            resetCheckBox = { (i) => this.resetCheckBox() }
                                            getCheckBoxAll = { this.state.getCheckBoxAll }
                                            getRemoveCheckBox = { this.state.getRemoveCheckBox }
                                            getSelectedCheckBox = { this.state.getSelectedCheckBox }
                                            {...this.props}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.showExecuted &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ExecutedInvestmentDetailUsers
                                            id={this.id}
                                            executedUsers={this.makeArrayUnique(this.state.executedUsers)}
                                            pagination={this.state.executedUsersPagination}
                                            getExecutedUsers={(isFromLoadMore, tags, sort) => this.getExecutedUsers(isFromLoadMore, tags, sort)}
                                            acceptParticipation={(investmentDetailId, callback) => this.acceptParticipation(investmentDetailId, callback)}
                                            onCancelInvestment={(id, type, callback) => this.cancelInvestment(id, type, callback)}
                                            adjustExceedAmount={(data) => { this.setState(this.investorAdjustInvestmentModalChild.setInvestment(data, 'executed', () => this.openInvestorAdjustAmountModal(), this.state.investmentData )) }}
                                            showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id, "fromInvestmentDetail"), this.openContactDetailModal())}
                                            onCheckedSingleExecutedUsers={(event, id) => this.onCheckedSingleExecutedUsers(event, id)}
                                            onCheckedAllExecutedUsers = {(event) => this.onCheckedAllExecutedUsers(event)}
                                            getSelectedExecutedUsers={ () => this.getSelectedExecutedUsers() }
                                            investmentData={this.state.investmentData}
                                            resetCheckBox = { (i) => this.resetCheckBox() }
                                            getCheckBoxAll = { this.state.getCheckBoxAll }
                                            getRemoveCheckBox = { this.state.getRemoveCheckBox }
                                            getSelectedCheckBox = { this.state.getSelectedCheckBox }
                                            {...this.props}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.showFunded &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <FundedInvestmentDetailUsers
                                            id={this.id}
                                            fundedUsers={this.makeArrayUnique(this.state.fundedUsers)}
                                            pagination={this.state.fundedUsersPagination}
                                            getFundedUsers={(isFromLoadMore, tags, sort) => this.getFundedUsers(isFromLoadMore, tags, sort)}
                                            confirmDate={(investmentDetailId, date, callback) => this.confirmDateHandler(investmentDetailId, date, callback)}
                                            isAllFundedUsersChecked={this.state.isAllFundedUsersChecked}
                                            getCheckedFundedUsers={() => this.getCheckedFundedUsers()}
                                            onCheckedSingleFundedUsers={(event, id) => this.onCheckedSingleFundedUsers(event, id)}
                                            onCheckedAllFundedUsers={(event) => this.onCheckedAllFundedUsers(event)}
                                            remindFundedInvestors={(users) => this.remindFundedInvestors(users)}
                                            remindMultipleFundedUsers={() => this.remindMultipleFundedUsers()}
                                            onCancelInvestment={(id, type, callback) => this.cancelInvestment(id, type, callback)}
                                            adjustExceedAmount={(data) => { this.setState(this.investorAdjustInvestmentModalChild.setInvestment(data, 'funded', () => this.openInvestorAdjustAmountModal(), this.state.investmentData )) }}
                                            showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id, "fromInvestmentDetail"), this.openContactDetailModal())}
                                            investmentData={this.state.investmentData}
                                            resetCheckBox = { (i) => this.resetCheckBox() }
                                            getCheckBoxAll = { this.state.getCheckBoxAll }
                                            getRemoveCheckBox = { this.state.getRemoveCheckBox }
                                            getSelectedCheckBox = { this.state.getSelectedCheckBox }
                                            {...this.props}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.showCompleted &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <CompletedInvestmentDetailUsers
                                            id={this.id}
                                            userByStatus={this.state.usersByStatus}
                                            pagination={this.state.usersByStatusPagination}
                                            getUsersByStatus={(status, isFromLoadMore, currentActiveStatus, sort, tags) => this.getInvitedUsersByStatus(status, isFromLoadMore, currentActiveStatus, sort, tags)}
                                            isAllUserByStatusChecked={this.state.isAllUserByStatusChecked}
                                            getCheckedCompletedUsers={() => this.getCheckedCompletedUsers()}
                                            onCheckedSingleCompletedUsers={(event, id) => this.onCheckedSingleCompletedUsers(event, id)}
                                            onCheckedAllCompletedUsers={(event) => this.onCheckedAllCompletedUsers(event)}
                                            sendDocument={(users) => this.sendDocument(users)}
                                            sendDocumentMultipleUser={() => this.sendDocumentMultipleUser()}
                                            onCancelInvestment={(id, type) => this.cancelInvestment(id, type)}
                                            adjustExceedAmount={(data) => { this.setState(this.investorAdjustInvestmentModalChild.setInvestment(data, 'completed', () => this.openInvestorAdjustAmountModal(), this.state.investmentData )) }}
                                            showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id, "fromInvestmentDetail"), this.openContactDetailModal())}
                                            investmentData={this.state.investmentData}
                                            resetCheckBox = { (i) => this.resetCheckBox() }
                                            getCheckBoxAll = { this.state.getCheckBoxAll }
                                            getRemoveCheckBox = { this.state.getRemoveCheckBox }
                                            getSelectedCheckBox = { this.state.getSelectedCheckBox }
                                            {...this.props}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.showSummary &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <SummaryStartupsComponent />
                                    </div>
                                </div>
                            }
                            {
                                this.state.showLedger &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <LedgerComponent {...this.props} />
                                    </div>
                                </div>
                            }
                            {
                                this.state.showExitInProcess &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ExitedInProcessUsers
                                            id={this.id}
                                            exitInProcessUsers={this.makeArrayUnique(this.state.exitInProcessUsers)}
                                            exitInProcessEscrowUsers={this.makeArrayUnique(this.state.exitInProcessEscrowUsers)}
                                            exitInProcessDisbursemenUsers={this.makeArrayUnique(this.state.exitInProcessDisbursemenUsers)}
                                            pagination={this.state.executedUsersPagination}
                                            getExitInProcessUsers={(isFromLoadMore, tags, sort, filterType) => this.getExitInProcessUsers(isFromLoadMore, tags, sort, filterType)}
                                            getExitProcessInitialDisbursementUsers={(isFromLoadMore, tags, sort) => this.getExitProcessInitialDisbursementUsers(isFromLoadMore, tags, sort)}
                                            getExitProcessEscrowUsers={(isFromLoadMore, tags, sort) => this.getExitProcessEscrowUsers(isFromLoadMore, tags, sort)}
                                            // isAllowExitInProcessUsersChecked={this.state.isAllowExitInProcessUsersChecked}
                                            // getCheckedExitInProcessUsers={() => this.getCheckedExitInProcessUsers()}
                                            // onCheckedSingleExitInProcessUser={(event, id) => this.onCheckedSingleExitInProcessUser(event, id)}
                                            // onCheckedAllExitInProcessUsers={(event) => this.onCheckedAllExitInProcessUsers(event)}
                                            // sendSingleUserMessage={(array, type) => this.sendSingleUserMessage(array, type)}
                                            // onCancelInvestment={(id, type, callback) => this.cancelInvestment(id, type, callback)}
                                            // sendMessageMultipleUsers={(message, type) => this.sendMessageMultipleUsers(message, type)}
                                            showLoading={(loading) => this.showLoadingHandler(loading)}
                                            investmentData={this.state.investmentData}
                                            showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id, "fromInvestmentDetail"), this.openContactDetailModal())}
                                            {...this.props}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.showExited &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ExitedUsers
                                            id={this.id}
                                            exitedUsers={this.makeArrayUnique(this.state.exitedUsers)}
                                            pagination={this.state.exitedUsersPagination}
                                            getExitedUsers={(isFromLoadMore, tags, sort) => this.getExitedUsers(isFromLoadMore, tags, sort)}
                                            isAllowExitedUsersChecked={this.state.isAllowExitedUsersChecked}
                                            getCheckedExitedUsers={() => this.getCheckedExitedUsers()}
                                            onCheckedSingleExitedUser={(event, id) => this.onCheckedSingleExitedUser(event, id)}
                                            onCheckedAllExitedUsers={(event) => this.onCheckedAllExitedUsers(event)}
                                            showLoading={(loading) => this.showLoadingHandler(loading)}
                                            sendSingleUserMessage={(array, type) => this.sendSingleUserMessage(array, type)}
                                            sendMessageMultipleUsers={(message, type) => this.sendMessageMultipleUsers(message, type)}
                                            showDataInPopup={(id) => this.setState(this.viewContactUserDetailsChild.setContactDetailsData(id, "fromInvestmentDetail"), this.openContactDetailModal())}
                                            {...this.props}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div >
            </div >
        );
    }

}

export default InvestmentDetail;