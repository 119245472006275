import React, { Component } from 'react';
import ReactTags from 'react-tag-autocomplete'
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import './InvestmentDetail.scss';
import TButton from '../../components/TButton';
import common from '../../common';
import swal from 'sweetalert';
import axios from 'axios';
import downarrow from '../../images/icons/downarrow.svg';
import { CSVLink, CSVDownload } from "react-csv";
import ContactSendMessageModal  from '../../components/ContactSendMessageModal';
import { setSummaryInvestmentData  } from '../../SummaryStartups/actions/summaryStartups';

class FundedInvestmentDetailUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fundedDates: [],
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' },
                { id: 'Spire One', name: 'Spire One' },
                { id: 'International', name: 'International' },
                { id: 'Federal', name: 'Federal' },
                { id: 'CXO', name: 'CXO' }],
            arrowType: {
                'user.name': 'down',
                emailId: 'down',
                executedDate: 'down',
                company: 'down',
                investmentAmount: 'down',
                paymentDate: 'down'
            },
            listOfUsers:[],
            exportLoading:false,
        }
        this.csvLink = React.createRef();
    }
    componentDidMount() {

        this.props.resetCheckBox();
        this.props.setSummaryInvestmentData(this.props.investmentData);
        if (this.props.id) {
            this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=funded`)
        }
        if (this.props.fundedUsers && this.props.fundedUsers.length > 0) {
            const fundedDates = [];
            this.props.fundedUsers.forEach(user => {
                fundedDates[user._id] = {
                    fundedDate: user.fundedDate ? new Date(user.fundedDate) : '',
                }
            });
            this.setState({ fundedDates });
        }
        window.addEventListener('scroll', this.handleScroll, false);
    }
    componentDidUpdate(prevProps) {
        if (this.props.fundedUsers !== prevProps.fundedUsers) {
            if (this.props.fundedUsers && this.props.fundedUsers.length > 0) {
                const fundedDates = [];
                this.props.fundedUsers.forEach(user => {
                    fundedDates[user._id] = {
                        fundedDate: user.fundedDate ? new Date(user.fundedDate) : '',
                    }
                });
                this.setState({ fundedDates });
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }
    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.props.pagination.currentPage < this.props.pagination.totalPages)) {
            this.props.getFundedUsers(true);
        }
    };
    mapDateFormat(mongoDate) {
        if (!mongoDate) return 'N/A';
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }
    handleDateChange(date, id) {
        const fundedDates = { ...this.state.fundedDates };
        if (fundedDates[id]) {
            fundedDates[id].fundedDate = date;
            this.setState({ fundedDates });
        }

    }

    onRemindClick(userId) {
        this.props.remindFundedInvestors([{ _id: userId }]);
    }
    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }
    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.props.getFundedUsers(false, this.mapTags())
        });
    }
    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.props.getFundedUsers(false, this.mapTags())
        });
    }
    viewDocumentRedirect(envId, type) {
        window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}`, '_blank', '', 2)

    }

    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        this.props.getFundedUsers(false, this.mapTags(), $sort)
        this.setState({ arrowType });
    }
    getUserCitizenForm(isForeignCitizen) {
        return isForeignCitizen === true ? 'W8' : 'W9'
    }
    // For Re-Send Doc
    onReSendClick(type, userid, investmentId) {
        swal({
            title: "Are you sure?",
            text: "You want to Re-Send this Doc.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willReSend) => {
                if (willReSend) {
                    let temp = this;
                    let token = window.localStorage.getItem('token');
                    temp.setState({ loading: true });
                    const data = {
                        investmentId: investmentId,
                        userId: userid,
                    };
                    if (type === 'isQSA') {
                        data.isQSAResend = true;
                    }
                    if (type === 'isW8') {
                        data.isW8Resend = true;
                    }
                    if (type === 'isW9') {
                        data.isW9Resend = true;
                    }
                    if (type === 'sideletter') {
                        data.isSignSideLetterResend = true;
                    }
                    
                    axios.post(`${common.path.base_url}/reSendDoc`, data, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then(function (response) {
                            swal({
                                title: "Updated!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            })
                        })
                        .catch(function (error) {
                            swal({
                                title: "Error!",
                                text: error.response.data.message,
                                icon: "error",
                                button: "Ok!",
                            });
                            temp.setState({ loading: false, loadinglada2: false });
                        });
                }
            });
    }

    approvedFundExportCSV() {
        
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            investmentId:this.props.id
        }
        const tags = this.mapTags();
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        
        this.setState({ listOfUsers: [], exportLoading:true });

        let getCSVUrl = null;
        axios.post(`${common.path.base_url}/getApprovedFundExportCSV`, data, {
            headers: {
                "Authorization": tokenpass
            }
        
        }).then((response) => {
            const UserArray = response.data.data;
            //console.log(UserArray);
            //const UserArray = contacts.userArray;
            if(UserArray.length > 0) {
                let arr = [];
                var userExData = new Promise((resolve, reject) => {
                    UserArray.forEach(function(val, index, UserArray) {
                        
                        let ObjCode = { 'First Name': (val.firstName) ? val.firstName : "",
                                        'Middle Name': (val.middleName) ? val.middleName : "",
                                        'Last Name': (val.lastName) ? val.lastName : "",     
                                        Email: (val.emailId) ? val.emailId : "",
                                        Company: (val.company) ? val.company : "-",
                                        Amount: (val.formattedAmount) ? "$"+val.formattedAmount : "$0.0",
                                        'Payment Type': (val.paymentType) ? val.paymentType : "",
                                        Tags: (val.user.investorCategories) ? val.user.investorCategories.join(', ') : "",   
                                    };
                        arr.push(ObjCode);
                        if (index === UserArray.length -1) resolve();
                    });
                    
                });
                userExData.then(() => {
                    this.setState({ listOfUsers: arr, exportLoading:false });
                    this.csvLink.current.link.click();
                });
            } else {

                swal({
                    title: "Export",
                    text: "Data not found.",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                });
                this.setState({ listOfUsers: [], exportLoading:false });
            }

        }).catch(function (error) {
            console.log(error);
            this.setState({ listOfUsers: [], exportLoading:false });
        });
    }

    sendAllMessage() {
        //console.log(this.state.allChkEmail, this.state.allChkMobile);
        document.getElementById('openModal-btn').click();
    }

    resetSMSEmailCard() {
        window.location.reload();
    }

    render() {
        const areCheckboxActive = this.props.getCheckedFundedUsers().length > 0 ? true : false;
        let emailArr = [];
        let mobileArr = [];
        
        if( this.props.getCheckedFundedUsers().length > 0 ) {
            
            this.props.getCheckedFundedUsers().map(userLoop => {
                const users = userLoop.user;
                if( users.emailAddress != '' && users.emailAddress != 'undefined' && users.emailAddress != null ) {
                    emailArr.push( users.emailAddress );
                }

                if( users.mobile != '' && users.mobile != 'undefined' && users.mobile != null ) {
                    mobileArr.push( users.mobile );
                }
                
            });
            //console.log(emailArr, mobileArr);
            //this.setState({ allChkEmail : emailArr, allChkMobile : mobileArr });
        }
        return (
            <div className="box-wrapper-main">
                <TButton onClick={() => { }} id='openModal-btn' dataTarget={`#sendMessageModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <ContactSendMessageModal 
                    allCheckbox= { emailArr }
                    allSelectMobile = { mobileArr }
                    resetSMSEmailCard = { () => this.resetSMSEmailCard()}
                    webinarType="YES"
                    getCheckBoxAll = { this.props.getCheckBoxAll }
                    getRemoveCheckBox = { this.props.getRemoveCheckBox }
                    getSelectedCheckBox = { this.props.getSelectedCheckBox }
                    pageLocation = "approvedToFund"
                    totalCount = { this.props.getCheckedFundedUsers().length }
                />
                <div className='col-xs-12 col-sm-5 search-status allInvestmentDetail header-investment' style={{ display: 'contents' }}>
                    <div className='search' style={{ width: '65%', padding: 15, display: 'inline-block', float:'left' }}>
                        <ReactTags
                            allowNew={true}
                            placeholder='Search'
                            suggestions={this.state.suggestions}
                            tags={this.state.searchInputTags}
                            handleDelete={this.handleSearchInputDelete.bind(this)}
                            handleAddition={this.handleSearchInputAddition.bind(this)} />
                    </div>
                    <div className='follow_up' style={{ width: '12%', display:'inline-block', padding:'17px 17px 0px' }}>
                        <div className='follow_up' style={{ width: '100%' }}>
                            <a onClick={() => this.approvedFundExportCSV()} className="tsg-btn invite export-btn" >
                                {
                                    this.state.exportLoading ? 
                                    <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </>
                                    :
                                    <><i className="fa fa-file-text ex-icon" aria-hidden="true" />EXPORT</>
                                }
                            </a>
                            <CSVLink 
                                data={ this.state.listOfUsers } 
                                filename={"approved-to-fund.csv"}
                                className="hidden"
                                ref={this.csvLink}
                                target="_blank"
                            />
                        </div>    
                    </div>
                    <div style={{ width: '10%', display:'inline-block', padding:'0px 0px 3px' }} >
                        <TButton
                            text='Remind Selected'
                            className='invite-btn'
                            onClick={() => this.props.remindMultipleFundedUsers()}
                            dataTarget={`#followUpModal`}
                            dataToggle={`modal`}
                            disabled={!areCheckboxActive}
                            style={{ padding:'20px 10px' }}
                        />
                    </div>
                    <div style={{ width: '10%', display:'inline-block', marginBottom:'0px' }} >
                        <button type='button' href='javascript:void(0)' className="tsg-btn invite sendMessBtn" onClick={(e) => this.sendAllMessage(e) } disabled={!areCheckboxActive} style={{  marginLeft: '10px' }} >
                            SEND MESSAGE
                        </button>
                    </div>
                </div>
                <div className="custom-table-view table-responsive">
                    <table className="table table-custom sortable">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(event) => this.props.onCheckedAllFundedUsers(event)}
                                        checked={this.props.isAllFundedUsersChecked}
                                    />
                                    <label>&nbsp; &nbsp;All</label>
                                </th>
                                <th>NAME<i className={`arrow ${this.state.arrowType['user.name']}`} onClick={() => this.toggleArrow('user.name')}></i></th>
                                <th>EMAIL<i className={`arrow ${this.state.arrowType['emailId']}`} onClick={() => this.toggleArrow('emailId')}></i></th>
                                <th>DATE<i className={`arrow ${this.state.arrowType['executedDate']}`} onClick={() => this.toggleArrow('executedDate')}></i></th>
                                <th>COMPANY<i className={`arrow ${this.state.arrowType['company']}`} onClick={() => this.toggleArrow('company')}></i></th>
                                <th>AMOUNT<i className={`arrow ${this.state.arrowType['investmentAmount']}`} onClick={() => this.toggleArrow('investmentAmount')}></i></th>
                                <th>PAYMENT DATE<i className={`arrow ${this.state.arrowType['paymentDate']}`} onClick={() => this.toggleArrow('paymentDate')}></i></th>
                                <th>MEMO/#</th>
                                <th>TYPE</th>
                                <th>CONFIRMATION DATE</th>
                                <th>View Documents</th>
                                <th></th>
                                <th></th>
                                <th>
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.fundedUsers.length > 0 &&
                                this.props.fundedUsers.map(paymentUser => {
                                    return (
                                        <tr key={paymentUser._id}>
                                            <td>
                                                {
                                                    paymentUser.status === common.investmentStatuses.executed &&
                                                    <input
                                                        type="checkbox"
                                                        onChange={(event) => this.props.onCheckedSingleFundedUsers(event, paymentUser.user._id)}
                                                        checked={paymentUser.isChecked}
                                                    />
                                                }
                                            </td>
                                            <td className="username-row"
                                                // onClick={() => this.props.history.push(`/viewProfile?id=${paymentUser.user._id}`)}
                                                onClick={() => { this.props.showDataInPopup(paymentUser.user._id) }}>
                                                {`${paymentUser.firstName} ${paymentUser.lastName}`}
                                            </td>
                                            <td>
                                                {paymentUser.emailId}
                                            </td>
                                            <td>
                                                {this.mapDateFormat(paymentUser.executedDate)}
                                            </td>
                                            <td>
                                                {paymentUser.investorCompanyName ? paymentUser.investorCompanyName : paymentUser.companyName ? paymentUser.companyName : ''}
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    value={paymentUser.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                <br />
                                                <a style={{ color: '#ef534a', cursor: 'pointer' }} onClick={() => this.props.adjustExceedAmount(paymentUser)}>ADJUST</a> &nbsp; &nbsp;
                                            </td>

                                            <td>
                                                {this.mapDateFormat(paymentUser.paymentDate)}
                                            </td>
                                            <td> {paymentUser.memoNo}</td>
                                            <td>{paymentUser.paymentDate ? paymentUser.paymentType.toUpperCase() : 'N/A'}</td>
                                            <td> <DatePicker
                                                selected={this.state.fundedDates && this.state.fundedDates[paymentUser._id] && this.state.fundedDates[paymentUser._id].fundedDate}
                                                onChange={(date) => this.handleDateChange(date, paymentUser._id)}
                                                maxDate={new Date()}
                                            /></td>
                                            <td>
                                                <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(paymentUser.subscriptionEnvId, 'Subscription')}>View QSA Doc.</a> <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.onReSendClick('isQSA', paymentUser.user._id, paymentUser.investmentId)}>| Re-Send</a><br />
                                                <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(paymentUser.w9EnvId, this.getUserCitizenForm(paymentUser.isForeignCitizen))}>
                                                    {
                                                        paymentUser.isForeignCitizen ? 'View W-8 Doc.' : 'View W-9 Doc.'
                                                    }
                                                </a> <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.onReSendClick(paymentUser.isForeignCitizen ? 'isW8' : 'isW9', paymentUser.user._id, paymentUser.investmentId)}>| Re-Send</a><br />
                                                
                                                {(!paymentUser.sideletterEnvId) ? "" : 
                                                <>
                                                <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(paymentUser.sideletterEnvId, 'sideletter')}>View Side Letter</a> <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.onReSendClick('sideletter', paymentUser.user._id, paymentUser.investmentId)}>| Re-Send</a>
                                                </>    
                                                }

                                            </td>
                                            <td>
                                                {( paymentUser.isW9Resend || paymentUser.isW8Resend || paymentUser.isQSAResend || paymentUser.isSignSideLetterResend ) ? (<span style={{ color: '#ef534a', }} >Re-Sign Pending</span>) :
                                                    !paymentUser.fundedDate &&
                                                    <TButton text={`CONFIRMED`} className='invite-btn' onClick={() => this.props.confirmDate(paymentUser._id, this.state.fundedDates && this.state.fundedDates[paymentUser._id] && this.state.fundedDates[paymentUser._id].fundedDate, (isSuccess) => {
                                                        if (isSuccess) {
                                                            this.setState({ searchInputTags: [] });
                                                        }
                                                    })} />
                                                }
                                                {/* {paymentUser.isW9Resend || paymentUser.isW8Resend || paymentUser.isQSAResend || (<span style={{ color: '#ef534a', }} >Re-Sign Pending</span>)}
                                                {
                                                    !paymentUser.fundedDate &&
                                                    <TButton text={`CONFIRMED`} className='invite-btn' onClick={() => this.props.confirmDate(paymentUser._id, this.state.fundedDates && this.state.fundedDates[paymentUser._id] && this.state.fundedDates[paymentUser._id].fundedDate, (isSuccess) => {
                                                        if (isSuccess) {
                                                            this.setState({ searchInputTags: [] });
                                                        }
                                                    })} />
                                                } */}
                                            </td>
                                            <td>
                                                <TButton text='CANCEL' className='invite-btn'
                                                    onClick={() => this.props.onCancelInvestment(paymentUser._id, 'funded', (isSuccess) => {
                                                        if (isSuccess) {
                                                            this.setState({ searchInputTags: [] });
                                                        }
                                                    })}
                                                />
                                            </td>
                                            <td>
                                                {
                                                    paymentUser.status === common.investmentStatuses.executed &&
                                                    <TButton text='Remind' className='invite-btn' onClick={() => this.onRemindClick(paymentUser.user._id)} />
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                this.props.fundedUsers.length === 0 &&
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td> No investors found for funded!</td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSummaryInvestmentData: bindActionCreators(setSummaryInvestmentData, dispatch),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(FundedInvestmentDetailUsers));