import axios from 'axios';
import _ from 'lodash';
import React, { Component } from 'react';
import swal from 'sweetalert';
import common from '../../../common';
import Header from '../../../components/Header';
import HeaderImage from '../../../components/HeaderImage';
import search from '../../../images/icons/search.svg';
import '../../InvestmentDetail/InvestmentDetail.scss';
import "./communication.scss";
import TButton from '../../../components/TButton';
import ViewInvetsmentMessageModal from "./viewMessageModel"

class InvestmentCommunication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            communicationSummary: [],
        }
    }

    componentWillMount() {
        this.setState({ userName: window.localStorage.getItem('username'), })
        this.setState({ Role: window.localStorage.getItem('role') });
        this.setState({ investmentcompanyname: window.localStorage.getItem('investmentCompanyName') });
        const url = new URL(window.location.href);
        this.InvestmentId = url.searchParams.get('InvestmentID');
    }

    componentDidMount() {
        if(this.InvestmentId){
            this.getInvestmentCommunicationsList();
        } else {
            this.getMCLCommunicationsList();
        }
    }

    getInvestmentCommunicationsList() {
        let roleCode = window.localStorage.getItem('roleCode');
        if (roleCode === '003' || roleCode === '004' || roleCode === '005') {
            window.location.href = '/';
            return;
        }

        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });

        const data = {
            investmentId: this.InvestmentId,
        }

        axios.post(`${common.path.base_url}/getInvestmentCommunicationSummary`, data, {
            headers: { "Authorization": token }
        }).then((response) => {
            if (response.data.data) {
                temp.setState({ loading: false, communicationSummary: response.data.data });
            }
        }).catch(function (error) {
            temp.setState({ loading: false });
            if (common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });
            } else {
                swal({
                    title: "Network Error!",
                    text: "Something went wrong",
                    icon: "error",
                    button: "Ok!",
                });
            }
            window.location.href = '/';
        });
    }
    getMCLCommunicationsList() {
        let roleCode = window.localStorage.getItem('roleCode');
        if (roleCode === '003' || roleCode === '004' || roleCode === '005') {
            window.location.href = '/';
            return;
        }

        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });

        axios.get(`${common.path.base_url}/getMCLCommunicationsSummary`, {
            headers: { "Authorization": token }
        }).then((response) => {
            if (response.data.data) {
                temp.setState({ loading: false, communicationSummary: response.data.data });
            }
        }).catch(function (error) {
            temp.setState({ loading: false });
            if (common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });
            } else {
                swal({
                    title: "Network Error!",
                    text: "Something went wrong",
                    icon: "error",
                    button: "Ok!",
                });
            }
            window.location.href = '/';
        });
    }

    searchInvestmentCommunicationsList = _.debounce((key) => {
        let temp = this;
        let token = window.localStorage.getItem('token');
        temp.setState({ loading: true });

        const data = {
            investmentId: this.InvestmentId,
            searchValue: key
        };

        axios.post(`${common.path.base_url}/getInvestmentCommunicationSummary`, data, {
            headers: {
                "Authorization": token
            }
        }).then((response) => {
            temp.setState({ loading: false, communicationSummary: response.data.data });
        }).catch(function (error) {
            temp.setState({ loading: false });
            if (common.AUTH_EXPIRED === error.response.data.message) {
                window.localStorage.clear();
                window.location.href = '/';
                return;
            } else if (error.response) {
                swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                });
            } else {
                swal({
                    title: "Network Error!",
                    text: "Something went wrong",
                    icon: "error",
                    button: "Ok!",
                });
            }
            window.location.href = '/';
        });
    }, 800);

    formatDate(createdAt) {
        const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }).format(new Date(createdAt));
        return formattedDate;
    }

    formatTime(createdAt) {
        const formattedTime = new Intl.DateTimeFormat('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23' // 24-hour format
        }).format(new Date(createdAt));
        return formattedTime;
    }

    openViewMessageModal() {
        document.getElementById('viewMessage-btn').click();
    }

    render() {
        return (
            <div>
                <ViewInvetsmentMessageModal
                    onRef={(ref) => this.ViewInvetsmentMessageModal = ref}
                    communicationList={this.state.communicationSummary}
                    {...this.props}
                />
                <TButton onClick={()=>{}} id='viewMessage-btn' dataTarget={`#ViewInvetsmentMessageModal`} dataToggle={`modal`} style={{ display: 'none' }} />

                <Header username={this.state.userName} role={this.state.Role} />
                <HeaderImage heading={this.state.Role} />
                <div className="wrapper">
                    <div className="management-main-content header-section dashboad-header-section">
                        <div className="management-title-content" style={{ alignContent: "end" }}>
                            <label className="header-title-txt">{ this.InvestmentId ? "Investment" : "MCL"} Communication Summary</label>
                            <h4 className="header-txt">{this.InvestmentId && this.state.investmentcompanyname}</h4>
                        </div>

                        <button className='btn btn-danger'
                            style={{ postion: "absolute", right: "0px", margin: "15px", }}
                            onClick={() => { this.InvestmentId ? this.getInvestmentCommunicationsList() : this.getMCLCommunicationsList() }}>
                            REFRESH
                        </button>
                    </div>
                </div>
                <div className="box-wrapper-main wrapper">
                    <div className="custom-table-view">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th>DATE & TIME</th>
                                    <th>TOTAL USERS</th>
                                    <th>PROCESSED EMAIL/SMS</th>
                                    <th>EMAIL STATUS</th>
                                    <th>SMS STATUS</th>
                                    <th>TASK STATUS</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.communicationSummary.length > 0 && this.state.communicationSummary.map(user => {
                                        return (
                                            <tr key={user._id}>
                                                <td> {this.formatDate(user.createdAt)}&nbsp;&nbsp;{this.formatTime(user.createdAt)}</td>
                                                <td>{user.totalCount}</td>
                                                <td>{user.emailTotalCount}/{user.smsTotalCount}</td>
                                                <td><span className="delivered">{user.emailDeliveredCount}</span>/<span className="failed">{user.emailFailedCount}</span></td>
                                                <td><span className="delivered">{user.smsDeliveredCount}</span>/<span className="failed">{user.smsFailedCount}</span></td>
                                                <td className={ user.status === "done" ? "delivered": "sending" }> {user.status === "done" ? "COMPLETED": "STARTED"} </td>
                                                <td> 
                                                    <button className='btn btn-danger'
                                                        style={{ postion: "absolute", right: "0px",marginRight:"9px" }}
                                                        onClick={() => { this.InvestmentId ?
                                                            this.props.history.push(`/communication-feed?InvestmentID=${user.investmentId}&communicationId=${user._id}`) :
                                                            this.props.history.push(`/mcl-feed?communicationId=${user._id}`)
                                                        }}>
                                                        VIEW DETAILS
                                                    </button> 
                                                    <span 
                                                        style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }}
                                                        onClick={() => {this.openViewMessageModal();this.ViewInvetsmentMessageModal.setData(user);}}>
                                                        VIEW MESSAGE
                                                    </span> 
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.state.communicationSummary.length === 0 &&
                                    <tr>
                                        <td>No Communication found!</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default InvestmentCommunication;