import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import ReactTags from 'react-tag-autocomplete'
import TButton from '../../components/TButton';
import FollowUpModal from './FollowUpModal';
import Select from 'react-select';
import './InvestmentDetail.scss';
import axios from 'axios';
import common from '../../common';
import { CSVLink, CSVDownload } from "react-csv";
import ContactSendMessageModal  from '../../components/ContactSendMessageModal';
import { setSummaryInvestmentData  } from '../../SummaryStartups/actions/summaryStartups';

class InvitedInvestmentDetailUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            followUp: [],
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' },
                { id: 'Spire One', name: 'Spire One' },
                { id: 'International', name: 'International' },
                { id: 'Federal', name: 'Federal' },
                { id: 'CXO', name: 'CXO' }],
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
                createdAt: 'down',
            },
            followupOption : [
                { value: 0, label: 'All' },
                { value: 1, label: 'Not Followed Up' },
                { value: 2, label: 'Followed Up' },
            ],
            UserIndicatorOption : [
                { value: 0, label: 'All' },
                { value: 1, label: 'Invitation Accepted' },
                { value: 2, label: 'Invitation Not Accepted' },
            ],
            followupOptionVal : 0,
            userIndicatorVal : 0,
            $sort: null,
            listOfUsers:[],
            exportLoading:false,
            allChkEmail : [],
            allChkMobile : []
            // getCheckBoxAll = { this.state.getCheckBoxAll }
            // getRemoveCheckBox = { this.state.getRemoveCheckBox }
            // getSelectedCheckBox = { this.state.getSelectedCheckBox }
        }
        this.csvLink = React.createRef();
    }

    componentDidMount() {

        this.props.setSummaryInvestmentData(this.props.investmentData);
        if (this.props.id) {
            //this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=invited`)
            //this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=invited&followup=${this.state.followupOptionVal}&userStatus=${this.state.userIndicatorVal}`)
            const url = new URL(window.location.href);
            
            if ( (url.searchParams.get('followup') &&  url.searchParams.get('followup')>=0 && url.searchParams.get('followup') <=2 ) || (url.searchParams.get('userStatus') && url.searchParams.get('userStatus')>=0 && url.searchParams.get('userStatus') <=2 ) ) {    

                this.setState({ "followupOptionVal" : url.searchParams.get('followup') })
                this.setState({ "userIndicatorVal" : url.searchParams.get('userStatus') })
                this.props.getInvitedUser(false, this.mapTags(), this.state.$sort, url.searchParams.get('followup'), url.searchParams.get('userStatus') )
            
            } else {
                this.props.getInvitedUser(false, this.mapTags(), this.state.$sort, this.state.followupOptionVal, this.state.userIndicatorVal )
            } 
        }
        window.addEventListener('scroll', this.handleScroll, false);
        
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    componentWillUpdate() {
        console.log("goviiiiiiiiiiiiii");
        ///this.autoCallSMSEmailMobile();
    }

    mapDateFormat(mongoDate) {
        if (!mongoDate) return null;
        let date = new Date(mongoDate);
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }

    followUpHandler(loading, user) {
        console.log("asdsadsadasd......111");
        if (user) {
            const followUp = [...this.state.followUp];
            followUp[user._id] = true;
            this.setState({ followUp });
            this.props.showLoading(loading);
        } else if (loading === 'clear') {
            this.setState({searchInputTags: []})
        } else {
            this.props.showLoading(loading);
        }
        
    }

    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.props.pagination.currentPage < this.props.pagination.totalPages)) {
            this.props.getInvitedUser(true, this.mapTags(), this.state.$sort, this.state.followupOptionVal, this.state.userIndicatorVal)
        }
    };
    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }
    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.props.getInvitedUser(false, this.mapTags(), this.state.$sort, this.state.followupOptionVal, this.state.userIndicatorVal)
        });
    }
    handleSearchInputAddition(tag) { 
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.props.getInvitedUser(false, this.mapTags(), this.state.$sort, this.state.followupOptionVal, this.state.userIndicatorVal)
        });
    }
    handleMultipleSendFollow(message, subject) {
        console.log(this.state.followupOptionVal); 
        this.props.followUpMultipleInvitedUsers( message, this.mapTags(), this.state.$sort, this.state.followupOptionVal, subject, this.state.userIndicatorVal )
    }

    toggleArrow(fieldType) {
        let arrowType  = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] =  -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        this.props.getInvitedUser(false, this.mapTags(), $sort, this.state.followupOptionVal, this.state.userIndicatorVal)
        this.setState({arrowType, $sort });
    }

    handleOnFollowupChanged(event) {
        //console.log(event.value);
        this.setState({followupOptionVal: event.value}, () =>{

            this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=invited&followup=${this.state.followupOptionVal}&userStatus=${this.state.userIndicatorVal}`)
            this.props.getInvitedUser(false, this.mapTags(), this.state.$sort, this.state.followupOptionVal, this.state.userIndicatorVal)
        });
    }

    userIndicatorChanged(event) {
        //console.log(event.value);
        this.setState({userIndicatorVal: event.value}, () =>{
            
            this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=invited&followup=${this.state.followupOptionVal}&userStatus=${this.state.userIndicatorVal}`)
            
            this.props.getInvitedUser(false, this.mapTags(), this.state.$sort, this.state.followupOptionVal, this.state.userIndicatorVal)
        });
    }

    invitedExportData(){
        
        let tokenpass = window.localStorage.getItem('token');
        let tags = this.mapTags();
        const data = {
            followType: this.state.followupOptionVal,
            companyId: this.props.investmentData.companyId._id,
            investmentId:this.props.id,
            userIndicatorVal : this.state.userIndicatorVal
        }
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        
        this.setState({ listOfUsers: [], exportLoading:true });

        let getCSVUrl = null;
        axios.post(`${common.path.base_url}/getInvitedExportCSV`, data, {
            headers: {
                "Authorization": tokenpass
            }
        
        }).then((response) => {
            const UserArray = response.data.data;
            //console.log(UserArray);
            //const UserArray = contacts.userArray;
            let arr = [];
            
            var userExData = new Promise((resolve, reject) => {
                UserArray.forEach(function(val, index, UserArray) {
                    
                    let ObjCode = { 'First Name': (val.userId.firstName) ? val.userId.firstName : "",
                                    'Middle Name': (val.userId.middleName) ? val.userId.middleName : "",
                                    'Last Name': (val.userId.lastName) ? val.userId.lastName : "",     
                                    Email: (val.emailAddress) ? val.emailAddress : "",
                                    Company: (val.company) ? val.company : "",
                                    Followup: (val.isFollowupSend) ? "Yes" : "No", 
                                    Tags: (val.userId.investorCategories) ? val.userId.investorCategories.join(', ') : "",  
                                };
                    arr.push(ObjCode);
                    if (index === UserArray.length -1) resolve();
                });
                
            });
            userExData.then(() => {
                this.setState({ listOfUsers: arr, exportLoading:false });
                this.csvLink.current.link.click();
            });

        }).catch(function (error) {
            console.log(error);
            this.setState({ listOfUsers: [], exportLoading:false });
        });
    }

    sendAllMessage() {
        document.getElementById('openModal-btn').click();
    }

    resetSMSEmailCard() {
        window.location.reload();
    }

    render() {
        ///console.log(this.props.invitedUsers);
        let invitedUsers = this.props.invitedUsers.map(invitedUser => {
            if (invitedUser && invitedUser.userId && (invitedUser.status === 'Invited' || invitedUser.status === 'Participate') ) {
                const user = invitedUser.userId;
                invitedUser.firstName = user.firstName;
                invitedUser.lastName = user.lastName;
                invitedUser.name = user.name;
                invitedUser.user = user;
                invitedUser.investorCompanyName = user.investorCompanyName;
                invitedUser.companyName = user.companyName;
                invitedUser.invitationAccepted = user.invitationAccepted;
                return invitedUser;
            }
        }).filter(item => item);
        const areCheckboxActive = this.props.getSelectedInvitedUsers().length > 0 ? true : false;
        
        return (

            <div className="box-wrapper-main">
                
                <TButton onClick={() => { }} id='openModal-btn' dataTarget={`#sendMessageModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <ContactSendMessageModal 
                    allCheckbox= { [] }
                    allSelectMobile = { [] }
                    resetSMSEmailCard = { () => this.resetSMSEmailCard()}
                    webinarType="YES"
                    getCheckBoxAll = { this.props.getCheckBoxAll }
                    getRemoveCheckBox = { this.props.getRemoveCheckBox }
                    getSelectedCheckBox = { this.props.getSelectedCheckBox }
                    filterVal = { this.mapTags() }
                    followupOptionVal = { this.state.followupOptionVal }
                    userIndicatorVal = { this.state.userIndicatorVal }
                    pageLocation = "invited"
                    totalCount = { this.props.getSelectedInvitedUsers().length }
                />
                
                <FollowUpModal
                    onRef={(ref) => this.followUpChild = ref}
                    onFollowUpClick={(loading, user) => this.followUpHandler(loading, user)}
                    handleMultipleSendFollow={(message, subject) => this.handleMultipleSendFollow(message, subject) } 
                    //followUpMultipleInvitedUsers={(message) => this.props.followUpMultipleInvitedUsers(message)}
                />
                <div className='col-xs-12 col-sm-5 search-status allInvestmentDetail header-investment' style={{display: 'contents'}}>
                    <div className='search' style={{ width: '40%', padding:15, float:'left' }}>
                        <ReactTags
                            allowNew={true}
                            placeholder='Search'
                            suggestions={this.state.suggestions}
                            tags={this.state.searchInputTags}
                            handleDelete={this.handleSearchInputDelete.bind(this)}
                            handleAddition={this.handleSearchInputAddition.bind(this)} />
                    </div>
                    <div className='follow_up' style={{ width: '14%', display:'inline-block' }}>
                        <div className='follow_up' style={{ width: '100%', padding: '15px 5px' }}>
                            <Select
                                defaultValue={ this.state.followupOption[this.state.followupOptionVal ] }
                                id='followUpStatus'
                                options={this.state.followupOption}
                                value={ this.state.followupOption[this.state.followupOptionVal ] }
                                onChange={(event) => this.handleOnFollowupChanged(event)}
                                className="col-md-12 select-followup"
                            />
                        </div>    
                    </div>
                    <div className='follow_up' style={{ width: '15%', display:'inline-block' }}>
                        <div className='follow_up' style={{ width: '100%', padding: '15px 5px' }}>
                            <Select
                                defaultValue={ this.state.UserIndicatorOption[this.state.userIndicatorVal] }
                                id='userIndicator'
                                options={this.state.UserIndicatorOption}
                                value={this.state.UserIndicatorOption[this.state.userIndicatorVal] }
                                onChange={(event) => this.userIndicatorChanged(event)}
                                className="col-md-12 select-followup"
                            />
                        </div>    
                    </div>
                    <div className='follow_up' style={{ width: '8%', display:'inline-block' }}>
                        <div className='follow_up' style={{ width: '100%' }}>
                            <a onClick={() => this.invitedExportData()} className="tsg-btn invite export-btn" >
                                {
                                    this.state.exportLoading ? 
                                    <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </>
                                    :
                                    <><i className="fa fa-file-text ex-icon" aria-hidden="true" />EXPORT </>
                                }
                            </a>
                            <CSVLink 
                                data={ this.state.listOfUsers } 
                                filename={"Invited-Contacts.csv"}
                                className="hidden"
                                ref={this.csvLink}
                                target="_blank"
                            />
                        </div>    
                    </div>
                    <div style={{ width: '9%', display:'inline-block', marginBottom:'2px' }} >

                        <TButton
                            text='Follow Up Selected'
                            className='invite-btn'
                            onClick={() => {
                                this.setState(this.followUpChild.resetFollowUpData(), this.followUpChild.setData({
                                    startupName: this.props.investmentData.companyId.companyName,
                                    round: this.props.investmentData.round,
                                    investmentId: this.props.investmentData._id,
                                    isFromMultipleFollowUp: true,
                                }));
                            }}
                            dataTarget={`#followUpModal`}
                            dataToggle={`modal`}
                            disabled={!areCheckboxActive}
                            style={{ padding:'21px 15px', marginLeft:'15px' }}
                        />
                    </div>

                    <div style={{ width: '9%', display:'inline-block', marginBottom:'0px' }} >
                        <button type='button' href='javascript:void(0)' className="tsg-btn invite sendMessBtn" onClick={ (e) => this.sendAllMessage(e) } disabled={!areCheckboxActive} >
                            SEND MESSAGE
                        </button>
                    </div>

                </div>
                <div className="custom-table-view table-responsive" style={{ clear:'both' }} >
                    <table className="table table-custom">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(event) => this.props.onCheckedAllInvitedUser(event)}
                                        checked={this.props.isAllInvitedUserChecked}
                                    />
                                    <label>&nbsp; &nbsp;All</label>
                                </th>
                                <th></th>
                                <th>NAME <i className={`arrow ${this.state.arrowType['name']}`} onClick={() => this.toggleArrow('name')}></i></th>
                                <th>EMAIL <i className={`arrow ${this.state.arrowType['emailAddress']}`} onClick={() => this.toggleArrow('emailAddress')}></i></th>
                                <th>COMPANY <i className={`arrow ${this.state.arrowType['company']}`} onClick={() => this.toggleArrow('company')}></i></th>
                                <th>DATE <i className={`arrow ${this.state.arrowType['createdAt']}`} onClick={() => this.toggleArrow('createdAt')}></i></th>
                                <th>STAGE</th>
                                <th>RESEND</th>
                                <th>CANCEL</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invitedUsers.length > 0 &&
                                invitedUsers.map(invite => {
                                    //console.log(this.props.investmentData);
                                    return (
                                        <tr key={invite._id} >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={invite.isChecked}
                                                    onChange={(event) => this.props.onCheckedSingleInvitedUser(event, invite._id)}
                                                />
                                            </td>
                                            <td>
                                                <span className={invite.user.invitationAccepted === '1' ? 'status-indicator' : 'status-indicator-red'} ></span>
                                            </td>
                                            <td className="username-row" 
                                            // onClick={() => this.props.history.push(`/viewProfile?id=${invite.user._id}`)}
                                            onClick={() => { this.props.showDataInPopup(invite.user._id) }}>
                                                {`${invite.firstName} ${invite.lastName}`}
                                            </td>
                                            <td>
                                                {`${invite.user.emailAddress}`}
                                            </td>
                                            <td>
                                                { invite.investorCompanyName ? invite.investorCompanyName : invite.companyName ? invite.companyName : '' }
                                            </td>
                                            <td>
                                                {this.mapDateFormat(invite.createdAt)}
                                            </td>
                                            <td>
                                                {invite.emailReadStatus ? 'Read' : 'Sent'}
                                            </td>
                                            <td>
                                                <TButton text={this.state.followUp && this.state.followUp[invite.user._id] ? 'FOLLOWED UP' : invite.isFollowupSend ? 'FOLLOWED UP' : 'FOLLOW UP'} className={ 'invite-btn ' + (invite.isFollowupSend ? 'blueColorCode' : '') }
                                                    onClick={() => {
                                                        this.setState(this.followUpChild.resetFollowUpData(), this.followUpChild.setData({
                                                            user: invite.user,
                                                            companyName:  this.props.investmentData.name,//this.props.investmentData.companyId.companyName,
                                                            investmentId: this.props.investmentData._id,
                                                            isFromMultipleFollowUp: false,
                                                            investmentStatus: invite.status,
                                                            round: this.props.investmentData.round,
                                                        }));
                                                    }}
                                                    dataTarget={`#followUpModal`}
                                                    dataToggle={`modal`}
                                                />
                                            </td>
                                            <td>
                                                <TButton text='CANCEL' className='invite-btn' style={{ 'marginRight':'10px' }}
                                                    onClick={() => this.props.onCancelInvestment(invite._id, 'invited', (isSuccess) => {
                                                        if (isSuccess) {
                                                            this.setState({searchInputTags: []});
                                                        }
                                                    })}
                                                />
                                                <TButton text='Adjust Amount' className='invite-btn' style={{ 'textTransform':'uppercase' }}
                                                    onClick={() => this.props.adjustExceedAmount(invite)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                invitedUsers.length === 0 &&
                                <tr visible-xs>
                                    <td> No investors found for invite!</td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
                <TButton id='followUpBtn' dataTarget={`#followUpModal`} dataToggle={`modal`} style={{ display: 'none' }} />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSummaryInvestmentData: bindActionCreators(setSummaryInvestmentData, dispatch),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(InvitedInvestmentDetailUsers));