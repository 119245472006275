import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./viewMessageModel.scss";

class ViewInvetsmentMessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      smsMessage: "",
      emailMessage: "",
    };
  }

  componentDidMount() {
    console.log("sadasdasdsad", this.props);
  }

  componentWillMount() {
    this.props.onRef && this.props.onRef(this);
    console.log(this.props);
  }

  setData(user){
    this.setState({
      subject: user.subject,
      smsMessage: user.smsMessage,
      emailMessage: user.emailMessage,
    })
    console.log(user,"user");
  }

  render() {
    return (
      <div>
        <div id="ViewInvetsmentMessageModal" className="modal fade">
          <div className="modal-dialog ">
            <div className="modal-content invite-contact card-mess">
              <div className="modal-header">
                <h4 className="modal-title">View Message</h4>
              </div>
              <div className="modal-body">
                  {this.state.subject &&
                      <div className="form-group">
                        <label className="col-12 control-label">
                          Subject:
                        </label>
                        <div className="col-12">
                            {this.state.subject}    
                        </div>
                      </div>
                  }

                  {this.state.emailMessage &&
                      <div className="form-group">
                        <label className="col-12 control-label">
                          Email Message:
                        </label>
                        <div className="col-12">
                        <CKEditor
                            id="emailhtml"
                            editor={ClassicEditor}
                            data={this.state.emailMessage}
                            onReady={(editor) => {
                              console.log("Editor is ready to use!", editor);
                              editor.isReadOnly = true;
                            }}
                          />
                        </div>
                      </div>
                  }

                  {this.state.smsMessage &&
                      <div className="form-group">
                        <label className="col-12 control-label">
                          SMS Message:
                        </label>
                        <div className="col-12">
                            {this.state.smsMessage}    
                        </div>
                      </div>
                    }
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewInvetsmentMessageModal;

