import axios from 'axios';
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import LaddaButton, { EXPAND_RIGHT, S, XS } from 'react-ladda';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import common from '../../../common';
import BankAccountVerification from '../../../components/Header/bankAccountVerification';
import ReferAFriendModal from '../../../components/Header/referFriend';
import LoadingSpinner from '../../../components/Loader';
import RequiredSign from '../../../components/RequiredSign';
import TButton from '../../../components/TButton';
import { path } from "../../imagesPath";
import "./InvestmentProcess.scss";
import ReferColleagueModal from './ReferColleagueModal';
import WalletFundingConfirmationModel from './walletFundingConfirmationModel';

class Documents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedType: '',
      types: [{ value: 'entity', label: 'Entity' }, { value: 'individual', label: 'Individual' }],
      isEntity: true,
      amount: '',
      type: 'entity',
      entity: '',
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      enableCheckbox1: false,
      enableCheckbox2: false,
      wireDate: '',
      wireConfirmation: '',
      depositeDate: '',
      enableCard1: true,
      enableCard2: false,
      documents: null,
      isEditPayment: false,
      userProfile: [],
      finalEntity: '',
      finalSelectedType: '',
      fundingStatus: 'start',
      transactionType: 'wallet',
      walletBalanceData: {
        currentBalance: 0,
        pendingBalance: 0,
        usualBalance: 0,
      },
      wirePage: 'firstPage',
      isChecked: false,
      wireAccountNumber: "",
      wireAccountName: "",
      wireRoutingNumber: "",
      achNickname: "",
      achAccountNumber: "",
      achAccountName: "",
      achRoutingNumber: "",
      verivend: {},
      bankAccounts: [],
      bankAccountId: null,
      transaction: null,
      isBankChecked: "",
      bankAccountType: "bankAccount",
      legelName: props.userData.name,
      isLegalNameConfirmed: false,
    }
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    this.investmentId = url.searchParams.get('investmentId');
    this.status = url.searchParams.get('status');
    this.processId = url.searchParams.get('processId');
    this.envId = url.searchParams.get('env');
    this.type = url.searchParams.get('type');
    this.setUpdatedState();
    this.getFormData();
    this.setVerivendData();
  }

  getFormData = () => {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/getUserInvestmentProcessById/${this.props.userData._id}/${this.investmentId}`, {
      headers: {
        "Authorization": token
      }
    })
      .then((response) => {
        temp.setState({ loading: false });
        const investment = (response.data.data.invitedInvestments[0]) ? response.data.data.invitedInvestments[0] : null;
        const pps = (investment != null) ? investment.pricePerShare : null;
        this.setFinaldata(response.data.data.investmentprocesses[0], pps);
        const companyStage = response.data.data.investmentCompanies[0].stage;
        if (companyStage === common.companyInvestmentStatus.exitInProcess && investment.stage === common.companyInvestmentStatus.exitInProcess) {
          window.location.href = `/exit-investment-details/${this.investmentId}/${this.props.userData._id}`
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        }
        else {
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  setVerivendData = () => {
    const userData = this.props.userData;
    if (userData.verivendData && userData.verivendData._id) {
      this.getWalletBalance(userData.verivendData._id);
      this.getUserVerivendData(userData.verivendData._id);
      this.getTransactionDetails();
      this.handleFundingStatus("firstPage");
      this.handleTransactionType("wallet");
    }
  }

  viewDocumentRedirect(envId, type) {
    let queryString = `?envId=${envId}&type=${type}`;
    window.open(` ${common.path.doc_download_url}/downloadPDF${queryString}`, '_blank', '', 2)

  }
  setUpdatedState = () => {
    const investmentProcess = this.props.invitedInvestment.investmentprocesses;
    const pps = this.props.invitedInvestment.pricePerShare;
    if (investmentProcess && investmentProcess.length > 0 &&
      investmentProcess[0].status !== 'Invited'
      && investmentProcess[0].status !== 'Cancelled'
      && investmentProcess[0].status !== 'Not Interested'
      && investmentProcess[0].status !== 'Complete') {
      const investmentDetail = investmentProcess[0];
      let selectedType = { value: 'entity', label: 'Entity' };
      let isEntity = true;
      if (investmentDetail && investmentDetail.type === 'individual') {
        selectedType = { value: 'individual', label: 'Individual' };
        isEntity = false;
      } else if (investmentDetail && investmentDetail.type === 'ira') {
        selectedType = { value: 'ira', label: 'IRA' };
        isEntity = false;
      }
      if (investmentDetail != undefined && investmentDetail.taxIdNumber != undefined && investmentDetail.taxIdNumber.includes('-')) {
        investmentDetail.taxIdNumber = investmentDetail.taxIdNumber.replaceAll('-', '');
      }
      this.setState({ isEntity: isEntity });
      this.setState({ finalSelectedType: selectedType });
      // investmentDetail.isEntity = isEntity;
      // investmentDetail.selectedType = selectedType;
      this.setFinaldata(investmentDetail, pps);
    } else {
      // if(window.localStorage.getItem(roleCode) && window.localStorage.getItem(roleCode)=='001') {
      //     window.location.href = '/dashboard';
      // } else if (investmentProcess && investmentProcess.length > 0 &&
      //     investmentProcess[0].status !== 'Complete') {
      //         window.location.href = '/';
      // }   
    }

  }

  setFinaldata = (investmentDetail, defaultPPS) => {
    //console.log("govind,,,,",investmentDetail);
    this.setState({
      documents: {
        [investmentDetail._id]: {
          amount: investmentDetail.amount,
          formattedAmount: investmentDetail.formattedAmount,
          type: investmentDetail.type ? investmentDetail.type : '',
          firstName: investmentDetail.firstName ? investmentDetail.firstName : this.props.userData.firstName,
          middleName: investmentDetail.middleName ? investmentDetail.middleName : this.props.userData.middleName,
          lastName: investmentDetail.lastName ? investmentDetail.lastName : this.props.userData.lastName,
          entityName: investmentDetail.entityName ? investmentDetail.entityName : '',
          otherEntityType: investmentDetail.otherEntityType ? investmentDetail.otherEntityType : '',
          title: investmentDetail.title ? investmentDetail.title : '',
          address1: investmentDetail.address1 ? investmentDetail.address1 : this.props.userData.address,
          address2: investmentDetail.address2 ? investmentDetail.address2 : this.props.userData.address2,
          city: investmentDetail.city ? investmentDetail.city : this.props.userData.city,
          state: investmentDetail.state ? investmentDetail.state : this.props.userData.state,
          zipcode: investmentDetail.zipcode,
          memberType: investmentDetail.memberType,
          taxIdNumber: investmentDetail.taxIdNumber && investmentDetail.taxIdNumber.toString() !== 'false' ? investmentDetail.taxIdNumber : '',
          interestedDate: investmentDetail.interestedDate,
          executedDate: investmentDetail.executedDate,
          isDocSign: investmentDetail.isDocSign,
          isAdjustAmount: investmentDetail.isAdjustAmount,
          paymentDate: investmentDetail.paymentDate ? new Date(investmentDetail.paymentDate) : '',
          fundedDate: investmentDetail.fundedDate ? new Date(investmentDetail.fundedDate) : '',
          memoNo: investmentDetail.memoNo,
          isSubscribed: (investmentDetail.isSubscribed === true),
          paymentType: investmentDetail.paymentType && investmentDetail.paymentType === 'check' ? 'check' : 'wire',
          status: investmentDetail.status,
          selectedType: this.state.finalSelectedType,
          isEntity: (investmentDetail.type === 'entity') ? true : false, //this.state.finalEntity,
          isDocument: this.getTabStatus(investmentDetail, 'document'),
          isExecute: this.getTabStatus(investmentDetail, 'execute'),
          isFund: this.getTabStatus(investmentDetail, 'fund'),
          isManagement: this.getTabStatus(investmentDetail, 'management'),
          isSignSideletter: investmentDetail.isSignSideletter,
          sideletterEnvId: investmentDetail.sideletterEnvId,
          isSignSideLetterResend: investmentDetail.isSignSideLetterResend,
          isSignSubscriptionAggrement: investmentDetail.isSignSubscriptionAggrement ? true : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'subscription' ? true : false,
          isSubcriptionComplete: investmentDetail.isSubcriptionComplete ? true : false,
          isSignW9: investmentDetail.isSignW9 ? true : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'signw9' ? true : false,
          subscriptionEnvId: investmentDetail.subscriptionEnvId ? investmentDetail.subscriptionEnvId : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'subscription' ? this.envId : null,
          w9EnvId: investmentDetail.w9EnvId ? investmentDetail.w9EnvId : this.processId && this.processId.toString() === investmentDetail._id.toString() && this.status === 'completed' && this.type === 'signw9' ? this.envId : null,
          taxIdNumberType: investmentDetail.taxIdNumberType ? investmentDetail.taxIdNumberType : '',
          isRequestExceedAmount: investmentDetail.isRequestExceedAmount ? true : false,
          investorMaxAmount: investmentDetail.investorMaxAmount ? investmentDetail.investorMaxAmount : null,
          investorMinAmount: investmentDetail.investorMinAmount ? investmentDetail.investorMinAmount : null,
          isForeignCitizen: investmentDetail.isForeignCitizen === true,
          country: investmentDetail.country,
          foreignTaxId: investmentDetail.foreignTaxId,
          otherEntityType: investmentDetail.otherEntityType ? investmentDetail.otherEntityType : '',
          managementFee: (investmentDetail.managementFee >= 0) ? investmentDetail.managementFee : null,
          pricePerShare: (investmentDetail.pricePerShare > 0) ? investmentDetail.pricePerShare : null,
          defaultPPS: (defaultPPS > 0) ? defaultPPS : null,
          pricePerClass: ((investmentDetail.pricePerShare > 0) && (defaultPPS > 0)) ? Number(investmentDetail.pricePerShare / defaultPPS) : 1,
        }
      }
    });
    //console.log('govind',this.state.documents)
  }

  getTabStatus(investmentDetail, type) {
    let isActive = false;
    switch (type) {
      case 'document':
        if (investmentDetail && (investmentDetail.status === 'Invited' || investmentDetail.status === 'Participate')) {
          isActive = true;
        } else {
          isActive = false;
        }
        break;
      case 'execute':
        if (investmentDetail && investmentDetail.status === 'Interested' && (!investmentDetail.isDocSign || (!investmentDetail.isDocSign && (investmentDetail.isSignSideletter === null || !investmentDetail.isSignSideletter)))) {
          isActive = true;
        } else {
          if ((investmentDetail.status === 'Interested' || investmentDetail.status === 'Executed') && investmentDetail.managementFee >= 0 && investmentDetail.isFeeAndCarrySet && !investmentDetail.sideletterEnvId) {
            console.log("adsasdasd2", investmentDetail);
            isActive = true;
          } else {
            isActive = false;
          }
        }
        break;
      case 'fund':
        if (investmentDetail && (investmentDetail.isDocSign && (investmentDetail.isSignSideletter === null || !investmentDetail.isSignSideletter)) && (investmentDetail.status === 'Interested' || investmentDetail.status === 'Executed' || investmentDetail.status === 'Payment' || investmentDetail.status === 'Complete')) {
          isActive = true;
        } else {
          if ((investmentDetail.status === 'Payment' || investmentDetail.status === 'Complete') && investmentDetail.managementFee >= 0 && investmentDetail.isFeeAndCarrySet) {
            isActive = true;
          } else {
            isActive = false;
          }
        }
        break;
      case 'management':
        if (investmentDetail && investmentDetail.isFeeAndCarrySet && (investmentDetail.status === 'management')) {
          isActive = true;
        } else {
          isActive = false;
        }
        break;
      default:
        return false;
    }
    return isActive;

  }

  selectType(value, investmentDetailId) {
    //console.log(value);
    if (value !== "") {
      let isEntity = false;
      if (value === 'entity') {
        isEntity = true;
        this.setState({ isEntity: true })
      } else {
        this.setState({ isEntity: false });
      }
      const documents = { ...this.state.documents };
      documents[investmentDetailId].type = value;
      documents[investmentDetailId].isEntity = isEntity;
      documents[investmentDetailId].selectedType = value;
      if (value === 'individual' || value === 'ira') {
        documents[investmentDetailId].taxIdNumberType = '';
        documents[investmentDetailId].memberType = 'Individual';
      } else {
        documents[investmentDetailId].memberType = '';
      }
      this.setState({ documents })
    } else {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].type = '';
      documents[investmentDetailId].isEntity = false;
      this.setState({ documents });
    }
  }
  selectSSNorEIN(value, investmentDetailId) {
    //console.log("check", investmentDetailId, value);
    const documents = { ...this.state.documents };
    documents[investmentDetailId].taxIdNumberType = value;
    this.setState({ documents });
    //console.log(this.state.documents);
  }
  selectCitizenType(value, investmentDetailId) {
    const documents = { ...this.state.documents };
    if (value === 'US') {
      documents[investmentDetailId].isForeignCitizen = false;
    } else {
      documents[investmentDetailId].isForeignCitizen = true;
    }
    this.setState({ documents })
  }

  selectMemberType(value, investmentDetailId) {
    const documents = { ...this.state.documents };
    documents[investmentDetailId].memberType = value;
    if (value !== 'Revocable Trust') {
      documents[investmentDetailId].taxIdNumberType = '';
    }
    this.setState({ documents })
  }


  menuClick(selected, id) {
    if (selected === 'document') {
      const documents = { ...this.state.documents };
      documents[id].isDocument = true;
      documents[id].isExecute = false;
      documents[id].isFund = false;
      documents[id].isManagement = false;
      this.setState({ documents });

      if (documents[id].type != 'entity') {
        documents[id].isEntity = false;
      }

    } else if (selected === 'execute') {
      const documents = { ...this.state.documents };
      if (documents[id].isSubscribed) {
        documents[id].isDocument = false;
        documents[id].isExecute = true;
        documents[id].isFund = false;
        documents[id].isManagement = false;
        this.setState({ documents });
      }
    } else if (selected === 'fund') {
      const documents = { ...this.state.documents };
      if (documents[id].isSubscribed) {
        documents[id].isDocument = false;
        documents[id].isExecute = false;
        documents[id].isFund = true;
        documents[id].isManagement = false;
        this.setState({ documents });
      }
    } else if (selected === 'management') {
      const documents = { ...this.state.documents };
      if (documents[id].isSubscribed) {
        documents[id].isDocument = false;
        documents[id].isExecute = false;
        documents[id].isFund = false;
        documents[id].isManagement = true;
        this.setState({ documents });
      }
    } else {
      const documents = { ...this.state.documents };
      if (documents[id].isDocSign) {
        documents[id].isDocument = false;
        documents[id].isExecute = false;
        documents[id].isFund = false;
        documents[id].isManagement = false;
        this.setState({ documents });
      }
    }
  }

  handleChange(id, value, investmentDetailId, formattedAmount) {
    if (id === 'signSubscription') {
      this.setState({ enableCheckbox1: !this.state.enableCheckbox1 })
    } else if (id === 'signW-9') {
      this.setState({ enableCheckbox2: !this.state.enableCheckbox2 })
    } else if (id === 'amount') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].amount = value;
      documents[investmentDetailId].formattedAmount = formattedAmount.slice(1);
      this.setState({ documents })
    } else if (id === 'firstName') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].firstName = value;
      this.setState({ documents })
    } else if (id === 'middleName') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].middleName = value;
      this.setState({ documents })
    } else if (id === 'lastName') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].lastName = value;
      this.setState({ documents })
    } else if (id === 'entityName') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].entityName = value;
      this.setState({ documents })
    } else if (id === 'otherEntityType') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].otherEntityType = value;
      this.setState({ documents })
    } else if (id === 'title') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].title = value;
      this.setState({ documents })
    } else if (id === 'employerIdentification' && value.length < 10) {
      if (value.includes('-')) {
        value = value.replace('-', '');
      }
      const documents = { ...this.state.documents };
      documents[investmentDetailId].taxIdNumber = value;
      this.setState({ documents })
    } else if (id === 'SSN' && value.length < 10) {
      if (value.includes('-')) {
        value = value.replace('-', '');
      }
      const documents = { ...this.state.documents };
      documents[investmentDetailId].taxIdNumber = value;
      this.setState({ documents })
    } else if (id === 'address1') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].address1 = value;
      this.setState({ documents })
    } else if (id === 'address2') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].address2 = value;
      this.setState({ documents })
    } else if (id === 'city') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].city = value;
      this.setState({ documents })
    } else if (id === 'state') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].state = value;
      this.setState({ documents })
    } else if (id === 'country') {
      const documents = { ...this.state.documents };
      if (documents[investmentDetailId].isForeignCitizen) {
        documents[investmentDetailId].country = value;
      } else {
        documents[investmentDetailId].country = '';
      }
      this.setState({ documents })
    } else if (id === 'foreignTaxId') {
      const documents = { ...this.state.documents };
      if (documents[investmentDetailId].isForeignCitizen) {
        if (value.length < 16) {
          let re = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
          let isSplChar = re.test(value);
          if (isSplChar) {
            let no_spl_char = value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
            documents[investmentDetailId].foreignTaxId = no_spl_char;
          }
          else {
            documents[investmentDetailId].foreignTaxId = value;
          }
        }
      } else {
        documents[investmentDetailId].foreignTaxId = '';
      }
      this.setState({ documents })
    } else if (id === 'zipcode') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].zipcode = value;
      this.setState({ documents })
    } else if (id === 'type') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].type = value;
      this.setState({ documents })
    } else if (id === 'wireConfirmation') {
      const documents = { ...this.state.documents };
      documents[investmentDetailId].memoNo = value;
      this.setState({ documents })
    } else if (id === 'subscription') {
      const documents = { ...this.state.documents };
      if (documents[investmentDetailId].isSubscribed) {
        documents[investmentDetailId].isSubscribed = false;
      } else {
        documents[investmentDetailId].isSubscribed = true;
      }
      this.setState({ documents })
    } else {
      // set values
      this.setState({ [id]: value })
    }
  }
  addDocument(id) {
    const documents = { ...this.state.documents };
    const document = documents[id];
    console.log("addBtn", document);
    const minAmount = this.props.invitedInvestment.minAmount;
    let maxAmount = this.props.invitedInvestment.maxAmount;
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    let u_id = window.localStorage.getItem('id');
    const data = { ...this.state.documents[id], u_id }
    const investorMaxAmount = document.investorMaxAmount;
    if (investorMaxAmount) {
      maxAmount = investorMaxAmount;
    }
    if (minAmount && maxAmount && (document.amount > parseInt(maxAmount))) {
      swal({
        title: "Are you sure?",
        text: "Your investment amount is exceeding the maximum limit set by TSG for this investment. You can request TSG for special privilege to allow your investment. Do you want to request to TSG?",
        icon: "warning",
        buttons: ["Cancel", "Yes"],
        dangerMode: true,
      })
        .then((action) => {
          if (action) {
            axios.post(`${common.path.base_url}/requestExceedAmount/${id}`, data, {
              headers: {
                "Authorization": token
              }
            })
              .then((response) => {
                temp.setState({ loading: false });
                documents[id].isRequestExceedAmount = true;
                documents[id].isExecute = true;
                documents[id].isDocument = false;
                documents[id].isFund = false;
                documents[id].interestedDate = Date(Date.now());
                documents[id].isSubscribed = true;
                if (documents[id].isForeignCitizen) {
                  documents[id].taxIdNumber = '';
                } else {
                  documents[id].foreignTaxId = '';
                  data.country = '';
                }
                this.setState({ documents });
              })
              .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                  window.localStorage.clear();
                  window.location.href = '/';
                  return;
                }
                else if (error.response) {
                  swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                  });
                }
                else {
                  swal({
                    title: "Network Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    icon: "error",
                    button: "Ok!",
                  });
                }
              });

          }
        });

    } else {
      axios.post(`${common.path.base_url}/generateInvestmentDoc/${id}`, data, {
        headers: {
          "Authorization": token
        }
      })
        .then((response) => {
          temp.setState({ loading: false });
          documents[id].isRequestExceedAmount = false;
          documents[id].isExecute = true;
          documents[id].isDocument = false;
          documents[id].isFund = false;
          documents[id].interestedDate = Date(Date.now());
          documents[id].isSubscribed = true;
          // if(documents[id].type ==="entity") {
          //   documents[id].isEntity = true;
          //   documents[id].taxIdNumberType = 'EIN';
          // } else {
          //   documents[id].isEntity = false;
          //   documents[id].taxIdNumberType = 'SSN';
          // }
          //console.log(documents);
          this.setState({ documents });

        })
        .catch(function (error) {
          temp.setState({ loading: false });
          if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
            window.localStorage.clear();
            window.location.href = '/';
            return;
          }
          else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          }
          else {
            swal({
              title: "Network Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }

  }

  execute(id) {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.post(`${common.path.base_url}/signInvestmentDoc/${id}`, {}, {
      headers: {
        "Authorization": token
      }
    })
      .then((response) => {
        temp.setState({ loading: false });
        const documents = { ...this.state.documents };
        documents[id].isDocument = false;
        documents[id].isExecute = false;
        documents[id].isFund = true;
        documents[id].isDocSign = true;
        this.setState({ documents });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        }
        else {
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  getTransactionDetails() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/getTransactionDetails/${this.investmentId}/${this.props.userData._id}`, {
      headers: {
        "Authorization": token
      }
    })
      .then((response) => {
        temp.setState({ loading: false });
        if (response.data && response.data.data && response.data.data.id) {
          temp.setState({ transaction: response.data.data });
          temp.setState({ fundingStatus: "lastPage" });
        }
        if (response.data && response.data.data && response.data.data.wireInstructions) {
          temp.setState({ wirePage: "transaction", transactionType: "wire", fundingStatus: "firstPage" });
        }
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        }
        else {
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  makeTransaction(type, investmentProcess) {
    let temp = this;
    if (type === 'wallet' && this.state.walletBalanceData.currentBalance >= 0 && this.state.walletBalanceData.currentBalance < Number(investmentProcess.amount.replaceAll(","))) {
      swal({
        title: "Error!",
        text: "Insufficient funds in your wallet.",
        icon: "error",
        button: "Ok!",
      });
    } else {
      temp.setState({ loading: true });
      let token = window.localStorage.getItem('token');
      const data = {
        amount: Number(investmentProcess.amount.replaceAll(",")),
        orgId: this.props.userData.verivendData.orgId,
        investmentId: this.investmentId,
        userId: this.props.userData._id,
        transactionType: type === "wallet" ? 1 : type === "wire" ? 4 : 2,
        investmentName: this.props.invitedInvestment.name,
      };
      if (data.transactionType === 2) {
        const bankAccounts = this.props.userData.verivendData.bankAccounts;
        const bankAccount = bankAccounts.filter((bankAccount) => bankAccount.bankAccountId === this.state.isBankChecked);
        data.bankAccountId = bankAccount[0].bankAccountId;
      }
      axios.post(`${common.path.base_url}/investmentPayment`, data, {
        headers: {
          "Authorization": token
        }
      })
        .then((response) => {
          if (type === "ach" || type === "wallet") {
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: "Ok!",
            }).then((resp) => {
              temp.setState({ loading: false });
              this.fund(investmentProcess._id, this.state.documents[investmentProcess._id].status, type);
            });
          } else {
            this.getTransactionDetails();
          }
        })
        .catch(function (error) {
          temp.setState({ loading: false });
          if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
            window.localStorage.clear();
            window.location.href = '/';
            return;
          }
          else if (error.response) {
            swal({
              title: "Error!",
              text: error.response.data.message,
              icon: "error",
              button: "Ok!",
            });
          }
          else {
            swal({
              title: "Network Error!",
              text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
              icon: "error",
              button: "Ok!",
            });
          }
        });
    }
  }

  cancelTransaction() {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.delete(`${common.path.base_url}/cancelTransaction/${this.investmentId}/${this.props.userData._id}`, {
      headers: { "Authorization": token }
    })
      .then((response) => {
        temp.setState({ loading: false });
        temp.setState({ transaction: null, transactionType: "wire", wirePage: "firstPage", isChecked: false });
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        }
        else {
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  fund(id, currentPaymentStatus, type) {
    const documents = { ...this.state.documents };
    const data = {
      paymentDate: documents[id].paymentDate.toString(),
      memoNo: documents[id].memoNo,
      paymentType: documents[id].paymentType,
      status: 'Payment',
    };
    let paymentDate = new Date().toUTCString();
    if (type === "ach" || type === "wallet") {
      data.paymentDate = paymentDate;
      data.paymentType = type;
    }
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.post(`${common.path.base_url}/paymentInvestment/${id}`, data, {
      headers: {
        "Authorization": token
      }
    })
      .then((response) => {
        temp.setState({ loading: false });
        const documents = { ...this.state.documents };
        documents[id].isDocument = false;
        documents[id].isExecute = false;
        documents[id].isFund = true;
        documents[id].isDocSign = true;
        documents[id].isFunded = true;
        documents[id].isManagement = true;
        documents[id].status = 'Payment';
        this.setState({ documents });
        this.openReferColleaugeModal();
      })
      .catch(function (error) {
        temp.setState({ loading: false });
        if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
          window.localStorage.clear();
          window.location.href = '/';
          return;
        }
        else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        }
        else {
          swal({
            title: "Network Error!",
            text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }
  cardClicked(value, id) {
    const documents = { ...this.state.documents };
    if (value === 'wire') {
      documents[id].paymentType = 'wire';
    } else {
      documents[id].paymentType = 'check';
    }
    this.setState({ documents });
  }


  intiateMicroDeposit(bankAccountId) {
    let temp = this;
    temp.setState({ loading: true });
    let tokenpass = window.localStorage.getItem('token');
    const data = {
      bankAccountId,
      orgId: this.state.verivend.orgId,
    }
    axios.post(`${common.path.base_url}/intiateBankVerify`, data, {
      headers: {
        "Authorization": tokenpass,
      }
    }).then((response) => {
      temp.setState({ loading: false });
      swal({
        title: "Success",
        text: response.data.message,
        icon: "success",
        button: "Ok!",
      }).then(() => this.getUserVerivendData(this.state.verivend._id));
    }).catch(function (error) {
      temp.setState({ loading: false });
      if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
        window.localStorage.clear();
        window.location.href = '/';
        return;
      }
      else if (error.response) {
        swal({
          title: "Error!",
          text: error.response.data.message,
          icon: "error",
          button: "Ok!",
        });
      }
      else {
        swal({
          title: "Network Error!",
          text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
          icon: "error",
          button: "Ok!",
        });
      }
    });
  }

  addBankAccountHandler() {
    let temp = this;
    temp.setState({ loading: true });
    let tokenpass = window.localStorage.getItem('token');
    const data = {
      name: this.state.achNickname,
      accNumber: this.state.achAccountNumber,
      accRoutingNumber: this.state.achRoutingNumber,
      accOwnerName: this.state.achAccountName,
    }
    axios.post(`${common.path.base_url}/addBankAccount/${this.state.verivend._id}`, data, {
      headers: {
        "Authorization": tokenpass,
      }
    }).then((response) => {
      temp.setState({ loading: false });
      this.setState({
        achNickname: "",
        achAccountNumber: "",
        achAccountName: "",
        achRoutingNumber: "",
      });
      swal({
        title: "Success",
        text: response.data.message,
        icon: "success",
        button: "Ok!",
      }).then(() => window.location.reload());
    }).catch(function (error) {
      temp.setState({ loading: false });
      if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
        window.localStorage.clear();
        window.location.href = '/';
        return;
      }
      else if (error.response) {
        swal({
          title: "Error!",
          text: error.response.data.message,
          icon: "error",
          button: "Ok!",
        });
      }
      else {
        swal({
          title: "Network Error!",
          text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
          icon: "error",
          button: "Ok!",
        });
      }
    });
  }

  createWalletHandler() {
    let temp = this;
    temp.setState({ loading: true });
    let tokenpass = window.localStorage.getItem('token');
    const data = {
      orgName: this.state.legelName,
      emailAddress: `${this.props.userData.emailAddress}`,
      userId: this.props.userData._id,
    }
    axios.post(`${common.path.base_url}/createOrganization`, data, {
      headers: {
        "Authorization": tokenpass,
      }
    }).then((response) => {
      if (response.data.data) {
        temp.setState({ loading: false });
        this.setState({ verivend: { _id: response.data.data.verivendId } });
        this.handleFundingStatus("firstPage");
        this.handleFundingStatus('firstPage');
      }
    }).catch(function (error) {
      temp.setState({ loading: false });
      if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
        window.localStorage.clear();
        window.location.href = '/';
        return;
      }
      else if (error.response) {
        swal({
          title: "Error!",
          text: error.response.data.message,
          icon: "error",
          button: "Ok!",
        });
      }
      else {
        swal({
          title: "Network Error!",
          text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
          icon: "error",
          button: "Ok!",
        });
      }
    });
  }
  getUserVerivendData(verivendId) {
    let temp = this;
    temp.setState({ loading: true });
    let token = window.localStorage.getItem('token');
    axios.get(`${common.path.base_url}/userVerivendData/${verivendId}`, {
      headers: {
        "Authorization": token,
      }
    }).then(response => {
      if (response.data.data && response.data.data.verivendData) {
        temp.setState({ verivend: response.data.data.verivendData });
        if (response.data.data && response.data.data.bankAccounts) {
          temp.setState({ bankAccounts: response.data.data.bankAccounts || [] });
        }
      }
    }).catch(function (error) {
      temp.setState({ loading: false });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        common.AUTH_EXPIRED === error.response.data.message
      ) {
        window.localStorage.clear();
        window.location.href = "/";
        return;
      } else if (error.response) {
        swal({
          title: "Error! #451220",
          text:
            error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : "Something Went Wrong",
          icon: "error",
          button: "Ok!",
        });
      } else {
        swal({
          title: "Network Error!",
          text:
            error.response &&
              error.response.data &&
              error.response.data.message
              ? error.response.data.message
              : error.message,
          icon: "error",
          button: "Ok!",
        });
      }
    });
  }

  getWalletBalance(verivendId) {
    let temp = this;
    const token = window.localStorage.getItem("token");
    temp.setState({ loding: true });
    axios
      .get(`${common.path.base_url}/getWalletBalance/${verivendId}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        temp.setState({ loding: false });
        if (response.data && response.data.data) {
          temp.setState({ walletBalanceData: response.data.data });
        }
      })
      .catch((error) => {
        temp.setState({ loding: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else {
          swal({
            title: "Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : "Something Went Wrong",
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }

  achValidation() {
    if (this.state.bankAccounts.length > 0) {
      if (this.state.isBankChecked === "bankAccount") {
        return true;
      } else {
        return !this.state.isChecked;
      }
    } else {
      return this.state.achRoutingNumber === "" && this.state.achAccountName === "" && this.state.achAccountNumber === "";
    }
  }
  handleFundingStatus(status) {
    this.setState({ fundingStatus: status });
  }
  handleTransactionType(status) {
    this.setState({ transactionType: status });
    this.setState({ isChecked: this.state.isChecked ? false : this.state.isChecked });
  }
  handleBankAccountType(status) {
    this.setState({ bankAccountType: status });
  }
  handleCheckButton() {
    this.setState({ isChecked: !this.state.isChecked });
  }
  getActiveStatusClass(transactionType) {
    return this.state.transactionType === transactionType ? path.checkslide : path.uncheckslideBorder;
  }
  handleBankAccount(id) {
    this.setState({ isBankChecked: id });
  }
  getActiveBankClass(id) {
    return this.state.isBankChecked === id ? path.checkslide : path.uncheckslideBorder;
  }
  getActiveCheckedImage() {
    return this.state.isChecked ? path.checkslide : path.uncheckslideBorder;
  }
  onChangeInput(type, value) {
    if (type == common.FormType.wireAccountName) {
      this.setState({ wireAccountName: value });
    }
    if (type == common.FormType.wireAccountNumber) {
      this.setState({ wireAccountNumber: value });
    }
    if (type == common.FormType.wireRoutingNumber) {
      this.setState({ wireRoutingNumber: value });
    }
    if (type == common.FormType.achNickname) {
      this.setState({ achNickname: value });
    }
    if (type == common.FormType.achAccountName) {
      this.setState({ achAccountName: value });
    }
    if (type == common.FormType.achAccountNumber) {
      this.setState({ achAccountNumber: value });
    }
    if (type == common.FormType.achRoutingNumber) {
      this.setState({ achRoutingNumber: value });
    }
  }
  handleDateChange(date, id) {
    const documents = { ...this.state.documents };
    documents[id].paymentDate = date;
    this.setState({ documents });
  }
  validInvitaionForm(id) {
    const documents = { ...this.state.documents };
    const document = documents[id];
    let minAmount = this.props.invitedInvestment.minAmount;
    let maxAmount = this.props.invitedInvestment.maxAmount;

    if (documents && documents[id] && documents[id].investorMaxAmount) {
      maxAmount = documents[id].investorMaxAmount;
    }
    if (documents && documents[id] && documents[id].investorMinAmount) {
      minAmount = documents[id].investorMinAmount;
    }

    if (!document.amount || !document.type || !document.address1 || !document.city || (document.isForeignCitizen && !document.country) || (document.isForeignCitizen && !document.foreignTaxId) || !document.state || !document.zipcode) {
      return false;
    }
    if (minAmount && !maxAmount && (document.amount < parseInt(minAmount))) {
      return false;
    }
    else if (minAmount && maxAmount && (document.amount < parseInt(minAmount))) {
      return false;
    }
    if (document.type === 'individual' && (!document.firstName || !document.lastName || (!document.taxIdNumber && !document.isForeignCitizen))) {
      return false;
    }
    else if (document.type === 'entity' && (!document.entityName || !document.memberType)) {
      return false;
    }
    else if (document.memberType === 'Other' && (!document.otherEntityType)) {
      return false;
    }
    if (document.type === 'entity' && document.memberType.includes('Trust')) {

      if (document.isForeignCitizen) {
        if (!document.foreignTaxId || !document.country) {
          return false;
        }
      } else {

        if (!document.taxIdNumber) {

          return false;
        }
      }
    } else if (document.type === 'entity' && !document.memberType.includes('Trust') && (!document.taxIdNumber && !document.foreignTaxId)) {

      return false;
    }
    if (document.memberType === 'Revocable Trust' && (!document.isForeignCitizen && !document.taxIdNumberType)) {

      return false;
    }
    if (document.isForeignCitizen === false && document.taxIdNumber.length != 9) {
      return false;
    }
    if (document.isForeignCitizen === true && !(document.foreignTaxId.length > 8)) {
      return false;
    }

    return true;
  }
  formatSSN(number) {
    if (number) {
      let array = number.split('');
      array.splice(3, 0, '-');
      array.splice(6, 0, '-');
      return array.join('');
    }
    return number;

  }
  formatTaxIdNumber(number) {
    if (number) {
      let array = number.split('');
      array.splice(2, 0, '-');
      return array.join('');
    }
    return number;

  }
  addressFormat(id) {
    let fullAddress = ''
    const documents = { ...this.state.documents };
    const document = documents[id];
    fullAddress = document.address1;
    if (document.address2) {
      fullAddress += ', ' + document.address2;
    }
    fullAddress += ', ' + document.city;
    fullAddress += ', ' + document.state;
    fullAddress += ', ' + document.zipcode;
    fullAddress += ', ' + document.country;

    return fullAddress;
  }

  w9FullAddress(id) {
    let fullAddress = ''
    const documents = { ...this.state.documents };
    const document = documents[id];
    fullAddress = document.address1;
    if (document.address2) {
      fullAddress += ', ' + document.address2;
    }
    return fullAddress;
  }

  cancelInvestment(id) {
    if (id) {
      swal({
        title: "Are you sure?",
        text: "Do you want to cancel participation from this investment?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((action) => {
          if (action) {
            let temp = this;
            temp.setState({ loading: true });
            let token = window.localStorage.getItem('token');
            axios.post(`${common.path.base_url}/cancelInvestment/${id}`, {}, {
              headers: {
                "Authorization": token
              }
            })
              .then((response) => {
                window.location.href = '/investor-dashboard';
              })
              .catch(function (error) {
                temp.setState({ loading: false });
                if (error.response && error.response.data && error.response.data.message && common.AUTH_EXPIRED === error.response.data.message) {
                  window.localStorage.clear();
                  window.location.href = '/';
                  return;
                }
                else if (error.response) {
                  swal({
                    title: "Error!",
                    text: error.response.data.message,
                    icon: "error",
                    button: "Ok!",
                  });
                }
                else {
                  swal({
                    title: "Network Error!",
                    text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    icon: "error",
                    button: "Ok!",
                  });
                }
              });
          }
        })
        .catch(error => {
        })

    }

  }

  getUserName(id) {
    let name = ''
    const documents = { ...this.state.documents };
    const document = documents[id];
    name = document.firstName;
    if (document.middleName) {
      name += ' ' + document.middleName;
    }
    if (document.lastName) {
      name += ' ' + document.lastName;
    }
    return name;
  }

  getUserCarry(carry, type) {
    let carryVal = carry;
    if (type == 2) {
      carryVal = 100 - carry;
    }
    carryVal = carryVal;
    return carryVal;
  }

  getQSAPowerFormId(type, shareType, entityType) {
    let powerFormID = null;
    if (shareType === 'Common') {
      shareType = 'COMMON';
    } else {
      shareType = 'PREFEERED';
    }
    //console.log(type);
    if (type === 'ira') {
      //console.log(process.env[`REACT_APP_${shareType}_IRA_ID`]);
      powerFormID = process.env[`REACT_APP_${shareType}_IRA_ID`];
    } else if (type === 'individual') {
      powerFormID = process.env[`REACT_APP_${shareType}_INDIVIDUAL_ID`];
    } else if (type === 'entity' && entityType && entityType.includes('Trust')) {
      powerFormID = process.env[`REACT_APP_${shareType}_ENTITY_TRUST_ID`];
    } else {
      powerFormID = process.env[`REACT_APP_${shareType}_ENTITY_ID`];
    }

    return powerFormID;
  }

  getCitizenPowerFormId(isForeignCitizen, type) {
    let powerFormID = null;
    if (isForeignCitizen) {
      if (type === 'individual') {
        powerFormID = process.env[`REACT_APP_W8_ID`];
      } else {
        powerFormID = process.env[`REACT_APP_W8_ENTITY_ID`];
      }
    } else {
      if (type === 'individual') {
        powerFormID = process.env[`REACT_APP_W9_INDIVIDUAL_ID`];
      } else {
        powerFormID = process.env[`REACT_APP_W9_ID`];
      }
    }

    return powerFormID;
  }

  getPowerFormId(type, shareType, entityType) {
    let powerFormID = null;
    if (shareType === 'Common') {
      if (entityType && entityType.includes('Trust')) {
        if (type === 'W8Entity') {
          powerFormID = process.env.REACT_APP_COMMON_TRUST_W8_ID;
        } else {
          powerFormID = process.env.REACT_APP_COMMON_TRUST_ID;
        }
      } else {
        switch (type) {
          case 'W8Individual':
            powerFormID = process.env.REACT_APP_COMMON_INDIVIDUAL_W8_ID;
            break;
          case 'W9Individual':
            powerFormID = process.env.REACT_APP_COMMON_INDIVIDUAL_ID
            break;
          case 'W9Entity':
            powerFormID = process.env.REACT_APP_COMMON_ENTITY_ID;
            break;
          case 'W8Entity':
            powerFormID = process.env.REACT_APP_COMMON_ENTITY_ID;
            break;
          default:
            break;
        }
      }

    } else {
      if (entityType && entityType.includes('Trust')) {
        if (type === 'W8Entity') {
          powerFormID = process.env.REACT_APP_PREFEERED_TRUST_W8_ID;
        } else {
          powerFormID = process.env.REACT_APP_PREFEERED_TRUST_ID;
        }

      } else {
        switch (type) {
          case 'W8Individual':
            powerFormID = process.env.REACT_APP_PREFEERED_INDIVIDUAL_W8_ID;
            break;
          case 'W9Individual':
            powerFormID = process.env.REACT_APP_PREFEERED_INDIVIDUAL_ID;
            break;
          case 'W9Entity':
            powerFormID = process.env.REACT_APP_PREFEERED_ENTITY_ID;
            break;
          case 'W8Entity':
            powerFormID = process.env.REACT_APP_PREFEERED_ENTITY_ID;
            break;
          default:
            break;

        }
      }

    }
    return powerFormID;
  }

  showLastFourChars(str) {
    if (str.length <= 4) {
      return str;
    }
    const lastFourChars = str.slice(-4);
    const remainingLength = str.length - 4;
    const replacement = "*".repeat(remainingLength);
    return replacement + lastFourChars;
  }

  getSignSideLettterPowerFormId() {
    let powerFormID = process.env.REACT_APP_SIDE_LETTER_ID;
    return powerFormID;
  }

  accountName(name) {
    if (name.includes('ThoughtSpot')) {
      return `TSG Ventures XI, LLC`
    }
    return `Syndicate Venture Partners X, LLC`;
  }
  accountNumber(name) {
    if (name.includes('ThoughtSpot')) {
      return `3676459906`
    }
    return `3583487016`;
  }
  routingNumber(name) {
    return `121000248`;
  }
  getWForm(id, isForeignCitizen) {
    let type = 'W9';
    if (isForeignCitizen) {
      type = 'W8';
    }
    return (
      <>
        <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(id, type)}>View {type} Signed Doc</a><br /><br />
      </>
    );
  }

  getQSA(id) {
    return (
      <>
        <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(id, 'Subscription', 1)}>View QSA Signed Doc</a><br /><br />
      </>
    );
  }
  getSignedSignLetter(id) {
    return (
      <>
        <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(id, 'sideletter', 1)}>View Side Letter</a><br /><br />
      </>
    );
  }
  openReferFriendModal() {
    document.getElementById('referFriend-btn').click();
    this.setState({ loading: false });
  }
  openReferColleaugeModal() {
    document.getElementById('referColleague-btn').click();
    this.setState({ loading: false });
  }

  openWalletFundingConfirmationModel() {
    document.getElementById('walletFundingConfirmation-btn').click();
    this.setState({ loading: false });
  }

  openBankAccountVerificationModal() {
    document.getElementById('bankAccountVerification-btn').click();
    this.setState({ loading: false });
  }

  onVerifyBankAccountSuccess() {
    this.getUserVerivendData(this.state.verivend._id);
  }

  render() {
    this.memberTypes = ['Corporation', 'Limited Liability Company', 'Limited Partnership', 'General Partnership', 'Revocable Trust', 'Irrevocable Trust', 'Other'];
    const investment = this.props.invitedInvestment;
    const bankDetail = this.props.bankDetail;
    const investmentBankDetail = investment.bankDetail;
    const transactionDetail = this.state.transaction;
    const investmentName = investment.name;
    const investmentProcess = investment.investmentprocesses && investment.investmentprocesses[0] && investment.investmentprocesses[0];
    const processId = investmentProcess && investmentProcess._id;
    const llcName = investment.llcName;
    let minAmount = investment.minAmount;
    const shareType = investment.shareType;
    let maxAmount = investment.maxAmount;
    if (this.state.documents && this.state.documents[processId] && this.state.documents[processId].investorMaxAmount) {
      maxAmount = this.state.documents[processId].investorMaxAmount;
    }
    if (this.state.documents && this.state.documents[processId] && this.state.documents[processId].investorMinAmount) {
      minAmount = this.state.documents[processId].investorMinAmount;
    }

    return (
      <>
        <div className="invitations_sec new_investment">
          <ReferAFriendModal
            onRef={(ref) => ref}
          />
          <WalletFundingConfirmationModel
            onRef={(ref) => ref}
            {...this.props}
            walletBalanceData={this.state.walletBalanceData}
            makeTransaction={(type, process) => this.makeTransaction(type, process)}
          />
          <TButton onClick={() => { }} id='walletFundingConfirmation-btn' dataTarget={`#walletFundingConfirmationModel`} dataToggle={`modal`} style={{ display: 'none' }} />
          <BankAccountVerification
            onRef={(ref) => ref}
            verivendData={this.state.verivend}
            id={this.state.bankAccountId}
            onVerifyBankAccountSuccess={() => this.onVerifyBankAccountSuccess()}
          />
          <TButton onClick={() => { }} id='referFriend-btn' dataTarget={`#referFriendModal`} dataToggle={`modal`} style={{ display: 'none' }} />
          <TButton onClick={() => { }} id='confirmPayment-btn' dataTarget={`#confirmPaymentModal`} dataToggle={`modal`} style={{ display: 'none' }} />
          <ReferColleagueModal
            onRef={(ref) => ref}
          />
          <TButton onClick={() => { }} id='referColleague-btn' dataTarget={`#referColleagueModal`} dataToggle={`modal`} style={{ display: 'none' }} />
          <div className="container-fluid">
            {this.state.loading ? <LoadingSpinner /> : ""}
            <div className="row">
              <div className="col-lg-2 col-md-3 col-sm-3">
                <div className="invitations_left_content">

                  {
                    (this.state.documents &&
                      this.state.documents[processId] &&
                      this.state.documents[processId].isDocSign &&
                      (this.state.documents[processId].managementFee >= 0 && this.state.documents[processId].isSignSideletter)) ?
                      <ul className='direct-fund'>
                        <li style={{ cursor: 'pointer' }}
                          className={`active`}
                          onClick={() => this.menuClick('document', processId)}>
                          {
                            this.state.documents && this.state.documents[processId] && this.state.documents[processId].isSubscribed ?
                              <span><i className="fa fa-check" aria-hidden="true"></i></span> :
                              <span>1</span>
                          }
                          <p>Investor Details</p>
                        </li>
                        <li style={{ cursor: 'pointer' }}
                          className={this.state.documents && this.state.documents[processId] && this.state.documents[processId].isDocSign ? 'active' : ''}
                          onClick={this.state.documents && this.state.documents[processId] && this.state.documents[processId].status === 'Executed' ? () => this.menuClick('fund', processId) : () => { }}
                        >
                          {
                            this.state.documents && this.state.documents[processId] && this.state.documents[processId].status === 'Payment' ?
                              <span><i className="fa fa-check" aria-hidden="true"></i></span> :
                              <span>2</span>
                          }
                          <p>Fund</p>
                        </li>
                        {
                          // investmentProcess && investmentProcess.isFeeAndCarrySet &&
                          // <li style={{ cursor: 'pointer' }}
                          //   className={this.state.documents && this.state.documents[processId] && this.state.documents[processId].isDocSign ? 'active' : ''}
                          //   onClick={() => this.menuClick('management', processId)}
                          // >
                          //   {
                          //     this.state.documents && this.state.documents[processId] && this.state.documents[processId].isSignSideletter ?
                          //       <span><i className="fa fa-check" aria-hidden="true"></i></span> :
                          //       <span>3</span>
                          //   }
                          //   <p>Sign Side Letter</p>
                          // </li>
                        }
                      </ul>
                      :
                      <ul>
                        <li style={{ cursor: 'pointer' }}
                          className={`active`}
                          onClick={() => this.menuClick('document', processId)}>
                          {
                            this.state.documents && this.state.documents[processId] && this.state.documents[processId].isSubscribed ?
                              <span><i className="fa fa-check" aria-hidden="true"></i></span> :
                              <span>1</span>
                          }

                          <p>Investor Details</p>
                        </li>
                        <li style={{ cursor: 'pointer' }}
                          className={this.state.documents && this.state.documents[processId] && this.state.documents[processId].isSubscribed ? 'active' : ''}
                          onClick={() => this.menuClick('execute', processId)}>
                          {
                            this.state.documents && this.state.documents[processId] && this.state.documents[processId].isDocSign && (this.state.documents[processId].managementFee === null || this.state.documents[processId].sideletterEnvId) ?
                              <>
                                <span><i className="fa fa-check" aria-hidden="true"></i></span>
                                <p>Executed Documents</p>
                              </> 
                            :
                              <>
                                <span>2</span>
                                <p>Execute</p>
                              </>
                          }
                          
                        </li>
                        <li style={{ cursor: 'pointer' }}
                          className={this.state.documents && this.state.documents[processId] && (this.state.documents[processId].isDocSign && (this.state.documents[processId].managementFee === null || this.state.documents[processId].sideletterEnvId)) ? 'active' : ''}
                          onClick={this.state.documents && this.state.documents[processId] && this.state.documents[processId].status === 'Executed' ? () => this.menuClick('fund', processId) : () => { }}
                        >
                          {
                            this.state.documents && this.state.documents[processId] && this.state.documents[processId].status === 'Payment' ?
                              <span><i className="fa fa-check" aria-hidden="true"></i></span> :
                              <span>3</span>
                          }
                          <p>Fund</p>
                        </li>
                        {
                          // investmentProcess && investmentProcess.isFeeAndCarrySet &&
                          // <li style={{ cursor: 'pointer' }}
                          //   className={this.state.documents && this.state.documents[processId] && this.state.documents[processId].isManagement ? 'active' : ''}
                          //   onClick={() => this.menuClick('management', processId)}
                          // >
                          //   {
                          //     this.state.documents && this.state.documents[processId] && this.state.documents[processId].status === 'management' ?
                          //       <span><i className="fa fa-check" aria-hidden="true"></i></span> :
                          //       <span>4</span>
                          //   }
                          //   <p>Sign Side Letter</p>
                          // </li>
                        }
                      </ul>

                  }
                </div>
              </div>
              {
                this.state.documents && this.state.documents[processId] && this.state.documents[processId].isDocument &&
                <div className="col-lg-10 col-md-9 col-sm-9">
                  <div className="card">
                    <h3>Investment Profile</h3>
                    {
                      !this.state.documents[processId].isDocSign &&
                      <a className="back-dashboard" style={{ float: 'right', position: 'relative', top: '-75px', right: '0px', color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.cancelInvestment(processId)} >Cancel Investment</a>
                    }
                    {
                      !this.state.documents[processId].isDocSign ? (
                        <div className="invitations_right">
                          <div className="row">
                            <div className="col-lg-2 col-md-3 field-label-responsive">
                              <label htmlFor="name">Amount<sup>*</sup> :</label>
                            </div>
                            <div className="col-lg-6 col-md-9" style={{ fontSize: '12px' }}>
                              {
                                minAmount && <>
                                  Min: <NumberFormat
                                    value={minAmount}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    fixedDecimalScale={true}
                                    // decimalScale={2}
                                    displayType={'text'}
                                  />
                                </>
                              }
                              {
                                maxAmount && <>
                                  {' '}/ Max: <NumberFormat
                                    value={maxAmount}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    fixedDecimalScale={true}
                                    // decimalScale={2}
                                    displayType={'text'}
                                  />
                                </>
                              }
                              {' '}(Variance Requests: <a href="mailto:platform@syndicategroup.com"><u>platform@syndicategroup.com</u></a>)
                            </div>

                            <div className="col-lg-6 col-md-9">
                              <div className="form-group amount-investment">
                                {
                                  this.state.documents[processId].isAdjustAmount ?
                                    <NumberFormat
                                      value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].amount}
                                      thousandSeparator={true}
                                      prefix={'$'}
                                      fixedDecimalScale={true}
                                      decimalScale={2}
                                      displayType={'text'}
                                    /> :
                                    <NumberFormat
                                      value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].amount}
                                      thousandSeparator={true}
                                      prefix={'$'}
                                      fixedDecimalScale={true}
                                      decimalScale={2}
                                      onValueChange={(values) => {
                                        this.handleChange('amount', values.value, processId, values.formattedValue)
                                      }}
                                    />
                                }

                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 field-label-responsive">
                              <label htmlFor="email">Type<sup>*</sup> :</label>
                            </div>
                            <div className="col-lg-6 col-md-9 col-sm-9">
                              <div className="form-group">
                                <select className="form-control form-control-lg" name="category" id="validationCustom03" onChange={(event) => this.selectType(event.target.value, processId)}>
                                  <option value=''>Select type</option>
                                  <option value='entity' selected={this.state.documents && this.state.documents[processId] && (this.state.documents[processId].type == 'entity')} >Entity</option>
                                  <option value='individual' selected={this.state.documents && this.state.documents[processId] && (this.state.documents[processId].type == 'individual')}>Individual</option>
                                  <option value='ira' selected={this.state.documents && this.state.documents[processId] && (this.state.documents[processId].type == 'ira')}>IRA</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {
                            this.state.documents && this.state.documents[processId] && this.state.documents[processId].isEntity ? (<>
                              <div className="row">
                                <div className="col-lg-2 col-md-3 field-label-responsive">
                                  <label htmlFor="email">Entity Type<sup>*</sup> :</label>
                                </div>
                                <div className="col-lg-6 col-md-9 col-sm-9">
                                  <div className="form-group">
                                    <select className="form-control form-control-lg" name="memberType" onChange={(event) => this.selectMemberType(event.target.value, processId)}>
                                      <option value=''>Select Entity Type</option>
                                      {
                                        this.memberTypes.length > 0 && this.memberTypes.map((type) => {
                                          return <option value={type} selected={this.state.documents && this.state.documents[processId] && this.state.documents[processId].memberType === type} >{type}</option>
                                        })
                                      }
                                    </select>
                                  </div>
                                </div>
                              </div>
                              {
                                this.state.documents && this.state.documents[processId] && this.state.documents[processId].memberType === 'Other' &&
                                <>
                                  <div className="row">
                                    <div className="col-lg-2 col-md-3 field-label-responsive">
                                      <label htmlFor="name">Other Entity Type<sup>*</sup> :</label>
                                    </div>
                                    <div className="col-lg-6 col-md-9">
                                      <div className="form-group">
                                        <input className="form-control" id="otherEntityType" type="text"
                                          onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                          placeholder='Enter Other Entity Type'
                                          value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].otherEntityType} />
                                        <p>{this.state.entityValidationMsg}</p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }
                              <div className="row">
                                <div className="col-lg-2 col-md-3 field-label-responsive">
                                  <label htmlFor="name">Entity Name<sup>*</sup> :</label>
                                </div>
                                <div className="col-lg-6 col-md-9">
                                  <div className="form-group">
                                    <input className="form-control" id="entityName" type="text"
                                      onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                      placeholder='Enter Entity Name'
                                      value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].entityName} />
                                    <p>{this.state.entityValidationMsg}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="row" style={{ display: 'none' }}>
                                <div className="col-lg-2 col-md-3 field-label-responsive">
                                  <label htmlFor="name">Title<sup>*</sup> :</label>
                                </div>
                                <div className="col-lg-6 col-md-9">
                                  <div className="form-group">
                                    <input className="form-control" id="title" type="text"
                                      onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                      placeholder='Enter Title'
                                      value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].title} />
                                    <p>{this.state.titleValidationMsg}</p>
                                  </div>
                                </div>
                              </div>
                            </>) :
                              (
                                <>
                                  <div className="row">
                                    <div className="col-lg-2 col-md-3 field-label-responsive">
                                      <label htmlFor="name">First Name<sup>*</sup> :</label>
                                    </div>
                                    <div className="col-lg-6 col-md-9">
                                      <div className="form-group">
                                        <input className="form-control" id="firstName" type="text"
                                          onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                          placeholder='Enter First Name'
                                          value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].firstName} />
                                        <p>{this.state.firstNameValidationMsg}</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-2 col-md-3 field-label-responsive">
                                      <label htmlFor="name">Middle Name :</label>
                                    </div>

                                    <div className="col-lg-6 col-md-9">
                                      <div className="form-group">
                                        <input className="form-control" id="middleName" type="text"
                                          onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                          placeholder='Enter Middle Name'
                                          value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].middleName} />
                                        <p>{this.state.middleNameValidationMsg}</p>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="row">
                                    <div className="col-lg-2 col-md-3 field-label-responsive">
                                      <label htmlFor="name">Last Name<sup>*</sup> :</label>
                                    </div>
                                    <div className="col-lg-6 col-md-9">
                                      <div className="form-group">
                                        <input className="form-control" id="lastName" type="text"
                                          // onKeyUp={(event) => this.formValidaton(event.target.id, event.target.value)}
                                          onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                          placeholder='Enter Last Name'
                                          value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].lastName} />
                                        <p>{this.state.lastNameValidationMsg}</p>
                                      </div>
                                    </div>
                                  </div>

                                </>
                              )
                          }
                          <div className="row">
                            <div className="col-lg-2 col-md-3 field-label-responsive">
                              <label htmlFor="email">Citizen Type<sup>*</sup> :</label>
                            </div>
                            <div className="col-lg-6 col-md-9 col-sm-9">
                              <div className="form-group">
                                <select className="form-control form-control-lg" name="category" id="validationCustom03" onChange={(event) => this.selectCitizenType(event.target.value, processId)}>
                                  <option value='US' selected={this.state.documents && this.state.documents[processId] && this.state.documents[processId].isForeignCitizen === false}>US Citizen</option>
                                  <option value='Foreign' selected={this.state.documents && this.state.documents[processId] && this.state.documents[processId].isForeignCitizen === true}>Foreign Citizen</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 field-label-responsive">
                              <label htmlFor="name">Address 1<sup>*</sup> :</label>
                            </div>
                            <div className="col-lg-6 col-md-9">
                              <div className="form-group">
                                <input className="form-control" id="address1" type="text"
                                  onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                  placeholder='Enter Address 1'
                                  value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].address1} />
                                <p>{this.state.address1ValidationMsg}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 field-label-responsive">
                              <label htmlFor="name">Address 2 :</label>
                            </div>
                            <div className="col-lg-6 col-md-9">
                              <div className="form-group">
                                <input className="form-control" id="address2" type="text"
                                  onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                  placeholder='Enter Address 2'
                                  value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].address2} />
                                <p>{this.state.address2ValidationMsg}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 field-label-responsive">
                              <label htmlFor="name">City<sup>*</sup> :</label>
                            </div>
                            <div className="col-lg-6 col-md-9">
                              <div className="form-group">
                                <input className="form-control" id="city" type="text"
                                  onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                  placeholder='Enter City'
                                  value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].city} />
                                <p>{this.state.cityValidationMsg}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 field-label-responsive">
                              {/* <label htmlFor="name">State :</label> */}
                              <label htmlFor="name">State <sup>*</sup> :</label>
                            </div>
                            <div className="col-lg-6 col-md-9">
                              <div className="form-group">
                                <input className="form-control" id="state" type="text"
                                  onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                  placeholder='Enter State'
                                  value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].state} />
                                <p>{this.state.stateValidationMsg}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-2 col-md-3 field-label-responsive">
                              {/* <label htmlFor="name">Zipcode<sup>*</sup> :</label> */}
                              {this.state.documents[processId].isForeignCitizen ? <label htmlFor="name">Postal code<sup>*</sup> :</label> : <label htmlFor="name">Zip code<sup>*</sup> :</label>}
                              {/* <label htmlFor="name">Postal code :</label> */}
                            </div>
                            <div className="col-lg-6 col-md-9">
                              <div className="form-group amount-investment">
                                <input className="form-control" id="state" type="text"
                                  onChange={(event) => this.handleChange('zipcode', event.target.value, processId)}
                                  placeholder={this.state.documents[processId].isForeignCitizen ? 'Enter Postal Code' : 'Enter Zip Code'}
                                  value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].zipcode} />
                                <p>{this.state.zipcodeValidationMsg}</p>
                              </div>
                            </div>
                          </div>
                          {
                            this.state.documents && this.state.documents[processId] && this.state.documents[processId].isForeignCitizen &&
                            <div className="row">
                              <div className="col-lg-2 col-md-3 field-label-responsive">
                                <label htmlFor="name">Country<sup>*</sup> :</label>
                              </div>
                              <div className="col-lg-6 col-md-9">
                                <div className="form-group">
                                  <input className="form-control" id="country" type="text"
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                    placeholder='Enter Country'
                                    value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].country} />
                                </div>
                              </div>
                            </div>
                          }
                          {
                            this.state.documents && this.state.documents[processId] &&
                              this.state.documents[processId].isEntity &&
                              !this.state.documents[processId].isForeignCitizen ? (<>
                                <div className="row">
                                  <div className="col-lg-2 col-md-3 field-label-responsive">
                                    <label htmlFor="name">Tax I.D. Number <sup>*</sup> :</label>
                                  </div>
                                  <div className="col-lg-6 col-md-9">
                                    <div className="form-group amount-investment">
                                      <NumberFormat
                                        value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].taxIdNumber}
                                        // decimalScale={2}
                                        allowLeadingZeros={true}
                                        onValueChange={(values) => {
                                          this.handleChange('employerIdentification', values.value, processId)
                                        }} />
                                    </div>
                                  </div>
                                </div>
                                {
                                  !this.state.documents[processId].isForeignCitizen && this.state.documents[processId].memberType === 'Revocable Trust' &&
                                  <>
                                    <div className="row">
                                      <div className="col-lg-2 col-md-3 field-label-responsive">
                                        <label htmlFor="email">SSN or EIN<sup>*</sup> :</label>
                                      </div>
                                      <div className="col-lg-6 col-md-9 col-sm-9">
                                        <div className="form-group">
                                          <select className="form-control form-control-lg" name="category" id="validationCustom03" onChange={(event) => this.selectSSNorEIN(event.target.value, processId)}>
                                            <option value=''>Select Type</option>
                                            <option value='SSN' selected={this.state.documents && this.state.documents[processId] && this.state.documents[processId].taxIdNumberType === 'SSN'}>SSN</option>
                                            <option value='EIN' selected={this.state.documents && this.state.documents[processId] && this.state.documents[processId].taxIdNumberType === 'EIN'}>EIN</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                }
                              </>

                            ) :
                              this.state.documents && this.state.documents[processId] && this.state.documents[processId].isForeignCitizen ?
                                <div className="row">
                                  <div className="col-lg-2 col-md-3 field-label-responsive">
                                    <label htmlFor="name">Foreign Tax ID <sup>*</sup> :</label>
                                  </div>
                                  <div className="col-lg-6 col-md-9">
                                    <div className="form-group">
                                      <input className="form-control" id="foreignTaxId" type="text"
                                        onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                        placeholder='Enter Foreign Tax ID'
                                        value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].foreignTaxId} />
                                    </div>
                                  </div>
                                </div> :
                                <div className="row">
                                  <div className="col-lg-2 col-md-3 field-label-responsive">
                                    <label htmlFor="name">SSN <sup>*</sup> :</label>
                                  </div>
                                  <div className="col-lg-6 col-md-9">
                                    <div className="form-group amount-investment">
                                      <NumberFormat
                                        value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].taxIdNumber}
                                        allowLeadingZeros={true}

                                        onValueChange={(values) => {
                                          this.handleChange('SSN', values.value, processId)
                                        }} />
                                    </div>
                                  </div>
                                </div>

                          }
                          <div className="row">
                            <div className="col-lg-2 col-md-3" />
                            <div className="col-lg-6 col-md-9 document-btn">
                              <LaddaButton
                                id='generateDoc'
                                loading={this.state.loadinglada}
                                onClick={() => this.addDocument(processId)}
                                data-color="#eee"
                                data-size={XS}
                                className={!this.validInvitaionForm(processId) ? 'disable' : ''}
                                data-style={EXPAND_RIGHT}
                                data-spinner-size={30}
                                data-spinner-color="#ddd"
                                data-spinner-lines={12}
                              >
                                GENERATE DOCS
                              </LaddaButton>
                            </div>
                          </div>
                        </div>)
                        : (
                          <>
                            <div className="row">
                              <div className="col-lg-2 col-md-3 field-label-responsive">
                                <label htmlFor="name">Amount<sup>*</sup> :</label>
                              </div>
                              <div className="col-lg-6 col-md-9">
                                <div className="form-group amount-investment">
                                  <NumberFormat
                                    value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].amount}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    displayType={'text'}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-2 col-md-3 field-label-responsive">
                                <label htmlFor="email">Type<sup>*</sup> :</label>
                              </div>
                              <div className="col-lg-6 col-md-9 col-sm-9">
                                <div className="form-group">
                                  {
                                    this.state.documents && this.state.documents[processId] && !this.state.documents[processId].isEntity ? 'Individual' : 'Entity'
                                  }

                                </div>
                              </div>
                            </div>
                            {
                              this.state.documents && this.state.documents[processId] && this.state.documents[processId].isEntity ? (<>
                                <div className="row">
                                  <div className="col-lg-2 col-md-3 field-label-responsive">
                                    <label htmlFor="email">Entity Type<sup>*</sup> :</label>
                                  </div>
                                  <div className="col-lg-6 col-md-9 col-sm-9">
                                    <div className="form-group">
                                      {this.state.documents[processId].memberType}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-2 col-md-3 field-label-responsive">
                                    <label htmlFor="name">Entity Name<sup>*</sup> :</label>
                                  </div>
                                  <div className="col-lg-6 col-md-9">
                                    <div className="form-group">
                                      {this.state.documents[processId].entityName}
                                    </div>
                                  </div>
                                </div>
                                <div className="row" style={{ display: 'none' }}>
                                  <div className="col-lg-2 col-md-3 field-label-responsive">
                                    <label htmlFor="name">Title<sup>*</sup> :</label>
                                  </div>
                                  <div className="col-lg-6 col-md-9">
                                    <div className="form-group">
                                      {this.state.documents[processId].title}
                                    </div>
                                  </div>
                                </div>
                              </>) :
                                (
                                  <>
                                    <div className="row">
                                      <div className="col-lg-2 col-md-3 field-label-responsive">
                                        <label htmlFor="name">First Name<sup>*</sup> :</label>
                                      </div>
                                      <div className="col-lg-6 col-md-9">
                                        <div className="form-group">
                                          {this.state.documents[processId].firstName}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-2 col-md-3 field-label-responsive">
                                        <label htmlFor="name">Middle Name :</label>
                                      </div>

                                      <div className="col-lg-6 col-md-9">
                                        <div className="form-group">
                                          {this.state.documents[processId].middleName}
                                        </div>
                                      </div>
                                    </div>


                                    <div className="row">
                                      <div className="col-lg-2 col-md-3 field-label-responsive">
                                        <label htmlFor="name">Last Name<sup>*</sup> :</label>
                                      </div>
                                      <div className="col-lg-6 col-md-9">
                                        <div className="form-group">
                                          {this.state.documents[processId].lastName}
                                        </div>
                                      </div>
                                    </div>

                                  </>
                                )
                            }
                            <div className="row">
                              <div className="col-lg-2 col-md-3 field-label-responsive">
                                <label htmlFor="name">Address 1<sup>*</sup> :</label>
                              </div>
                              <div className="col-lg-6 col-md-9">
                                <div className="form-group">
                                  {this.state.documents[processId].address1}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-2 col-md-3 field-label-responsive">
                                <label htmlFor="name">Address 2 :</label>
                              </div>
                              <div className="col-lg-6 col-md-9">
                                <div className="form-group">
                                  {this.state.documents[processId].address2}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-2 col-md-3 field-label-responsive">
                                <label htmlFor="name">City<sup>*</sup> :</label>
                              </div>
                              <div className="col-lg-6 col-md-9">
                                <div className="form-group">
                                  {this.state.documents[processId].city}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-2 col-md-3 field-label-responsive">
                                <label htmlFor="name">State<sup>*</sup> :</label>
                              </div>
                              <div className="col-lg-6 col-md-9">
                                <div className="form-group">
                                  {this.state.documents[processId].state}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-2 col-md-3 field-label-responsive">
                                <label htmlFor="name">Zipcode<sup>*</sup> :</label>
                              </div>
                              <div className="col-lg-6 col-md-9">
                                <div className="form-group amount-investment">
                                  <NumberFormat
                                    value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].zipcode}
                                    displayType={'text'}
                                  />
                                </div>
                              </div>
                            </div>
                            {
                              this.state.documents && this.state.documents[processId] && this.state.documents[processId].isEntity ? (<>
                                <div className="row">
                                  <div className="col-lg-2 col-md-3 field-label-responsive">
                                    <label htmlFor="name">Tax I.D. Number <sup>*</sup> :</label>
                                  </div>
                                  <div className="col-lg-6 col-md-9">
                                    <div className="form-group amount-investment">
                                      <NumberFormat
                                        value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].taxIdNumber}
                                        displayType={'text'}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                              ) : (
                                <div className="row">
                                  <div className="col-lg-2 col-md-3 field-label-responsive">
                                    <label htmlFor="name">SSN <sup>*</sup> :</label>
                                  </div>
                                  <div className="col-lg-6 col-md-9">
                                    <div className="form-group amount-investment">
                                      <NumberFormat
                                        value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].taxIdNumber}
                                        displayType={'text'}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          </>
                        )
                    }
                  </div>
                </div>
              }
              {
                this.state.documents && this.state.documents[processId] && this.state.documents[processId].isSubscribed &&
                this.state.documents[processId].isExecute && (!this.state.documents[processId].isDocSign || (this.state.documents[processId].managementFee === null || (this.state.documents[processId].managementFee >= 0 && !this.state.documents[processId].isSignSideletter))) &&
                <div className="col-lg-10 col-md-9 col-sm-9">
                  <div className="card">
                    <h3 className="full-width">
                      {
                        this.state.documents && this.state.documents[processId] && this.state.documents[processId].isDocSign && (this.state.documents[processId].managementFee === null || this.state.documents[processId].sideletterEnvId) ? "Executed Documents" : "Execute"
                      }
                    </h3>
                    {
                      !this.state.documents[processId].isDocSign &&
                      // <div className="back-dashboard" style={{float:'right', position: 'relative',top: '-90px', right: '-9px'}} onClick={() => this.cancelInvestment(processId)}>
                      //   <a className="btn btn-danger">Cancel Investment</a>
                      // </div>
                      <a className="back-dashboard" style={{ float: 'right', position: 'relative', top: '-75px', right: '0px', color: '#F15B4B', cursor: 'pointer' }} onClick={() => this.cancelInvestment(processId)} >Cancel Investment</a>
                    }
                    {
                      this.state.documents[processId].isDocSign &&
                      this.state.documents[processId].isSignSubscriptionAggrement &&
                      <div className='viewDoc'>
                        {this.getQSA(this.state.documents[processId].subscriptionEnvId)}
                        {this.getWForm(this.state.documents[processId].w9EnvId, this.state.documents[processId].isForeignCitizen)}

                        {
                          (this.state.documents[processId].managementFee != null && !this.state.documents[processId].isSignSideletter) &&
                          <>
                            {

                              this.state.documents[processId].isSignSideletter && (this.state.documents[processId].sideletterEnvId && !this.state.documents[processId].isSignSideLetterResend) ?
                                <>
                                  {this.getSignedSignLetter(this.state.documents[processId].sideletterEnvId)}
                                </>
                                :
                                <>
                                  <form onSubmit={this.getFormData} action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${this.getSignSideLettterPowerFormId()}`} method="POST">
                                    <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
                                    <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
                                    <input type="hidden" name="v" id="v" value='2' />
                                    <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
                                    <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
                                    <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
                                    <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
                                    <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
                                    <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='sideletter' />
                                    <input type="hidden" name="investor_UserName" id="investor_UserName" value={this.getUserName(processId)} />
                                    <input type="hidden" name="investor_entityName" id="investor_entityName" value={investment.llcName} />
                                    <input type="hidden" name="investor_Email" id="investor_Email" value={window.localStorage.getItem('emailAddress')} />
                                    <input type="hidden" name="investor_address" id="investor_address" value={investment.investmentprocesses[0].address1} />
                                    <input type="hidden" name="investor_address2" id="investor_address2" value={investment.investmentprocesses[0].address2} />
                                    <input type="hidden" name="investor_managementFee" id="investor_managementFee" value={this.getUserCarry(investment.investmentprocesses[0].managementFee, 1)} />
                                    <input type="hidden" name="investor_managementFeeValue" id="investor_managementFeeValue" value={investment.investmentprocesses[0].managementFee} />
                                    <input type="hidden" name="investor_carry" id="investor_carry" value={this.getUserCarry(investment.investmentprocesses[0].carry, 1)} />
                                    <input type="hidden" name="investor_carryAll" id="investor_carryAll" value={this.getUserCarry(investment.investmentprocesses[0].carry, 2)} />
                                    <input type="hidden" name="EnvelopeField_reSend" id="EnvelopeField_reSend" value={false} />

                                    <button type='submit' className='btn btn-danger'>
                                      {
                                        (this.state.documents[processId].isSignSideLetterResend) ?
                                          'Re-Sign Side Letter'
                                          :
                                          'Sign Side Letter'
                                      }
                                    </button>
                                  </form>
                                </>
                            }
                          </>
                        }

                      </div>
                    }
                    {
                      (!this.state.documents[processId].isDocSign) ?
                        (
                          <>
                            {
                              this.state.documents[processId].isRequestExceedAmount ?
                                <>
                                  <div className="invitations_right">
                                    <div className="description-txt">
                                      Your investment amount of  <NumberFormat
                                        value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].amount}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        displayType={'text'}
                                      /> is waiting for approval from TSG. Once approved, you will be able to execute the process of signing document.
                                    </div>
                                  </div>
                                </> :
                                <>

                                  {
                                    this.state.documents[processId].isSignSubscriptionAggrement ?
                                      <h4>Documents Complete</h4> :
                                      <h4>Review, Complete and Sign Investment Documents</h4>
                                  }

                                  <div className="invitations_right">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="form-group">
                                          <div className="checklist_bg">
                                            <div className="chiller_cb">
                                              {/* <input type='checkbox' id={`signSubscription`} checked={this.state.documents[processId].isSignSubscriptionAggrement ? true : false} /> */}

                                              {
                                                this.state.documents[processId].isSignSubscriptionAggrement ?
                                                  <>
                                                    {this.getQSA(this.state.documents[processId].subscriptionEnvId)}
                                                    {this.getWForm(this.state.documents[processId].w9EnvId, this.state.documents[processId].isForeignCitizen)}
                                                  </> :
                                                  <>
                                                    <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} target='_blank' href={`/signOA?investmentId=${investment._id}`} >Read Operating Agreement</a>
                                                    <br /><br /><br />
                                                    {
                                                      this.state.documents[processId].isSubcriptionComplete &&
                                                        this.state.documents[processId].subscriptionEnvId ?
                                                        <>
                                                          {this.getQSA(this.state.documents[processId].subscriptionEnvId)}<br /><br />

                                                        </> :
                                                        <>
                                                          <form onSubmit={this.getFormData} action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${this.getQSAPowerFormId(this.state.documents[processId].type, shareType, this.state.documents[processId].memberType)}`} method="POST">
                                                            <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
                                                            <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
                                                            <input type="hidden" name="v" id="v" value='2' />
                                                            <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
                                                            <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
                                                            <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
                                                            <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
                                                            <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
                                                            <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='subscription' />
                                                            <input type="hidden" name="investor_UserName" id="investor_UserName" value={this.getUserName(processId)} />
                                                            <input type="hidden" name="investor_amount" id="investor_amount" value={this.state.documents[processId].formattedAmount} />
                                                            <input type="hidden" name="investor_pricePerClass" id="investor_pricePerClass" value={Number(this.state.documents[processId].pricePerClass).toFixed(4)} />
                                                            <input type="hidden" name="investor_classAUnits" id="investor_classAUnits" value={Number(Number(this.state.documents[processId].amount) / Number(this.state.documents[processId].pricePerClass).toFixed(4)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} />
                                                            <input type="hidden" name="investor_contactPerson" id="investor_contactPerson" value={this.getUserName(processId)} />
                                                            <input type="hidden" name="investor_investorEmail" id="investor_investorEmail" value={window.localStorage.getItem('emailAddress')} />
                                                            <input type="hidden" name="investor_Email" id="investor_Email" value={window.localStorage.getItem('emailAddress')} />
                                                            <input type="hidden" name="investor_address" id="investor_address" value={this.addressFormat(processId)} />
                                                            <input type="hidden" name="investor_state" id="investor_state" value={this.state.documents[processId].state} />
                                                            <input type="hidden" name="investor_phoneNumber" id="investor_phoneNumber" value={this.props.phoneNumber} />
                                                            <input type="hidden" name="investor_LLCName" id="investor_LLCName" value={llcName} />
                                                            <input type="hidden" name="investor_companyName" id="investor_companyName" value={this.props.legalName} />
                                                            <input type="hidden" name="investor_title" id="investor_title" value={this.state.documents[processId].title} />
                                                            <input type="hidden" name="investor_entityName" id="investor_entityName" value={this.state.documents[processId].entityName} />
                                                            <input type="hidden" name="investor_memberType" id="investor_memberType" value={this.state.documents[processId].memberType ? this.state.documents[processId].memberType : 'Corporation'} />
                                                            <input type="hidden" name="investor_otherEntityType" id="investor_otherEntityType" value={this.state.documents[processId].otherEntityType ? this.state.documents[processId].otherEntityType : ''} />
                                                            {
                                                              this.state.documents && this.state.documents[processId] && this.state.documents[processId].isForeignCitizen ?
                                                                <input type="hidden" name="EnvelopeField_isForeignCitizen" id="EnvelopeField_isForeignCitizen" value='yes' /> :
                                                                <input type="hidden" name="EnvelopeField_isForeignCitizen" id="EnvelopeField_isForeignCitizen" value='no' />
                                                            }


                                                            {this.state.documents && this.state.documents[processId] && this.state.documents[processId].isForeignCitizen ?
                                                              <input type="hidden" name="investor_taxIdNumber" id="investor_taxIdNumber" value={this.state.documents[processId].foreignTaxId} />
                                                              :
                                                              <>
                                                                { //console.log("ssss",this.state.documents[processId])
                                                                }
                                                                {
                                                                  this.state.documents && this.state.documents[processId] && this.state.documents[processId].isEntity ?

                                                                    this.state.documents[processId].taxIdNumberType === 'SSN' ?
                                                                      <>
                                                                        { //console.log("iiiii",this.state.documents[processId].taxIdNumberType) 
                                                                        }
                                                                        <input type="hidden" name="investor_taxIdNumber" id="investor_taxIdNumber" value={this.formatSSN(this.state.documents[processId].taxIdNumber)} />
                                                                      </>
                                                                      :
                                                                      <>
                                                                        { //console.log("00000",processId,this.state.documents[processId].taxIdNumberType) 
                                                                        }
                                                                        <input type="hidden" name="investor_taxIdNumber" id="investor_taxIdNumber" value={this.formatTaxIdNumber(this.state.documents[processId].taxIdNumber)} />
                                                                      </>
                                                                    :
                                                                    <input type="hidden" name="investor_taxIdNumber" id="investor_taxIdNumber" value={this.formatSSN(this.state.documents[processId].taxIdNumber)} />

                                                                }
                                                              </>
                                                            }
                                                            <button type='submit' className='btn btn-danger'>Sign QSA</button>

                                                          </form>
                                                          <br />
                                                        </>
                                                    }

                                                    {
                                                      this.state.documents[processId].isSignW9 &&
                                                        this.state.documents[processId].w9EnvId ?
                                                        <>
                                                          {this.getWForm(this.state.documents[processId].w9EnvId, this.state.documents[processId].isForeignCitizen)}
                                                        </> :
                                                        <>
                                                          <form action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${this.getCitizenPowerFormId(this.state.documents[processId].isForeignCitizen, this.state.documents[processId].type)}`} method="POST">
                                                            <input type="hidden" name="investor_UserName" id="investor_UserName" value={this.getUserName(processId)} />
                                                            <input type="hidden" name="investor_name" id="investor_name" value={this.getUserName(processId)} />
                                                            <input type="hidden" name="investor_Email" id="investor_Email" value={window.localStorage.getItem('emailAddress')} />
                                                            <input type="hidden" name="investor_address" id="investor_address" value={this.w9FullAddress(processId)} />
                                                            <input type="hidden" name="investor_cityStateZip" id="investor_cityStateZip" value={`${this.state.documents[processId].city}, ${this.state.documents[processId].state}, ${this.state.documents[processId].zipcode}`} />
                                                            <input type="hidden" name="investor_country" id="investor_country" value={this.state.documents[processId].country} />
                                                            <input type="hidden" name="investor_foreignTaxIdNumber" id="investor_foreignTaxIdNumber" value={this.state.documents[processId].foreignTaxId} />
                                                            <input type="hidden" name="investor_taxIdNumber" id="investor_taxIdNumber" value={this.state.documents[processId].foreignTaxId} />
                                                            {
                                                              this.state.documents[processId].type === 'entity' &&
                                                              <input type="hidden" name="investor_entityName" id="investor_entityName" value={this.state.documents[processId].entityName} />
                                                            }
                                                            {
                                                              this.state.documents[processId].type === 'individual' &&
                                                              <input type="hidden" name="investor_w9MemberType" id="investor_w9MemberType" value='Individual' />
                                                            }

                                                            {
                                                              this.state.documents && this.state.documents[processId] && this.state.documents[processId].isEntity ?

                                                                this.state.documents[processId].taxIdNumberType === 'SSN' ?
                                                                  <input type="hidden" name="investor_SSN" id="investor_SSN" value={this.formatSSN(this.state.documents[processId].taxIdNumber)} /> :
                                                                  <input type="hidden" name="investor_EIN" id="investor_EIN" value={this.formatTaxIdNumber(this.state.documents[processId].taxIdNumber)} /> :
                                                                <input type="hidden" name="investor_SSN" id="investor_SSN" value={this.formatSSN(this.state.documents[processId].taxIdNumber)} />
                                                            }
                                                            <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
                                                            <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
                                                            <input type="hidden" name="v" id="v" value='2' />
                                                            <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
                                                            <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
                                                            <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
                                                            <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
                                                            <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
                                                            <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='signw9' />
                                                            {
                                                              this.state.documents && this.state.documents[processId] && this.state.documents[processId].isForeignCitizen ?
                                                                <input type="hidden" name="EnvelopeField_isForeignCitizen" id="EnvelopeField_isForeignCitizen" value="yes" /> :
                                                                <input type="hidden" name="EnvelopeField_isForeignCitizen" id="EnvelopeField_isForeignCitizen" value="no" />
                                                            }

                                                            <button type='submit' className='btn btn-danger'>{this.state.documents[processId].isForeignCitizen ? 'Sign W8' : 'Sign W9'}</button>

                                                          </form>
                                                        </>
                                                    }
                                                    {
                                                      investmentProcess && investmentProcess.isFeeAndCarrySet &&
                                                      <>
                                                        {
                                                          this.state.documents[processId].isSignSideletter &&
                                                            (this.state.documents[processId].sideletterEnvId && !this.state.documents[processId].isSignSideLetterResend) ?
                                                            <>
                                                              <div className='viewDoc'>
                                                                {this.getSignedSignLetter(this.state.documents[processId].sideletterEnvId)}
                                                              </div>
                                                            </>
                                                            :
                                                            <>
                                                              <br />
                                                              <form onSubmit={this.getFormData} action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${this.getSignSideLettterPowerFormId()}`} method="POST">
                                                                <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
                                                                <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
                                                                <input type="hidden" name="v" id="v" value='2' />
                                                                <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
                                                                <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
                                                                <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
                                                                <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
                                                                <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
                                                                <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='sideletter' />
                                                                <input type="hidden" name="investor_entityName" id="investor_entityName" value={investment.llcName} />
                                                                <input type="hidden" name="investor_UserName" id="investor_UserName" value={this.getUserName(processId)} />
                                                                <input type="hidden" name="investor_Email" id="investor_Email" value={window.localStorage.getItem('emailAddress')} />
                                                                <input type="hidden" name="investor_address" id="investor_address" value={investment.investmentprocesses[0].address1} />
                                                                <input type="hidden" name="investor_address2" id="investor_address2" value={investment.investmentprocesses[0].address2} />
                                                                <input type="hidden" name="investor_managementFee" id="investor_managementFee" value={this.getUserCarry(investment.investmentprocesses[0].managementFee, 1)} />
                                                                <input type="hidden" name="investor_managementFeeValue" id="investor_managementFeeValue" value={investment.investmentprocesses[0].managementFee} />
                                                                <input type="hidden" name="investor_carry" id="investor_carry" value={this.getUserCarry(investment.investmentprocesses[0].carry, 1)} />
                                                                <input type="hidden" name="investor_carryAll" id="investor_carryAll" value={this.getUserCarry(investment.investmentprocesses[0].carry, 2)} />
                                                                <input type="hidden" name="EnvelopeField_reSend" id="EnvelopeField_reSend" value={false} />

                                                                <button type='submit' className='btn btn-danger'>
                                                                  {
                                                                    (this.state.documents[processId].isSignSideLetterResend) ?
                                                                      'Re-Sign Side Letter'
                                                                      :
                                                                      'Sign Side Letter'
                                                                  }
                                                                </button>
                                                              </form>
                                                            </>
                                                        }
                                                      </>
                                                    }

                                                    <br />
                                                    <div style={{ display: 'inline-block' }} onClick={() => this.menuClick('document', processId)}>
                                                      <button className='btn btn-danger'>Adjust Amount</button>
                                                    </div>
                                                  </>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="row">
                                      <div className="col-lg-12">
                                        {(this.state.documents[processId].isSignSubscriptionAggrement) && <div className="execute-btn">
                                          <LaddaButton
                                            id='execute-btn'
                                            loading={this.state.loadinglada}
                                            onClick={() => this.execute(processId)}
                                            data-color="#eee"
                                            data-size={XS}
                                            className=''
                                            data-style={EXPAND_RIGHT}
                                            data-spinner-size={30}
                                            data-spinner-color="#ddd"
                                            data-spinner-lines={12}
                                          >
                                            CONTINUE
                                </LaddaButton>
                                        </div>}
                                      </div>
                                    </div> */}


                                  </div>
                                </>
                            }
                          </>
                        )
                        : (
                          !this.state.documents[processId].executedDate &&
                            this.state.documents[processId].isDocSign ?
                            (
                              (investmentProcess && investmentProcess.isFeeAndCarrySet) ?
                                <>
                                  {
                                    this.state.documents[processId].isSignSideletter &&
                                      (this.state.documents[processId].sideletterEnvId && !this.state.documents[processId].isSignSideLetterResend) ?

                                      <div className="invitations_right">
                                        <div className="description-txt">Your investment is currently pending.</div>
                                        <div className="description-txt">Once confirmed, you will receive funding instructions.</div>
                                      </div>
                                      :
                                      ""
                                  }
                                </>
                                :
                                (
                                  <div className="invitations_right">
                                    <div className="description-txt">Your investment is currently pending.</div>
                                    <div className="description-txt">Once confirmed, you will receive funding instructions.</div>
                                  </div>
                                )

                            ) :
                            !this.state.documents[processId].paymentDate &&
                              this.state.documents[processId].executedDate &&
                              this.state.documents[processId].isDocSign ?
                              (
                                <div className="invitations_right">
                                </div>
                              ) :
                              this.state.documents[processId].paymentDate &&
                                this.state.documents[processId].executedDate &&
                                this.state.documents[processId].isDocSign ?
                                (
                                  <div className="invitations_right">
                                    <div className="description-txt">Your investment is currently pending.</div>
                                    <div className="description-txt">Once your payment confirmed by TSG, your investment process will complete.</div>
                                  </div>
                                ) : null

                        )
                    }
                    {
                      this.state.documents[processId].isDocSign && this.state.documents[processId].isSignSubscriptionAggrement && (this.state.documents[processId].managementFee === null || (this.state.documents[processId].managementFee >= 0 && this.state.documents[processId].isSignSideletter)) &&

                      <div className="row" style={{ marginTop: '15px', float: 'left' }}>
                        <div className="col-lg-12">
                          {(this.state.documents[processId].isSignSubscriptionAggrement) && <div className="execute-btn">
                            <LaddaButton
                              id='execute-btn'
                              loading={this.state.loadinglada}
                              onClick={() => this.menuClick('fund', processId)}
                              data-color="#eee"
                              data-size={XS}
                              className=''
                              data-style={EXPAND_RIGHT}
                              data-spinner-size={30}
                              data-spinner-color="#ddd"
                              data-spinner-lines={12}
                            >
                              Fund 
                            </LaddaButton>
                          </div>}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
              {
                this.state.documents && this.state.documents[processId] && !this.state.documents[processId].isDocument && !this.state.documents[processId].isExecute && (this.state.documents[processId].managementFee === null || (this.state.documents[processId].managementFee >= 0)) &&

                <div className="col-lg-10 col-md-9 col-sm-9">
                  <div className="card">
                    <h3>Fund</h3>
                    {
                      this.state.documents[processId].executedDate
                        && this.state.documents[processId].status !== 'Payment' ?
                        (
                          this.state.fundingStatus === "start" ?
                            <>
                              <h4 className='title-fund' style={{ fontSize: "17px", fontWeight: "700" }}>TSG Verivend Wallet</h4>
                              <div className="invitations_right invitations_right_fund">
                                <div className="col">
                                  <div style={{ padding: "30px 10px", textAlign: "justify", lineHeight: "normal" }}>
                                    <h4 style={{ fontWeight: "400" }}>
                                      Verivend’s proprietary Digital Wallet technology is an immutable and fully auditable ledger of all historical and current fund
                                      ownership within Verivend’s payment network that provides a detailed record of all transaction activity maintained using Verivend’s proprietary
                                      Digital Wallet ledgering technology. All Capital Contributions within TSG’s Verivend Digital Wallet are completely under the control of TSG as the
                                      recipient of the funds. Available funds can be withdrawn at any time to an external bank account at TSG’s discretion or set to automatically transfer
                                      if designated by TSG. Capital Accounts will be maintained through the TSG’s relationship with Verivend and its banking partners, using Verivend’s proprietary
                                      Digital Wallet technology. Capital Accounts will be maintained in accordance with U.S. federal income tax guidelines.
                                    </h4>
                                  </div>

                                  <div className="legalname-container">
                                    <div className="row">
                                      <div className="col-md-6 col-10">
                                        <div className="form-group">
                                          <div className="investor-input">
                                            <label>Legal Name</label>
                                            <input
                                              type="text"
                                              value={this.state.legelName}
                                              onChange={(event) =>
                                                this.setState({
                                                  legelName: event.target.value,
                                                })
                                              }
                                              name="legalName"
                                              className="form-control"
                                              placeholder="Enter Your Legal Name"
                                              disabled={this.state.isLegalNameConfirmed}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex check-container">
                                      <input type="checkbox"
                                        onChange={() => {
                                          this.setState({ isLegalNameConfirmed: !this.state.isLegalNameConfirmed })
                                        }}
                                        checked={this.state.isLegalNameConfirmed}
                                      />
                                      <span>I confirm my legal name is {this.state.legelName}.</span>
                                    </div>
                                  </div>

                                  <div className="form-btn" style={{ padding: "30px 10px" }}>
                                    <TButton
                                      id='form-btn'
                                      onClick={() => this.createWalletHandler()}
                                      text={'Create Wallet'}
                                      style={{ display: 'inline-block', padding: '4px 10px' }}
                                      disabled={!this.state.isLegalNameConfirmed}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                            : this.state.fundingStatus === "firstPage" ?
                              <>
                                <div className="invitations_right invitations_right_fund">
                                  <div className="row">
                                    <div className="d-flex flex-row" style={{ justifyContent: "center" }}>

                                      {(!this.state.transaction || !this.state.transaction?.wireInstructions) && (
                                        <>
                                          <div
                                            className="col-md-1 col-xs-3"
                                            style={{ display: "flex", alignItems: "center" }}
                                            onClick={() => this.handleTransactionType('wallet')}
                                          >
                                            <h4 style={{ fontSize: "16px" }}><b style={{ marginRight: '5px' }}>Wallet</b></h4>
                                            <img style={{ hegiht: "18px", width: "18px" }} src={this.getActiveStatusClass("wallet")} alt="" />
                                          </div>
                                          <div
                                            className="col-md-1 col-xs-3 justify-end"
                                            style={{ display: "flex", alignItems: "center" }}
                                            onClick={() => this.handleTransactionType('ach')}
                                          >
                                            <h4 style={{ fontSize: "16px" }}><b style={{ marginRight: '5px' }}>ACH</b></h4>
                                            <img style={{ hegiht: "18px", width: "18px" }} src={this.getActiveStatusClass("ach")} alt="" />
                                          </div><div
                                            className="col-md-1 col-xs-3"
                                            style={{ display: "flex", alignItems: "center" }}
                                            onClick={() => this.handleTransactionType('wire')}
                                          >
                                            <h4 style={{ fontSize: "16px" }}><b style={{ marginRight: '5px' }}>Wire</b></h4>
                                            <img style={{ hegiht: "18px", width: "18px" }} src={this.getActiveStatusClass("wire")} alt="" />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {
                                    this.state.transactionType === "wallet" ?
                                      <div className="row d-flex flex-row" style={{ justifyContent: "center" }}>
                                        <div className="col-lg-6 max-width-content mobile-spacing">
                                          <h3>Wallet</h3>
                                          <br />
                                          <div className="row d-flex space-between" style={{ padding: "0px 20px" }}>
                                            <div className="col-md-6" style={{ textAlign: "left" }}>
                                              <div className="row">
                                                <h4>Balance Available:</h4>
                                              </div>
                                              <br />
                                              <div className="row">
                                                <h4>Investment Amount:</h4>
                                              </div>
                                              <br />
                                            </div>
                                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                              <div className="row">
                                                <h4><NumberFormat
                                                  className="amount-text"
                                                  value={this.state.walletBalanceData.currentBalance || 0}
                                                  displayType={'text'}
                                                  thousandSeparator={true}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  prefix={'$'} />
                                                </h4>
                                              </div>
                                              <br />
                                              <div className="row">
                                                <h4><NumberFormat
                                                  className="amount-text"
                                                  value={investment.investmentprocesses[0].amount}
                                                  displayType={'text'}
                                                  thousandSeparator={true}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  prefix={'$'} /></h4>
                                              </div>
                                              <br />
                                            </div>
                                          </div>
                                          <br />
                                          <div
                                            className="row"
                                            style={{ display: "flex", alignItems: "center", padding: "0px 20px" }}
                                            onClick={() => this.handleCheckButton()}
                                          >
                                            <img style={{ hegiht: "18px", width: "18px" }} src={this.getActiveCheckedImage()} alt="" />
                                            <div>
                                              <h4 style={{ fontSize: "14px", marginLeft: '10px' }}><b>By checking this box, I authorize funding my investment using my TSG Wallet balance.</b></h4>
                                            </div>
                                          </div>
                                          <br />
                                          <div className="row d-flex flex-row" style={{ justifyContent: "flex-end" }}>
                                            <div className="form-btn d-flex" style={{ padding: "30px 10px", alignItems: "center" }}>
                                              <input type="button" id="pop_cancel_list" className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => window.location.href = "/"} style={{ marginLeft: "10px" }} />
                                              <LaddaButton
                                                loading={this.state.loadinglada}
                                                style={{
                                                  margin: "6px",
                                                  color: "#ffffff",
                                                  backgroundColor: "#ef534a",
                                                  borderRadius: "4px",
                                                  fontSize: "14px",
                                                }}
                                                onClick={() => this.openWalletFundingConfirmationModel()}
                                                data-color="#eee"
                                                data-size={S}
                                                className={!this.state.isChecked ?
                                                  'disable' : ''}
                                                data-style={EXPAND_RIGHT}
                                                data-spinner-size={30}
                                                data-spinner-color="#ddd"
                                                data-spinner-lines={12}
                                              >
                                                SUBMIT
                                              </LaddaButton>
                                              {/* <input type="button" id="pop_cancel_list" className="btn btn-default" data-dismiss="modal" value="CANCEL" style={{ marginLeft: "10px" }} /> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      this.state.transactionType === "ach"
                                        ? this.state.bankAccounts.length > 0
                                          && this.state.bankAccountType === "bankAccount" ?
                                          (<>
                                            <div className="row d-flex flex-row" style={{ justifyContent: "center" }}>
                                              <div className="col-lg-6 max-width-content mobile-spacing">
                                                <h3>ACH</h3>
                                                <div className="col-xs-12 col-md-12">
                                                  <div className="row d-flex space-between" style={{ padding: "0px 5px" }}>
                                                    <div className="col-md-6" style={{ textAlign: "left" }}>
                                                      <div className="row">
                                                        <h4>Investment Amount:</h4>
                                                      </div>
                                                      <br />
                                                    </div>
                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                      <div className="row">
                                                        <NumberFormat
                                                          className="amount-text"
                                                          value={investment.investmentprocesses[0].amount}
                                                          displayType={'text'}
                                                          thousandSeparator={true}
                                                          decimalScale={2}
                                                          fixedDecimalScale={true}
                                                          prefix={'$'} />
                                                      </div>
                                                      <br />
                                                    </div>
                                                  </div>
                                                  {
                                                    this.state.bankAccounts.map((bankAccount, index) => {
                                                      return (<>
                                                        <div
                                                          className="row flex-row"
                                                          key={index}
                                                          style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}

                                                        >
                                                          <div className='col-md-8'>
                                                            <div className='row d-flex flex-row' style={{ margin: "0px 5px" }} >
                                                              <img style={{ hegiht: "18px", width: "18px" }} src={this.getActiveBankClass(bankAccount.bankAccountId)} alt="" onClick={() => this.handleBankAccount(bankAccount.bankAccountId)} />
                                                              <h4 style={{ fontSize: "14px" }}><b style={{ marginLeft: '10px' }}><span>{bankAccount.bankAccountName}</span>  <span>{this.showLastFourChars(bankAccount.bankAccountNumber)}</span></b></h4>
                                                            </div>
                                                          </div>
                                                          <div className='col-md-4'>
                                                          </div>
                                                        </div>
                                                      </>);
                                                    })
                                                  }
                                                  <div
                                                    className="row"
                                                    style={{ display: "flex", alignItems: "center", padding: "10px" }}
                                                    onClick={() => this.handleCheckButton()}
                                                  >
                                                    <img style={{ hegiht: "18px", width: "18px" }} src={this.getActiveCheckedImage()} alt="" />
                                                    <div>
                                                      <h4 style={{ fontSize: "14px", marginLeft: '10px' }}><b>By checking this box, I authorize funding my investment via ACH from the bank account specified above.</b></h4>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="row d-flex flex-row" style={{ justifyContent: "flex-end" }}>
                                                  <div className="form-btn d-flex" style={{ padding: "30px 10px", alignItems: "center" }}>
                                                    <input
                                                      type="button"
                                                      id="pop_cancel_list"
                                                      className="btn btn-default"
                                                      data-dismiss="modal"
                                                      value="CANCEL"
                                                      onClick={() => window.location.href = "/"}
                                                      style={{
                                                        margin: "6px"
                                                      }} />
                                                    <input
                                                      type="button"
                                                      id="add_bank_account"
                                                      className="btn btn-default"
                                                      value="ADD BANK ACCOUNT"
                                                      onClick={() => this.handleBankAccountType("addBankAccount")}
                                                      style={{
                                                        margin: "6px"
                                                      }} />
                                                    <LaddaButton
                                                      loading={this.state.loadinglada}
                                                      style={{
                                                        margin: "6px",
                                                        color: "#ffffff",
                                                        backgroundColor: "#ef534a",
                                                        borderRadius: "4px",
                                                        fontSize: "14px",
                                                      }}
                                                      onClick={() => this.makeTransaction("ach", investment.investmentprocesses[0])}
                                                      data-color="#eee"
                                                      data-size={S}
                                                      className={this.achValidation() ?
                                                        'disable' : ''}
                                                      data-style={EXPAND_RIGHT}
                                                      data-spinner-size={30}
                                                      data-spinner-color="#ddd"
                                                      data-spinner-lines={12}
                                                    >
                                                      SUBMIT
                                                    </LaddaButton>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                          ) :
                                          (
                                            <div className="row d-flex flex-row" style={{ justifyContent: "center" }}>
                                              <div className="col-lg-6 max-width-content mobile-spacing">
                                                <h3>ACH</h3>
                                                <div className="row d-flex space-between">
                                                  <div className="col-xs-12 col-md-12">
                                                    <div className="form-group-bank form-css">
                                                      <label style={{ fontWeight: "600" }} htmlFor="name">Account NickName<RequiredSign /> :</label>
                                                      <div className='recommendation-input' style={{ width: "60%" }}>
                                                        <input type="text"
                                                          value={this.state.achNickname}
                                                          onChange={(event) => {
                                                            this.onChangeInput(common.FormType.achNickname, event.target.value)
                                                          }}
                                                          name="website" className="form-control" id="accountNickname" placeholder="Enter Account Nickname"
                                                        />
                                                        <p style={{ color: 'red' }}>{this.state.errorWebisteUrl}</p>
                                                      </div>
                                                    </div>
                                                    <div className="form-group-bank form-css">
                                                      <label style={{ fontWeight: "600" }} htmlFor="name">Account Owner<RequiredSign /> :</label>
                                                      <div className='recommendation-input' style={{ width: "60%" }}>
                                                        <input type="text"
                                                          value={this.state.achAccountName}
                                                          onChange={(event) => {
                                                            this.onChangeInput(common.FormType.achAccountName, event.target.value)
                                                          }}
                                                          name="website" className="form-control" id="accountName" placeholder="Enter Account Name"
                                                        />
                                                        <p style={{ color: 'red' }}>{this.state.errorWebisteUrl}</p>
                                                      </div>
                                                    </div>
                                                    <div className="form-group-bank form-css">
                                                      <label style={{ fontWeight: "600" }} htmlFor="name">Account Number<RequiredSign /> :</label>
                                                      <div className='recommendation-input' style={{ width: "60%" }}>
                                                        <input type="number"
                                                          value={this.state.achAccountNumber}
                                                          onChange={(event) => {
                                                            this.onChangeInput(common.FormType.achAccountNumber, event.target.value)
                                                          }}
                                                          name="website" className="form-control" id="accountNumber" placeholder="Enter Account Number"
                                                        />
                                                        <p style={{ color: 'red' }}>{this.state.errorWebisteUrl}</p>
                                                      </div>
                                                    </div>
                                                    <div className="form-group-bank form-css">
                                                      <label style={{ fontWeight: "600" }} htmlFor="name">Routing Number<RequiredSign /> :</label>
                                                      <div className='recommendation-input' style={{ width: "60%" }}>
                                                        <input type="number"
                                                          value={this.state.achRoutingNumber}
                                                          onChange={(event) => {
                                                            this.onChangeInput(common.FormType.achRoutingNumber, event.target.value)
                                                          }}
                                                          name="website" className="form-control" id="routingNumber" placeholder="Enter Routing Number"
                                                        />
                                                        <p style={{ color: 'red' }}>{this.state.errorWebisteUrl}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row d-flex flex-row" style={{ justifyContent: "flex-end" }}>
                                                  <div className="form-btn" style={{ padding: "0px 10px" }}>
                                                    <input type="button" id="pop_cancel_list" className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => { this.state.bankAccounts.length > 0 ? this.handleBankAccountType("bankAccount") : window.location.href = "/" }} style={{ marginLeft: "10px" }} />
                                                    <LaddaButton id='form-btn'
                                                      loading={this.state.loadinglada}
                                                      style={{
                                                        margin: "6px",
                                                        color: "#ffffff",
                                                        backgroundColor: "#ef534a",
                                                        borderRadius: "4px",
                                                        fontSize: "14px",
                                                      }}
                                                      onClick={() => this.addBankAccountHandler()}
                                                      data-color="#eee"
                                                      data-size={S}
                                                      // data-target={`#confirmPaymentModal`}
                                                      data-toggle={`modal`}
                                                      className={this.state.achRoutingNumber === "" && this.state.achAccountName === "" && this.state.achAccountNumber === "" ?
                                                        'disable' : ''}
                                                      data-style={EXPAND_RIGHT}
                                                      data-spinner-size={30}
                                                      data-spinner-color="#ddd"
                                                      data-spinner-lines={12}
                                                    >
                                                      SUBMIT
                                                    </LaddaButton>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        : this.state.transactionType === "wire" && this.state.wirePage === "firstPage"
                                          ? <div className="row d-flex flex-row" style={{ justifyContent: "center" }}>
                                            <div className="col-lg-6 max-width-content mobile-spacing">
                                              <h3>Wire</h3>
                                              <div className="col-lg-12 col-md-12">
                                                <div className="row d-flex space-between" style={{ padding: "0px 5px" }}>
                                                  <div className="col-md-6" style={{ textAlign: "left" }}>
                                                    <div className="row">
                                                      <h4>Investment Amount:</h4>
                                                    </div>
                                                    <br />
                                                  </div>
                                                  <div className="col-md-6" style={{ textAlign: "right" }}>
                                                    <div className="row">
                                                      <NumberFormat
                                                        className="amount-text"
                                                        value={investment.investmentprocesses[0].amount}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        prefix={'$'} />
                                                    </div>
                                                    <br />
                                                  </div>
                                                </div>
                                                <div
                                                  className="row"
                                                  style={{ display: "flex", alignItems: "center", padding: "10px" }}
                                                  onClick={() => this.handleCheckButton()}
                                                >
                                                  <img style={{ hegiht: "18px", width: "18px" }} src={this.getActiveCheckedImage()} alt="" />
                                                  <div>
                                                    <h4 style={{ fontSize: "14px", marginLeft: '10px' }}><b>By checking this box, I authorize funding my investment via wire payment.</b></h4>
                                                  </div>
                                                </div>
                                                <div
                                                  className="row"
                                                  style={{ display: "flex", alignItems: "center", padding: "10px" }}
                                                >
                                                  <div>
                                                    <h4><b style={{ fontSize: "13px", fontWeight: "600" }}>NOTE:</b><b style={{ fontSize: "14px", marginLeft: '10px', fontWeight: "400" }}>After checking the box and clicking the continue button, the system will generate the wire instructions. Please review these instructions carefully before proceeding with the funds transfer. If you need assistance or encounter any issues, please contact our support team for immediate help.</b></h4>
                                                  </div>
                                                </div>

                                                <div className="row d-flex flex-row" style={{ justifyContent: "flex-end" }}>
                                                  <div className="form-btn d-flex" style={{ padding: "30px 10px", alignItems: "center" }}>
                                                    <input type="button" id="pop_cancel_list" className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => window.location.href = "/"} style={{ marginLeft: "10px" }} />
                                                    <LaddaButton
                                                      id='form-btn'
                                                      loading={this.state.loadinglada}
                                                      style={{
                                                        margin: "6px",
                                                        color: "#ffffff",
                                                        backgroundColor: "#ef534a",
                                                        borderRadius: "4px",
                                                        fontSize: "14px",
                                                      }}
                                                      onClick={() => this.makeTransaction("wire", investment.investmentprocesses[0])}
                                                      data-color="#eee"
                                                      data-size={S}
                                                      className={!this.state.isChecked ? 'disable' : ''}
                                                      data-style={EXPAND_RIGHT}
                                                      data-spinner-size={30}
                                                      data-spinner-color="#ddd"
                                                      data-spinner-lines={12}
                                                    >
                                                      CONTINUE
                                                    </LaddaButton>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          : <div className="row d-flex flex-row" style={{ justifyContent: "center" }}>
                                            <div className="col-lg-6">
                                              <h3>Wire</h3>
                                              <div className="col-md-12">
                                                <div className="row d-flex" style={{ padding: "0px 20px" }}>
                                                  <div className="col-md-6" style={{ textAlign: "left" }}>
                                                    <div className="row">
                                                      <h4>Investment Amount:</h4>
                                                    </div>
                                                    <br />
                                                  </div>
                                                  <div className="col-md-6" style={{ textAlign: "right" }}>
                                                    <div className="row">
                                                      <NumberFormat
                                                        className="amount-text"
                                                        value={transactionDetail.totalAmountToWire}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        prefix={'$'} />
                                                    </div>
                                                    <br />
                                                  </div>
                                                </div>
                                                <div className='fund_box' style={{ height: "auto", display: "flex", flexDirection: "column" }} >
                                                  {
                                                    transactionDetail.wireInstructions &&
                                                    <table className='tableForBankDetail'>
                                                      <tbody>
                                                        <tr>
                                                          <td>
                                                            <label>Bank Name:</label>
                                                            <p>{(transactionDetail.wireInstructions && transactionDetail.wireInstructions.bankName)}</p>
                                                          </td>
                                                          <td>
                                                            <label>Account Name:</label>
                                                            <p>{(transactionDetail.wireInstructions && transactionDetail.wireInstructions.verivendAccountName)}</p>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <label>Account Number:</label>
                                                            <p>{(transactionDetail.wireInstructions && transactionDetail.wireInstructions.verivendWireAccountNumber)}</p>
                                                          </td>
                                                          <td>
                                                            <label>Routing Number:</label>
                                                            <p>{(transactionDetail.wireInstructions && transactionDetail.wireInstructions.verivendWireABANumber)}</p>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <label>Bank Address:</label>
                                                            <p>{(transactionDetail.wireInstructions && transactionDetail.wireInstructions.bankAddress)}</p>
                                                          </td>
                                                          <td>
                                                            <label>Swift Code:</label>
                                                            <p>{(transactionDetail.wireInstructions && transactionDetail.wireInstructions.swiftCode)}</p>
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td>
                                                            <label>Memo Line:</label>
                                                            <p>{(transactionDetail.wireInstructions && transactionDetail.wireInstructions.wireMemo)}</p>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  }
                                                </div>
                                                <div className="form-horizontal">
                                                  <div className="row">
                                                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 field-label-responsive">
                                                      <label htmlFor="name">Wire Date<sup>*</sup> :</label>
                                                    </div>
                                                    <div className="col-xl-8 col-lg-6 col-md-7 col-sm-7">
                                                      <div className="form-group">
                                                        <DatePicker
                                                          selected={this.state.documents && this.state.documents[processId] && this.state.documents[processId].paymentDate}
                                                          onChange={(date) => this.handleDateChange(date, processId)}
                                                          maxDate={new Date()}
                                                        />
                                                        <p>{this.state.wireDateValidationMsg}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 field-label-responsive">
                                                      <label htmlFor="name">Wire Confirmation<sup>*</sup> :</label>
                                                    </div>
                                                    <div className="col-xl-8 col-lg-6 col-md-7 col-sm-7">
                                                      <div className="form-group">
                                                        {/* <input type="text" name="name" className="form-control" id="name" placeholder="Wire Confirmation" required autofocus /> */}
                                                        <input className="form-control" id="wireConfirmation" type="text"
                                                          onChange={(event) => this.handleChange(event.target.id, event.target.value, processId)}
                                                          placeholder='Wire Confirmation'
                                                          value={this.state.documents && this.state.documents[processId] && this.state.documents[processId].memoNo} />
                                                        <p>{this.state.wireConfirmationValidationMsg}</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="row d-flex flex-row" style={{ justifyContent: "flex-end" }}>
                                                  <div className="form-btn" style={{ padding: "30px 10px" }}>
                                                    <input type="button" id="pop_cancel_list" className="btn btn-default" data-dismiss="modal" value="CANCEL" onClick={() => this.cancelTransaction()} style={{ marginLeft: "10px" }} />
                                                    <LaddaButton
                                                      id='form-btn'
                                                      loading={this.state.loadinglada}
                                                      style={{
                                                        margin: "6px",
                                                        color: "#ffffff",
                                                        backgroundColor: "#ef534a",
                                                        borderRadius: "4px",
                                                        fontSize: "14px",
                                                      }}
                                                      onClick={() => this.fund(processId, this.state.documents[processId].status, "wire")}
                                                      data-color="#eee"
                                                      data-size={S}
                                                      className={!this.state.documents[processId].paymentDate || !this.state.documents[processId].memoNo ? 'disable' : ''}
                                                      data-style={EXPAND_RIGHT}
                                                      data-spinner-size={30}
                                                      data-spinner-color="#ddd"
                                                      data-spinner-lines={12}
                                                    >
                                                      SUBMIT
                                                    </LaddaButton>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                  }
                                </div>
                              </>
                              :
                              <></>

                        )
                        :
                        this.state.documents[processId].status === 'Payment' ?
                          (

                            <div className="invitations_right">
                              <div className="description-txt">You have successfully submitted your funding details – thank you!</div>
                              {
                                this.state.documents[processId].isDocSign &&
                                this.state.documents[processId].isSignSubscriptionAggrement &&
                                <div className='viewDoc'>
                                  {this.getQSA(this.state.documents[processId].subscriptionEnvId)}
                                  {this.getWForm(this.state.documents[processId].w9EnvId, this.state.documents[processId].isForeignCitizen)}

                                  {this.state.documents[processId].isSignSideletter && (this.state.documents[processId].sideletterEnvId && !this.state.documents[processId].isSignSideLetterResend && (investmentProcess.managementFee >= 0)) ?
                                    <>
                                      {this.getSignedSignLetter(this.state.documents[processId].sideletterEnvId)}
                                    </>
                                    :
                                    <>
                                      {investmentProcess && investmentProcess.isFeeAndCarrySet && (investmentProcess.managementFee >= 0) &&
                                        <>
                                          <form onSubmit={this.getFormData} action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${this.getSignSideLettterPowerFormId()}`} method="POST">
                                            <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
                                            <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
                                            <input type="hidden" name="v" id="v" value='2' />
                                            <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
                                            <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
                                            <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
                                            <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
                                            <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
                                            <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='sideletter' />
                                            <input type="hidden" name="investor_UserName" id="investor_UserName" value={this.getUserName(processId)} />
                                            <input type="hidden" name="investor_entityName" id="investor_entityName" value={investment.llcName} />
                                            <input type="hidden" name="investor_Email" id="investor_Email" value={window.localStorage.getItem('emailAddress')} />
                                            <input type="hidden" name="investor_address" id="investor_address" value={investment.investmentprocesses[0].address1} />
                                            <input type="hidden" name="investor_address2" id="investor_address2" value={investment.investmentprocesses[0].address2} />
                                            <input type="hidden" name="investor_managementFee" id="investor_managementFee" value={this.getUserCarry(investment.investmentprocesses[0].managementFee, 1)} />
                                            <input type="hidden" name="investor_managementFeeValue" id="investor_managementFeeValue" value={investment.investmentprocesses[0].managementFee} />
                                            <input type="hidden" name="investor_carry" id="investor_carry" value={this.getUserCarry(investment.investmentprocesses[0].carry, 1)} />
                                            <input type="hidden" name="investor_carryAll" id="investor_carryAll" value={this.getUserCarry(investment.investmentprocesses[0].carry, 2)} />
                                            <input type="hidden" name="EnvelopeField_reSend" id="EnvelopeField_reSend" value={false} />

                                            <button type='submit' className='btn btn-danger'>
                                              {
                                                (this.state.documents[processId].isSignSideLetterResend) ?
                                                  'Re-Sign Side Letter'
                                                  :
                                                  'Sign Side Letter'
                                              }
                                            </button>
                                          </form>
                                        </>
                                      }
                                    </>
                                  }

                                </div>
                              }
                              <div className="description-txt">Once TSG confirms receipt of your funds, you will receive a confirmation email and your investment will move to the Pending TSG Countersignature section of your dashboard.</div>
                              <div className="description-txt">When the entire investment is closed by TSG, you will receive a closing confirmation email and fully executed (countersigned) docs in your dashboard.</div>
                              <div className="col-lg-3 col-md-3 col-xs-12 col-sm-10" onClick={() => { this.openReferFriendModal() }}>
                                <div className="refer-colleague-box">
                                  <img src={path.refer} />
                                  <label>Refer an Investor</label>
                                </div>
                              </div>
                            </div>
                          ) :
                          (
                            <div className="invitations_right">
                              <div className="description-txt">Your documents have been completed – thank you!</div>
                              {
                                this.state.documents[processId].isDocSign &&
                                this.state.documents[processId].isSignSubscriptionAggrement &&
                                <div className='viewDoc'>
                                  {this.getQSA(this.state.documents[processId].subscriptionEnvId)}
                                  {this.getWForm(this.state.documents[processId].w9EnvId, this.state.documents[processId].isForeignCitizen)}

                                  {this.state.documents[processId].isSignSideletter && (this.state.documents[processId].sideletterEnvId && !this.state.documents[processId].isSignSideLetterResend && (investmentProcess.managementFee >= 0)) ?
                                    <>
                                      {this.getSignedSignLetter(this.state.documents[processId].sideletterEnvId)}
                                    </>
                                    :
                                    <>
                                      {investmentProcess && investmentProcess.isFeeAndCarrySet && (investmentProcess.managementFee >= 0) &&
                                        <>
                                          <form onSubmit={this.getFormData} action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${this.getSignSideLettterPowerFormId()}`} method="POST">
                                            <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
                                            <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
                                            <input type="hidden" name="v" id="v" value='2' />
                                            <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
                                            <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
                                            <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
                                            <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
                                            <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
                                            <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='sideletter' />
                                            <input type="hidden" name="investor_UserName" id="investor_UserName" value={this.getUserName(processId)} />
                                            <input type="hidden" name="investor_entityName" id="investor_entityName" value={investment.llcName} />
                                            <input type="hidden" name="investor_Email" id="investor_Email" value={window.localStorage.getItem('emailAddress')} />
                                            <input type="hidden" name="investor_address" id="investor_address" value={investment.investmentprocesses[0].address1} />
                                            <input type="hidden" name="investor_address2" id="investor_address2" value={investment.investmentprocesses[0].address2} />
                                            <input type="hidden" name="investor_managementFee" id="investor_managementFee" value={this.getUserCarry(investment.investmentprocesses[0].managementFee, 1)} />
                                            <input type="hidden" name="investor_managementFeeValue" id="investor_managementFeeValue" value={investment.investmentprocesses[0].managementFee} />
                                            <input type="hidden" name="investor_carry" id="investor_carry" value={this.getUserCarry(investment.investmentprocesses[0].carry, 1)} />
                                            <input type="hidden" name="investor_carryAll" id="investor_carryAll" value={this.getUserCarry(investment.investmentprocesses[0].carry, 2)} />
                                            <input type="hidden" name="EnvelopeField_reSend" id="EnvelopeField_reSend" value={false} />

                                            <button type='submit' className='btn btn-danger'>
                                              {
                                                (this.state.documents[processId].isSignSideLetterResend) ?
                                                  'Re-Sign Side Letter'
                                                  :
                                                  'Sign Side Letter'
                                              }
                                            </button>
                                          </form>
                                        </>
                                      }
                                    </>
                                  }
                                </div>
                              }
                              <div className="description-txt">Your investment is currently pending TSG review.</div>
                              <div className="description-txt">Once approved, you will receive an email confirmation and funding instructions will be available here.</div>
                            </div>
                          )
                    }
                  </div>
                </div>
              }
              {
                // this.state.documents && this.state.documents[processId] && this.state.documents[processId].isManagement &&
                // // this.state.documents[processId].isSignSideletter &&
                // < div className="col-lg-10 col-md-9 col-sm-9">
                //   <div className="card">
                //     <h3>Sign Side Letter</h3>
                //     <div className="invitations_right">
                //       <h4>Please sign Side Letter below.</h4>
                //       <br />
                //       { 
                //         this.state.documents[processId].isSignSideletter && 
                //           (this.state.documents[processId].sideletterEnvId && !this.state.documents[processId].isSignSideLetterResend ) ?
                //           <div className='viewDoc'>
                //             {this.getSignedSignLetter(this.state.documents[processId].sideletterEnvId)}
                //           </div>
                //           :
                //           <>
                //             <form onSubmit={this.getFormData} action={`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/PowerFormSigning.aspx?PowerFormId=${this.getSignSideLettterPowerFormId()}`} method="POST">
                //               <input type="hidden" name="env" id="env" value={process.env.REACT_APP_DOCUSIGN_ENV} />
                //               <input type="hidden" name="acct" id="acct" value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID} />
                //               <input type="hidden" name="v" id="v" value='2' />
                //               <input type="hidden" name="EnvelopeField_userId" id="EnvelopeField_userId" value={window.localStorage.getItem('id')} />
                //               <input type="hidden" name="EnvelopeField_webhookEnv" id="EnvelopeField_webhookEnv" value={process.env.REACT_APP_WEBHOOK_ENV} />
                //               <input type="hidden" name="EnvelopeField_investmentId" id="EnvelopeField_investmentId" value={investment._id} />
                //               <input type="hidden" name="EnvelopeField_companyId" id="EnvelopeField_companyId" value={investment.companyId} />
                //               <input type="hidden" name="EnvelopeField_processId" id="EnvelopeField_processId" value={processId} />
                //               <input type="hidden" name="EnvelopeField_type" id="EnvelopeField_type" value='sideletter' />
                //               <input type="hidden" name="investor_UserName" id="investor_UserName" value={this.getUserName(processId)} />
                //               <input type="hidden" name="investor_Email" id="investor_Email" value={window.localStorage.getItem('emailAddress')} />
                //               <input type="hidden" name="investor_address" id="investor_address" value={investment.investmentprocesses[0].address1} />
                //               <input type="hidden" name="investor_address2" id="investor_address2" value={investment.investmentprocesses[0].address2} />
                //               <input type="hidden" name="investor_managementFee" id="investor_managementFee" value={this.getUserCarry(investment.investmentprocesses[0].managementFee, 1) } />
                //               <input type="hidden" name="investor_managementFeeValue" id="investor_managementFeeValue" value={investment.investmentprocesses[0].managementFee } />
                //               <input type="hidden" name="investor_carry" id="investor_carry" value={this.getUserCarry(investment.investmentprocesses[0].carry, 1)} />
                //               <input type="hidden" name="investor_carryAll" id="investor_carryAll" value={this.getUserCarry(investment.investmentprocesses[0].carry, 2)} />
                //               <input type="hidden" name="EnvelopeField_reSend" id="EnvelopeField_reSend" value={false} />
                //               <button type='submit' className='btn btn-danger'>
                //                 {
                //                   (this.state.documents[processId].isSignSideLetterResend) ? 
                //                     'Re-Sign Side Letter'
                //                   :
                //                     'Sign Side Letter'
                //                 }
                //               </button>
                //             </form>
                //             <br />
                //           </>
                //       }
                //     </div>

                //   </div>
                // </div>
              }
            </div >
          </div >
        </div >
      </>
    );
  }
}
export default Documents;