import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom'
import Dashboard from '../Dashboard'
import Wireinstuction from '../../containers/WireInstruction';
import ClientOnboardChecklist from '../../containers/ClientOnboardChecklist'
import LoginPage from '../LoginPage';
import MasterContactList from '../../components/MasterContactList'
import Profile from '../../containers/Profle';
import ViewProfile from '../../containers/Profle/viewProfile';
import MailGUNTesting from '../../containers/MailGUNTesting';
import AcceptInvitation from '../AcceptInvitation';
import ForgotPassword from '../ForgotPassword';
import FaqPreview from '../../containers/FaqPreview';
import InvestorFaqPreview from '../../containers/investorFaqPreview/investorFaqPreview';
import NdaPdf from '../../containers/NdaPdf';
import ClientEngLetter from '../../containers/ClientEngLetter';
import IIR from '../../containers/IIR';
import FoundLetterPreview from '../FoundLetterPreview';
import StartUpEngagement from '../../containers/StartUpEngagement'
import Preview from '../IIR/Preview';
import FinalPreview from '../IIR/FinalPreview';
import ExecutiveLetter from '../../containers/ExecutiveLetter';
import PrivacyPolicy from '../../containers/PrivacyPolicy/PrivacyPolicy';
import TSGInterestRequestForm from '../TSGInterestRequest/TSGInterestRequestForm';
import YearReview from '../YearReview/YearReview';
import LaunchSCPRequestForm from '../LaunchSCP/LaunchSCPRequestForm';
import InvestmentDetail from '../InvestmentDetail';
import InvestorDashboard from '../../InvestorDashboard/components/App';
import InvestorSentiments from '../../InvestorDashboard/components/PipelineInvestments/InvestorSentiments';
import InvestmentProcess from '../../InvestorDashboard/components/InvestmentProcess/app';
import InvestorInvestmentDetail from '../../InvestorDashboard/InvestmentDetail/App';
import InvestorWalletDetails from '../../InvestorDashboard/Wallet/App';
import CheckList from '../../InvestorDashboard/InvestmentDetail/App';
import IIRPreviewPDF from '../IIR/Preview/iirPreviewPDF';
import PDFViewer from '../IIR/Preview/PDFViewer';
import SignOAPreview from './SignOA';
import GlobalSettings from '../GlobalSettings/GlobalSettings.container';
import InvestorFollowup from '../InvestmentFollowup';
import UnsubscribeEmail from '../../components/UnsubscribeEmail';
import PipelineStartups from '../../PipelineStartups/components/App';
import InvestorSentimentsDetails from '../../PipelineStartups/components/InvestorSentimentsDetailRoot'
import UserRecommendationsDetail from '../../PipelineStartups/components/UserRecommendationDetailRoot'
import StartupDashboard from '../../StartupDashboard/components/App';
import InvestmentVIPAccess from '../InvestmentDetail/InvestmentVIPAccess';
import WireInformationForm from '../../InvestorDashboard/components/WireInformation/WireInformationForm';
import StartupDetail from '../StartupDetail';
import ManagementFeesandCarry from '../../containers/InvestmentDetail/ManagementFees-Carry';
import SummaryStartups from '../../SummaryStartups/containers';
import EmailRedirect from '../Webinar/EmailRedirect';
import WebinarReport from '../Webinar/WebinarReport';
import ExitInvestmentDetails from '../ExitInvestmentDetails';
import Ledger from '../../Ledger/containers';
import AllPipeline from '../../Pipeline/containers';
import NewAddPipeline from '../../Pipeline/containers/newAddPipeline';
import TransactioHistory from '../../InvestorDashboard/transaction-history/containers/index';
import CommunicationFeed from '../InvestmentDetail/Communications/CommunicationFeed'
import InvestmentCommunication from '../InvestmentDetail/Communications/InvestmentCommunication'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        window.localStorage.getItem('isLoggedIn') || window.localStorage.getItem('roleCode') != null
            ? <Component {...props} />
            : <Redirect to='/' />
    )} />
)
function App() {
    return (
        <div>
            <main>
                <Route exact path="/" component={LoginPage} />
                <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
                <Route exact path="/become-investor" component={TSGInterestRequestForm} />
                <Route exact path="/year-in-review" component={YearReview} />
                <Route exact path="/launch-SCP" component={LaunchSCPRequestForm} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/investor-dashboard" component={InvestorDashboard} />
                <PrivateRoute exact path="/startup-dashboard/:id" component={StartupDashboard} />
                <PrivateRoute exact path="/startup-dashboard" component={StartupDashboard} />
                <PrivateRoute exact path="/investor-sentiments" component={InvestorSentiments} />
                <PrivateRoute exact path="/investment-process" component={InvestmentProcess} />
                <PrivateRoute exact path="/investment-detail" component={InvestorInvestmentDetail} />
                <PrivateRoute exact path="/investmentDetail" component={InvestmentDetail} />
                <PrivateRoute exact path="/pipeline-startups" component={PipelineStartups} />
                <PrivateRoute exact path="/investor-sentiments-details" component={InvestorSentimentsDetails} />
                <PrivateRoute exact path="/recommendation-details" component={UserRecommendationsDetail} />
                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute exact path="/check-list" component={CheckList} />
                <PrivateRoute exact path="/viewProfile" component={ViewProfile} />
                <PrivateRoute exact path="/mailgun-testing" component={MailGUNTesting} />
                <PrivateRoute exact path="/contacts" component={MasterContactList} />
                <PrivateRoute exact path="/global-settings" component={GlobalSettings} />
                <PrivateRoute exact path="/investment-settings/:id" component={InvestorFollowup} />
                <PrivateRoute exact path="/startupDetail" component={StartupDetail} />
                <PrivateRoute exact path="/summaryDetail" component={SummaryStartups} />
                <PrivateRoute exact path='/webinar-report' component={WebinarReport} />
                <PrivateRoute exact path='/exit-investment-details/:id/:investorId' component={ExitInvestmentDetails} />
                <PrivateRoute exact path="/ledger" component={Ledger} />
                <PrivateRoute exact path="/pipeline" component={AllPipeline} />
                <PrivateRoute exact path="/pipeline/add" component={NewAddPipeline} />
                <PrivateRoute exact path="/pipeline/add/:cid" component={NewAddPipeline} />

                
                <Route exact path="/acceptInvitation" component={AcceptInvitation} />
                <Route exact path="/forgotPassword" component={ForgotPassword} />
                <Route exact path="/onboardChecklist" component={ClientOnboardChecklist} />
                <Route exact path="/faqPreview" component={FaqPreview} />
                <Route exact path="/investorFaqPreview" component={InvestorFaqPreview} />
                <Route exact path="/wireInstruction" component={Wireinstuction} />
                <Route exact path="/executiveLetter" component={ExecutiveLetter} />
                <Route exact path="/ndaPdf" component={NdaPdf} />
                <Route exact path="/startUpEngagement" component={StartUpEngagement} />
                <Route path='/clientEngLetter' component={ClientEngLetter} />
                <Route path="/iir" component={IIR} />
                <Route path='/foundLetterPreview' component={FoundLetterPreview} />
                <Route path='/preview' component={Preview} />
                <Route path='/previewIIR' component={FinalPreview} />
                <Route path='/iirPreviewPDF' component={IIRPreviewPDF} />
                <Route path='/pdfViewer' component={PDFViewer} />
                <Route path='/signOA' component={SignOAPreview} />
                <Route path='/unsubscribe/:userId' component={UnsubscribeEmail} />
                <Route path='/investmentVIPAccess' component={InvestmentVIPAccess} />
                <Route path='/managementFees-Carry' component={ManagementFeesandCarry} />
                <Route path='/wireInformationForm' component={WireInformationForm} />
                <Route path='/webinar-request' component={EmailRedirect} />
                <Route path='/investorWallet' component={InvestorWalletDetails} />
                <Route path='/transaction-history' component={TransactioHistory}/>
                <Route path='/communication-feed' component={CommunicationFeed}/>
                <Route path='/investment-communication' component={InvestmentCommunication}/>
                <Route path='/mcl-feed' component={CommunicationFeed}/>
                <Route path='/mcl-communication' component={InvestmentCommunication}/>
            </main>
        </div>
    );
}

export default App;

