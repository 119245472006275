import React, { useState, useEffect, useRef, useCallback } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import _ from 'lodash';
import ReactTags from 'react-tag-autocomplete';
import Select from 'react-select';
import LoadingSpinner from '../../components/Loader';
import { isLoading, isNotLoading } from '../../shared/actions/loadingSpinner';
import { setSummaryStartupsData,setSummaryInvestmentData  } from '../actions/summaryStartups';
import SummaryTableData from './SummaryTableData';
import { CSVLink, CSVDownload } from "react-csv";
import ContactSendMessageModal  from '../../components/ContactSendMessageModal';
import TButton from '../../components/TButton';


const SummaryStartupsUserList = props => {

    //const  height = window.innerHeight;
    const { summartList, investment, tsgSetting, sendStrtype } = props;
    //const [ listData, setListData ] = useState([]);
    const [getstrategic, setStrategic] = useState([
            { value: 0, label: 'All' }, 
            { value: 1, label: 'Strategic' },
            { value: 2, label: 'Non-Strategic' },
        ]);
    const [tags, _setTags] = useState([]);
    const [straType, setStraType] = useState(0);
    const [exportData, setExportData] = useState([]);
    const [exportLoading, setExportLoading] = useState(0);
    const pricePerShare = investment.pricePerShare;
    const [myData, setMyData] = useState(summartList);
    const [emailAddress, setEmailAddress] = useState([]);
    const [mobile, setMobile] = useState([]);

    const [allChkEmail, setAllEmailAddress] = useState([]);
    const [allChkMobile, setAllMobile] = useState([]);
    const [checkBoxSelect, setCheckBoxSelect] = useState(false);

    const [getCheckBox, setCheckBox] = useState(false);
    //const [ getSetting, setSetting ] = useState(tsgSetting);    
    
    const [getCheckBoxAll, setCheckBoxAll] = useState(false);
    const [getRemoveCheckBox, setRemoveCheckBox] = useState([]);
    const [getSelectedCheckBox, setSelectedCheckBox] = useState([]);

    const tagsRef = useRef(tags);
    const setTags = x => {
        tagsRef.current = x; // keep updated
        _setTags(x);
    };
    
    if(props.investment.webinarLink1 != undefined && props.investment.webinarLink1 != null) {
        //console.log("---sd--sd--sd-sd",props.investment)
        props.setSummaryInvestmentData(props.investment);
    }
    const csvLink = useRef();

    function handleScroll(){

        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)) {
            
            const newTags = tagsRef.current;          
            if(newTags.length > 0) {
                props.getSummaryList(newTags);
            } else {
                props.getSummaryList();
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, false);
        return () => {
            // remove the event listener
            window.removeEventListener('scroll', handleScroll, false);
        };
    }, []);

    useEffect(() => { 
        const intervalId = setInterval(() => {
            setCheckBoxSelect(true);
        }, 1000);
        return () => clearInterval(intervalId);
    
    },[allChkEmail, allChkMobile]);

    useEffect(() => { 

        const exUserArray = props.exportData;
        const UserArray = (exUserArray.investmentProcess) ? exUserArray.investmentProcess : [];
        if(UserArray.length > 0) {
            let arr = [];

            var userExData = new Promise((resolve, reject) => {
                UserArray.forEach(function(val, index, UserArray) {
                    
                    let managementFeeAmt = (val.managementFee) ? val.managementFee : 0;
                    const managementVal = (val.updateRecord  && (val.updateManagementFee >= 0)) ? val.updateManagementFee : managementFeeAmt
                    const pricePerShare = (val.pricePerShare > 0) ? (val.pricePerShare) : ((val.updatePricePerShare) ? val.updatePricePerShare : val.investment.pricePerShare );
                    const amount = (val.updateAmount) ? val.updateAmount : (val.amount);
                    const netAmt = amount - ( managementVal > 0 ? ((amount * managementVal)/100) : 0 );
                    const sharesVal = (netAmt / pricePerShare);
                    //const sharesVal = (val.updateTotalShare) ? val.updateTotalShare : shares;
                    let strategicVal = (val.user.investorCategories) ? val.user.investorCategories : [];
                    strategicVal = ( strategicVal.includes('Strategic') ? "Strategic" : "Non-Strategic" );

                    let ObjCode = { 
                        'NAME': (val.user.name) ? val.user.name : "",
                        'COMPANY': (val.user.companyName) ? val.user.companyName : val.user.investorCompanyName,
                        'TYPE': strategicVal,
                        'FINAL ALLOTMENT': Number(amount).toFixed(2),
                        'MGMT FEE': managementVal,
                        'CARRY': (val.updateCarry) ? val.updateCarry : (val.carry ? val.carry : 0),
                        'PPS': pricePerShare,
                        'NET INVESTMENT': Number(netAmt).toFixed(2),
                        'SHARES': Number(sharesVal).toFixed(2),
                    };
                    arr.push(ObjCode);
                    if (index === UserArray.length -1) resolve();
                });
                
            });
            userExData.then(() => {
                setExportData(arr);
                csvLink.current.link.click();
            });
        }
    }, [ props.exportData ]);
    

    function tagsArr(tag){
        setTags([].concat(tags, { id: tag, name: tag }));
        const tagss = [].concat(tags, { id: tag, name: tag });
        return tagss;
    }

    const handleDelete = (i) => {
        //console.log("govind", i);

        const newTags = tags.slice(0)
        newTags.splice(i, 1)
        setTags(newTags);
        const reload = 1; 
        props.getSummaryList(newTags, straType, reload);
    }
    
    const handleAddition = (tag) => {
        //console.log("asdsadasd",tag);
        //props.getSummarySearchList(tagsArr(tag));
        const newTags = tagsArr(tag);
        const reload = 1; 
        props.getSummaryList(newTags, straType, reload);
    }

    const handleOnStrategicChanged = (event) => {
        const strType = event.value;
        const newTags = tagsRef.current;
        const reload = 1; 
        const id = window.localStorage.getItem('summaryInvestmentId');
        
        props.history.push( `/investmentDetail?id=${id}&activeTab=summary&type=${strType}` );
        
        setStraType(strType);
        props.getSummaryList(newTags, strType, reload);        
    }

    const getTagsCard = (tags) => {
        return tags.map(item => item.name);
    }

    function resetSMSEmailCard(){
        window.location.reload();
    } 

    function sendMessage(e){
        //console.log(e);
        setAllEmailAddress([]);
        setAllMobile([]);

        let userBlock = (e) ? e : false;
        if( (userBlock) && userBlock.emailAddress != '' && userBlock.emailAddress != 'undefined' && userBlock.emailAddress != null ) {
            setAllEmailAddress(() => [ userBlock.emailAddress ]);
        }
        if( (userBlock) && userBlock.mobile != '' && userBlock.mobile != 'undefined' && userBlock.mobile != null ) {
            setAllMobile(() => [ userBlock.emailAddress ]);
        }
        document.getElementById('openModal-btn').click();
    }

    function exportSummary(){
        props.exportSummary(tags, straType);
    }

    function onCheckedAllSendMessage(event) {
        
        // const selectEmail = [];
        // const allSelectMobile = [];
        setSelectedCheckBox([]);

        if(event.target.checked){
            // summartList.map((val, index) => {
                
            //     let users = val.user;
            //     if( users.emailAddress != '' && users.emailAddress != 'undefined' && users.emailAddress != null ) {
            //         if(!selectEmail.includes(users.emailAddress)) {
            //             selectEmail.push(users.emailAddress)
            //         }    
            //     }

            //     if( users.mobile != '' && users.mobile != 'undefined' && users.mobile != null ) {
            //         if(!allSelectMobile.includes(users.mobile)) {
            //             allSelectMobile.push(users.mobile)
            //         }
            //     }
            // });
            
            // setAllEmailAddress(selectEmail);
            // setAllMobile(allSelectMobile);
            setCheckBoxAll(true);

        } else {
            setCheckBoxAll(false);
            setRemoveCheckBox([]);
            // setAllEmailAddress([]);
            // setAllMobile([]);
        }
    }

    function onSingleSendMessage(e, user) {
        
        //console.log(user);
        const userId = user._id;
        //setRemoveCheckBox 
        if(getCheckBoxAll) {

            if( !getRemoveCheckBox.includes(userId) ) {
                let removeUserId = [...getRemoveCheckBox, userId];
                setRemoveCheckBox(removeUserId);
            } else {
                
                let allSelectCard = [...getRemoveCheckBox];
                var index = allSelectCard.indexOf(userId)
                if (index !== -1) {
                    //console.log("11",allSelectCard);
                    allSelectCard.splice(index, 1);
                    
                    if( allSelectCard.length > 0 ) {
                        setRemoveCheckBox( allSelectCard );
                    } else {
                        setRemoveCheckBox([]);
                    }
                }
            }
        } else {
            if( !getSelectedCheckBox.includes(userId) ) {
                let removeUserId = [...getSelectedCheckBox, userId];
                setSelectedCheckBox(removeUserId);
            } else {
                
                let allSelectCard = [...getSelectedCheckBox];
                var index = allSelectCard.indexOf(userId)
                if (index !== -1) {
                    //console.log("11",allSelectCard);
                    allSelectCard.splice(index, 1);
                    
                    if( allSelectCard.length > 0 ) {
                        setSelectedCheckBox( allSelectCard );
                    } else {
                        setSelectedCheckBox([]);
                    }
                }
            }
        }    

        //console.log(getSelectedCheckBox);

        // setCheckBoxSelect(false);
        // const selectEmail = user.emailAddress;
        // const selectMobile = user.mobile;

        // if(!allChkEmail.includes(selectEmail)) {
        //     let emailCode = [...allChkEmail, selectEmail];
        //     setAllEmailAddress(emailCode);
            
        // } else {
        //     let allSelectCard = allChkEmail;
        //     var index = allSelectCard.indexOf(selectEmail)
        //     if (index !== -1) {
        //         allSelectCard.splice(index, 1);
                
        //         if( allSelectCard.length > 0 ) {
        //             setAllEmailAddress( allSelectCard );
        //         } else {
        //             setAllEmailAddress([]);
        //         }
        //     }
        // }
        
        // if( selectMobile != "" && selectMobile != null && selectMobile != undefined ) {
        //     if(!allChkMobile.includes(selectMobile)) {
        //         let mobileCode = [...allChkMobile, selectMobile];
        //         setAllMobile(mobileCode);
        //     } else {
        //         const allSelectMobile = allChkMobile;
        //         var index = allSelectMobile.indexOf(selectMobile)
        //         if (index !== -1) {
        //             allSelectMobile.splice(index, 1);
                    
        //             if(allSelectMobile.length > 0) {
        //                 setAllMobile(allSelectMobile);
        //             } else {
        //                 setAllMobile([]);
        //             }      
        //         }
        //     }
        // }
    }

    function sendAllMessage(e) {
        document.getElementById('openModal-btn').click();
    }

    return (
        <>  
            <TButton onClick={() => { }} id='openModal-btn' dataTarget={`#sendMessageModal`} dataToggle={`modal`} style={{ display: 'none' }} />
            <ContactSendMessageModal 
                allCheckbox= { [] }
                allSelectMobile = { [] }
                resetSMSEmailCard = { () => resetSMSEmailCard()}
                webinarType="YES"
                getCheckBoxAll = { getCheckBoxAll }
                getRemoveCheckBox = { getRemoveCheckBox }
                getSelectedCheckBox = { getSelectedCheckBox }
                pageLocation = "summary"
                filterVal = { getTagsCard(tagsRef.current) }
                totalCount = { getCheckBoxAll ? summartList.length : getSelectedCheckBox.length }
            />
            {
                <div className='col-md-12 cod-laptop'>
                    <div className='col-xs-12 col-sm-5 filter-bar-status'>
                        <div className='search col-md-7 react-card' style={{ 'padding':'12px 0px','display':'inline-block' }}>
                            <ReactTags
                                allowNew={true}
                                placeholder='Search Investor'
                                tags={tags}
                                handleDelete={(i) => handleDelete(i)}
                                handleAddition={(value) => handleAddition(value.name)}
                            />
                        </div>
                        <div className='follow_up col-md-2'>
                            <div className='follow_up' style={{ 'padding': '12px 0px', 'display':'block' }} >
                                <Select
                                    defaultValue={ getstrategic[sendStrtype] }
                                    id='strategic'
                                    options={ getstrategic }
                                    onChange={(event) => handleOnStrategicChanged(event) }
                                    className="col-md-12 strategic-select padding-boot-remove"
                                />
                            </div>    
                        </div>
                        <div className='follow_up col-md-3'>
                            <div className='follow_up' style={{ 'padding': '12px 0px', 'display':'inline-block' }} >
                                <a href='javascript:void(0)' className="tsg-btn invite exportBtn" onClick={(e) => exportSummary(e) } >
                                    {
                                        (exportLoading > 0) ? 
                                        <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </>
                                        :
                                        <><i className="fa fa-file-text ex-icon" aria-hidden="true" />&nbsp;&nbsp;EXPORT</>
                                    }
                                </a>
                                <CSVLink 
                                    data={ exportData } 
                                    filename={"summary_invesment.csv"}
                                    className="hidden"
                                    ref={ csvLink }
                                    target="_blank"
                                />

                                <button type='button' href='javascript:void(0)' className="tsg-btn invite sendMessBtn" disabled={ ( getCheckBoxAll || getSelectedCheckBox.length > 0 ) ? false : true } onClick={(e) => sendAllMessage(e) } >
                                    SEND MESSAGE
                                </button>
                            </div>    
                        </div>
                    </div>

                    <div className="custom-table-view table-responsive">
                        <table className="table table-custom sortable">
                            <thead>
                                <tr>
                                    <th className='checkbox-th'>
                                        <input
                                            type="checkbox"
                                            name="userCheckbox"
                                            onChange={ (event) => onCheckedAllSendMessage(event) }
                                            className="chkbox-input"
                                            checked={ getCheckBoxAll }
                                        />
                                        <label className='chkbox-lable'>&nbsp; &nbsp;All</label>
                                    </th>
                                    <th>NAME<i className={`arrow down`} ></i></th>
                                    <th>COMPANY</th>
                                    <th>TYPE</th>
                                    <th>FINAL ALLOTMENT</th>
                                    <th>MGMT FEE</th>
                                    <th>CARRY</th>
                                    <th>PPS</th>
                                    <th>NET INVESTMENT</th>
                                    <th>SHARES</th>
                                    <th className='actionTh'>ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {   
                                    ( summartList.length > 0 ) ?  
                                        summartList.map(( val, i ) => {
                                        
                                        let amtInst = (val.amount) ? Number(val.amount).toFixed(2) : 0;   
                                        let strategicVal = (val.user.investorCategories) ? val.user.investorCategories : [];
                                        const mgfee = ( val.isFeeAndCarrySet && val.managementFee >=0) ? val.managementFee : tsgSetting.managementFee;
                                        const mgfeeVal = ( mgfee > 0 ) ?  ( (mgfee / 100) * amtInst ) : 0;
                                        const carry = ( val.isFeeAndCarrySet && val.carry >=0 ) ? val.carry : tsgSetting.carry;
                                        let viewMgFee = (mgfeeVal > 0) ? Number(mgfeeVal).toFixed(2) : "";
                                        const netAmt =  amtInst - mgfeeVal;    
                                        let defaultpps = (val.pricePerShare > 0) ? val.pricePerShare : pricePerShare;
                                        let shares = (defaultpps > 0) ? (netAmt / defaultpps) : netAmt;
                                        shares = Number(shares).toFixed(2);
                                        
                                        return <>    
                                            <SummaryTableData 
                                                key = { "tab_"+i }
                                                name = {  (val.user && val.user.name) ? val.user.name : "" }
                                                companyName = { (val.user.companyName) ? val.user.companyName : val.user.investorCompanyName }
                                                strategicVal = { strategicVal }
                                                pricePerShare = { defaultpps }
                                                shares = { shares }
                                                amtInst = { amtInst }
                                                mgfee = { mgfee }
                                                viewMgFee = { viewMgFee }
                                                carry = { carry }
                                                netAmt = { netAmt }
                                                processId = { val }
                                                sendMessage = { (e) => sendMessage(e) }
                                                getCheckBox = { getCheckBox }
                                                onSingleSendMessage = { (e, user) => onSingleSendMessage(e, user)  }
                                                allChkMobile = { allChkMobile }
                                                allChkEmail = { allChkEmail }
                                                getCheckBoxAll = { getCheckBoxAll }
                                                getRemoveCheckBox = { getRemoveCheckBox }
                                                getSelectedCheckBox = { getSelectedCheckBox }
                                                {...props}
                                            />
                                        </>
                                        })
                                    : 
                                    <tr>
                                        <td colSpan={10} style={ { textAlign: 'center', paddingTop: '11px' } }>
                                        Record Not Found
                                        </td>
                                    </tr>   
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    );
}

//export default SummaryStartupsList;
const mapStateToProps = state => {
    // return {
    //     loadingSpinner: state.loadingSpinner,
    // };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // isLoading: bindActionCreators(isLoading, dispatch),
        // isNotLoading: bindActionCreators(isNotLoading, dispatch),
        // setSummaryStartupsData: bindActionCreators(setSummaryStartupsData, dispatch),
        setSummaryInvestmentData: bindActionCreators(setSummaryInvestmentData, dispatch),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(SummaryStartupsUserList));