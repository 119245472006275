import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import ReactTags from 'react-tag-autocomplete';
import LoadingSpinner from '../../components/Loader';
import './InvestmentDetail.scss';
import NumberFormat from 'react-number-format';
import TButton from '../../components/TButton';
import common from '../../common';
import swal from 'sweetalert';
import axios from 'axios';
import { CSVLink, CSVDownload } from "react-csv";
import ContactSendMessageModal  from '../../components/ContactSendMessageModal';
import { setSummaryInvestmentData  } from '../../SummaryStartups/actions/summaryStartups';
class CompletedInvestmentDetailUsers extends Component {

    constructor(props) {
        super()
        this.state = {
            searchInputTags: [],
            suggestions: [
                { id: 'YPO', name: 'YPO' },
                { id: 'Institutional', name: 'Institutional' },
                { id: 'Strategic', name: 'Strategic' },
                { id: 'Preferred', name: 'Preferred' },
                { id: 'Investment(s)', name: 'Investment(s)' },
                { id: 'Spire One', name: 'Spire One' },
                { id: 'International', name: 'International' },
                { id: 'Federal', name: 'Federal' },
                { id: 'CXO', name: 'CXO' }],
            arrowType: {
                name: 'down',
                emailAddress: 'down',
                company: 'down',
                investmentAmount: 'down',
                createdAt: 'down',
                paymentDate: 'down',
                fundedDate: 'down',
            },
            $sort: null,
            listOfUsers:[],
            exportLoading:false,
        };
        this.csvLink = React.createRef();
    }

    componentDidMount() {
        
        this.props.resetCheckBox();
        this.props.setSummaryInvestmentData(this.props.investmentData);
        if (this.props.id) {
            this.props.history.push(`/investmentDetail?id=${this.props.id}&activeTab=completed`)
        }
        window.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = () => {
        if ((Math.round(window.innerHeight + document.documentElement.scrollTop) === document.documentElement.offsetHeight)
            && (this.props.pagination.currentPage < this.props.pagination.totalPages)) {
            this.props.getUsersByStatus(common.investmentStatuses.complete, true, common.investmentStatuses.complete, this.state.$sort);
        }
    };

    mapDateFormat(mongoDate) {
        if (!mongoDate) return 'N/A';
        const monthNames = [
            "01", "02", "03",
            "04", "05", "06", "07",
            "08", "09", "10",
            "11", "12"
        ];
        let date = new Date(mongoDate);
        let day = date.getUTCDate();
        // let day = date.getDate();
        let monthIndex = date.getUTCMonth();
        // let monthIndex = date.getMonth();
        let year = date.getUTCFullYear();
        // let year = date.getFullYear();
        return monthNames[monthIndex] + '/' + day + '/' + year;

    }
    signDoc(envId) {
        window.open(`${process.env.REACT_APP_DOCUSIGN_HOST}/Member/EmailStart.aspx?m=${envId}`, '_blank');
    }

    onSendDocClick(userId) {
        this.props.sendDocument([{ _id: userId }]);
    }

    viewDocumentRedirect(envId, type, number) {
        window.open(` ${common.path.doc_download_url}/downloadPDF?envId=${envId}&type=${type}&number=${number}`, '_blank', '', 2)
    }
    
    toggleArrow(fieldType) {
        let arrowType = this.state.arrowType;
        let $sort = {};
        if (arrowType[fieldType] === 'down') {
            arrowType[fieldType] = 'up';
            $sort[fieldType] = -1;
        } else {
            arrowType[fieldType] = 'down';
            $sort[fieldType] = 1;
        }
        this.props.getUsersByStatus(common.investmentStatuses.complete, false, common.investmentStatuses.complete, $sort);
        this.setState({ arrowType, $sort });
    }
    mapTags(tags) {
        if (!tags) {
            tags = this.state.searchInputTags;
        }
        let tagIds = []
        if (tags && tags.length > 0) {
            tagIds = tags.map(tag => tag.name)
        }
        return tagIds;
    }
    handleSearchInputDelete(i) {
        const tags = this.state.searchInputTags.slice(0)
        tags.splice(i, 1)
        this.setState({ searchInputTags: tags }, () => {
            this.props.getUsersByStatus(common.investmentStatuses.complete, false, common.investmentStatuses.complete, this.state.$sort, tags);
        });
    }
    handleSearchInputAddition(tag) {
        const tags = [].concat(this.state.searchInputTags, tag);
        this.setState({ searchInputTags: tags }, () => {
            this.props.getUsersByStatus(common.investmentStatuses.complete, false, common.investmentStatuses.complete, this.state.$sort, tags);
        });
    }
    getUserCitizenForm(isForeignCitizen) {
        return isForeignCitizen === true ? 'W8' : 'W9'
    }

    onReSendClick(type, userid, investmentId) {
        swal({
            title: "Are you sure?",
            text: "You want to Re-Send this Doc.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willReSend) => {
                if (willReSend) {
                    let temp = this;
                    let token = window.localStorage.getItem('token');
                    temp.setState({ loading: true });
                    const data = {
                        investmentId: investmentId,
                        userId: userid,
                    };
                    if (type === 'isQSA') {
                        data.isQSAResend = true;
                    }
                    if (type === 'isW8') {
                        data.isW8Resend = true;
                    }
                    if (type === 'isW9') {
                        data.isW9Resend = true;
                    }
                    if (type === 'sideletter' ) {
                        data.isSignSideLetterResend = true;
                    }

                    axios.post(`${common.path.base_url}/reSendDoc`, data, {
                        headers: {
                            "Authorization": token
                        }
                    })
                        .then(function (response) {
                            swal({
                                title: "Updated!",
                                text: response.data.message,
                                icon: "success",
                                button: "Ok!",
                            }).then(() => { 
                                window.location.reload();
                            });
                        })
                        .catch(function (error) {
                            swal({
                                title: "Error!",
                                text: error.response.data.message,
                                icon: "error",
                                button: "Ok!",
                            });
                            temp.setState({ loading: false, loadinglada2: false });
                        });
                }
            });
    }

    investmentFundedExportCSV() {
        
        let tokenpass = window.localStorage.getItem('token');
        const data = {
            investmentId:this.props.id
        }
        const tags = this.mapTags();
        if (tags && tags.length > 0) {
            data.tags = tags;
        }
        
        this.setState({ listOfUsers: [], exportLoading:true });

        let getCSVUrl = null;
        axios.post(`${common.path.base_url}/getInvestmentFundedExportCSV`, data, {
            headers: {
                "Authorization": tokenpass
            }
        
        }).then((response) => {
            const UserArray = response.data.data;
            //console.log(UserArray);
            //const UserArray = contacts.userArray;
            if(UserArray.length > 0) {
                let arr = [];
                var userExData = new Promise((resolve, reject) => {
                    UserArray.forEach(function(val, index, UserArray) {
                        
                        let dateCom = (val.fundedDate) ? (val.fundedDate) : "-";
                        let dateCreated = (val.createdAt) ? (val.createdAt) : "-";

                        let ObjCode = { 'First Name': (val.firstName) ? val.firstName : "",
                                        'Middle Name': (val.middleName) ? val.middleName : "",
                                        'Last Name': (val.lastName) ? val.lastName : "",     
                                        Email: (val.emailId) ? val.emailId : "",
                                        'Date': dateCreated, 
                                        Company: (val.company) ? val.company : "-",
                                        Amount: (val.formattedAmount) ? "$"+val.formattedAmount : "$0.0",
                                        'Payment Type': (val.paymentType) ? val.paymentType : "", 
                                        'Confirmation Date': dateCom, 
                                        Tags: (val.user.investorCategories) ? val.user.investorCategories.join(', ') : "",
                                    };
                        arr.push(ObjCode);
                        if (index === UserArray.length -1) resolve();
                    });
                    
                });
                userExData.then(() => {
                    this.setState({ listOfUsers: arr, exportLoading:false });
                    this.csvLink.current.link.click();
                });
            } else {

                swal({
                    title: "Export",
                    text: "Data not found.",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                });
                this.setState({ listOfUsers: [], exportLoading:false });
            }

        }).catch(function (error) {
            console.log(error);
            this.setState({ listOfUsers: [], exportLoading:false });
        });
    }

    sendAllMessage() {
        document.getElementById('openModal-btn').click();
    }
    
    resetSMSEmailCard() {
        window.location.reload();
    }

    render() {
        const areCheckboxActive = this.props.getCheckedCompletedUsers().length > 0 ? true : false;
        //console.log(this.props.getCheckedCompletedUsers());

        let emailArr = [];
        let mobileArr = [];
        
        if( this.props.getCheckedCompletedUsers().length > 0 ) {
            
            this.props.getCheckedCompletedUsers().map(userLoop => {
                const users = userLoop.user;
                if( users.emailAddress != '' && users.emailAddress != 'undefined' && users.emailAddress != null ) {
                    emailArr.push( users.emailAddress );
                }

                if( users.mobile != '' && users.mobile != 'undefined' && users.mobile != null ) {
                    mobileArr.push( users.mobile );
                }
                
            });
            //this.setState({ allChkEmail : emailArr, allChkMobile : mobileArr });
        }

        return (
            <div className="box-wrapper-main">

                <TButton onClick={() => { }} id='openModal-btn' dataTarget={`#sendMessageModal`} dataToggle={`modal`} style={{ display: 'none' }} />
                <ContactSendMessageModal 
                    allCheckbox= { emailArr }
                    allSelectMobile = { mobileArr }
                    resetSMSEmailCard = { () => this.resetSMSEmailCard()}
                    webinarType="YES"
                    getCheckBoxAll = { this.props.getCheckBoxAll }
                    getRemoveCheckBox = { this.props.getRemoveCheckBox }
                    getSelectedCheckBox = { this.props.getSelectedCheckBox }
                    pageLocation = "funded"
                    totalCount = { this.props.getCheckedCompletedUsers().length }
                />

                <div className='col-xs-12 col-sm-5 search-status allInvestmentDetail header-investment' style={{ display: 'contents' }}>
                    <div className='search' style={{ width: '65%', padding: 15, display:'inline-block', float:'left' }}>
                        <ReactTags
                            allowNew={true}
                            placeholder='Search'
                            suggestions={this.state.suggestions}
                            tags={this.state.searchInputTags}
                            handleDelete={this.handleSearchInputDelete.bind(this)}
                            handleAddition={this.handleSearchInputAddition.bind(this)} />
                    </div>
                    <div className='follow_up' style={{ width: '12%', display:'inline-block', padding:'17px' }}>
                        <div className='follow_up' style={{ width: '100%' }}>
                            <a onClick={() => this.investmentFundedExportCSV()} className="tsg-btn invite export-btn" >
                                {
                                    this.state.exportLoading ? 
                                    <><i className="fa fa-spinner fa-spin ex-icon" aria-hidden="true" />Loading </>
                                    :
                                    <><i className="fa fa-file-text ex-icon" aria-hidden="true" />EXPORT</>
                                }
                            </a>
                            <CSVLink 
                                data={ this.state.listOfUsers } 
                                filename={"investment-funded.csv"}
                                className="hidden"
                                ref={this.csvLink}
                                target="_blank"
                            />
                        </div>
                    </div>
                    <div style={{ width: '10%', display:'inline-block', padding:'20px 10px' }} >
                        <TButton
                            text='Send/Resend Selected'
                            className='invite-btn'
                            onClick={() => this.props.sendDocumentMultipleUser()}
                            dataTarget={`#followUpModal`}
                            dataToggle={`modal`}
                            disabled={!areCheckboxActive}
                            style={{ padding:'20px 10px' }}
                        />    
                    </div>
                    <div style={{ width: '10%', display:'inline-block', marginBottom:'16px' }} >
                        <button type='button' href='javascript:void(0)' className="tsg-btn invite sendMessBtn" onClick={(e) => this.sendAllMessage(e) } disabled={!areCheckboxActive} style={{  marginLeft: '10px' }} >
                            SEND MESSAGE
                        </button>
                    </div>
                </div>
                <div className="custom-table-view table-responsive">
                    <table className="table table-custom sortable">
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(event) => this.props.onCheckedAllCompletedUsers(event)}
                                        checked={this.props.isAllUserByStatusChecked}
                                    />
                                    <label>&nbsp; &nbsp;All</label>
                                </th>
                                <th>NAME<i className={`arrow ${this.state.arrowType['name']}`} onClick={() => this.toggleArrow('name')}></i></th>
                                <th>EMAIL<i className={`arrow ${this.state.arrowType['emailAddress']}`} onClick={() => this.toggleArrow('emailAddress')}></i></th>
                                <th>DATE<i className={`arrow ${this.state.arrowType['createdAt']}`} onClick={() => this.toggleArrow('createdAt')}></i></th>
                                <th>COMPANY<i className={`arrow ${this.state.arrowType['company']}`} onClick={() => this.toggleArrow('company')}></i></th>
                                <th>AMOUNT<i className={`arrow ${this.state.arrowType['investmentAmount']}`} onClick={() => this.toggleArrow('investmentAmount')}></i></th>
                                <th>PAYMENT DATE<i className={`arrow ${this.state.arrowType['paymentDate']}`} onClick={() => this.toggleArrow('paymentDate')}></i></th>
                                <th>MEMO/#</th>
                                <th>TYPE</th>
                                <th>CONFIRMATION DATE<i className={`arrow ${this.state.arrowType['fundedDate']}`} onClick={() => this.toggleArrow('fundedDate')}></i></th>
                                <th>View Documents</th>
                                <th>CANCEL</th>
                                <th>
                                    {/* <TButton
                                        text='Send/Resend Selected'
                                        className='invite-btn'
                                        onClick={() => this.props.sendDocumentMultipleUser()}
                                        dataTarget={`#followUpModal`}
                                        dataToggle={`modal`}
                                        disabled={!areCheckboxActive}
                                    /> */}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.userByStatus.length > 0 &&
                                this.props.userByStatus.map(completeUser => {
                                    //console.log(completeUser);
                                    return (
                                        <tr key={completeUser._id}>
                                            <td>
                                                {
                                                    completeUser.isSubcriptionComplete &&
                                                    <input
                                                        type="checkbox"
                                                        onChange={(event) => this.props.onCheckedSingleCompletedUsers(event, completeUser.user._id)}
                                                        checked={completeUser.isChecked}
                                                    />
                                                }
                                            </td>
                                            <td className="username-row"
                                                // onClick={() => this.props.history.push(`/viewProfile?id=${completeUser.user._id}`)}
                                                onClick={() => { this.props.showDataInPopup(completeUser.user._id) }}>
                                                {`${completeUser.firstName} ${completeUser.lastName}`}
                                            </td>
                                            <td>
                                                {completeUser.emailId}
                                            </td>
                                            <td>
                                                {this.mapDateFormat(completeUser.createdAt)}
                                            </td>
                                            <td>

                                                {completeUser.investorCompanyName ? completeUser.investorCompanyName : completeUser.companyName ? completeUser.companyName : ''}
                                            </td>
                                            <td>
                                                <NumberFormat
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                    value={completeUser.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                                <br />
                                                <a style={{ color: '#ef534a', cursor: 'pointer' }} onClick={() => this.props.adjustExceedAmount(completeUser)}>ADJUST</a> &nbsp; &nbsp;
                                            </td>

                                            <td>
                                                {this.mapDateFormat(completeUser.paymentDate)}
                                            </td>
                                            <td> {completeUser.memoNo}</td>
                                            <td>{completeUser.paymentDate && completeUser.paymentType ? completeUser.paymentType.toUpperCase() : 'N/A'}</td>
                                            <td>
                                                {this.mapDateFormat(completeUser.fundedDate)}
                                            </td>
                                            <td>
                                                {
                                                    completeUser.subscriptionEnvId &&
                                                    <>
                                                        <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(completeUser.subscriptionEnvId, 'Subscription', 1)}>View QSA Signed Doc.</a> <br />
                                                        {/* <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.onReSendClick('isQSA', completeUser.user._id, completeUser.investmentId)}>| Re-Send</a> */}
                                                    </>
                                                }
                                                {
                                                    completeUser.subscriptionEnvId &&
                                                    <>
                                                        <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(completeUser.w9EnvId, this.getUserCitizenForm(completeUser.isForeignCitizen), 2)}>
                                                            {
                                                                completeUser.isForeignCitizen ? 'View W-8 Signed Doc.' : 'View W-9 Signed Doc.'
                                                            }
                                                        </a><br />
                                                    </>
                                                }
                                                {   
                                                    completeUser.sideletterEnvId &&
                                                    <>
                                                        <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.viewDocumentRedirect(completeUser.sideletterEnvId, 'sideletter', 2)}>
                                                            {
                                                                completeUser.sideletterEnvId ? 'View Side Letter' : 'View Side Letter Signed Doc.'
                                                            }
                                                        </a>
                                                        { completeUser.isSignSideletterComplete ? "" : 
                                                            <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.onReSendClick('sideletter', completeUser.user._id, completeUser.investmentId)}>| Re-Send</a>
                                                        }

                                                    </>
                                                }
                                                {/* <a style={{ color: '#F15B4B', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => this.onReSendClick(completeUser.isForeignCitizen ? 'isW8' : 'isW9', completeUser.user._id, completeUser.investmentId)}>| Re-Send</a> */}
                                            </td>
                                            <td>
                                                <TButton text='CANCEL' className='invite-btn'
                                                    onClick={() => this.props.onCancelInvestment(completeUser._id, 'completed')}
                                                />
                                            </td>
                                            <td>
                                                {
                                                        !completeUser.isSignByAdmin ?
                                                            <TButton style={{ backgroundColor: '#3d465c', color: 'white', padding: '15px 3px' }} text={`SIGN DOC`} className='invite-btn' onClick={() => { this.signDoc(completeUser.subscriptionEnvId) }} /> :
                                                            completeUser.isDocSend ?
                                                                <TButton style={{ backgroundColor: 'gray', color: 'white', padding: '18px 3px' }} text={`RESEND DOC`} className='invite-btn' onClick={() => { this.onSendDocClick(completeUser.user._id) }} /> :
                                                                <TButton style={{ padding: '18px 0px' }} text={`NOTIFY OF CLOSING`} className='invite-btn' onClick={() => { this.onSendDocClick(completeUser.user._id) }} />

                                                }

                                                {   
                                                    completeUser.sideletterEnvId &&
                                                    (!completeUser.isSignSideletterComplete && !completeUser.isSignSideLetterResend ) ?
                                                    <>
                                                        <br></br>
                                                        <TButton style={{ backgroundColor: '#3d465c', color: 'white', marginTop:'10px', padding: '18px 4px' }} text={`SIGN SIDE LETTER`} className='invite-btn' onClick={() => { this.signDoc(completeUser.sideletterEnvId) }} />
                                                    </>
                                                    : ""
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                this.props.userByStatus.length === 0 &&
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td> No investors found for invite!</td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        setSummaryInvestmentData: bindActionCreators(setSummaryInvestmentData, dispatch),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(CompletedInvestmentDetailUsers));
