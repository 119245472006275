import axios from "axios";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import common from "../../../common";
import InvestorDashboardDocuments from "../../shared/components/InvestorDashboardDocuments.component";
import { getCompanyLogoFromS3 } from "../../shared/helper";
import "./NewInvitations.scss";
import { getActiveStatusClass } from "./helper";

const getText = (data) => {
  const { isQSAResend, isW9Resend, isW8Resend, isSignSideLetterResend } = data;
  let reSignText = "";

  if (isQSAResend) {
    reSignText = "Re-Sign QSA";
  }
  if (isW9Resend) {
    if (reSignText !== "") {
      reSignText = " & W9";
    } else {
      reSignText = "Re-Sign W9";
    }
  }
  if (isW8Resend) {
    if (reSignText !== "") {
      reSignText = " & W8";
    } else {
      reSignText = "Re-Sign W8";
    }
  }
  if (isSignSideLetterResend) {
    if (reSignText !== "") {
      reSignText = " & Side Letter";
    } else {
      reSignText = "Re-Sign Side Letter";
    }
  }
  return (reSignText = "Re-Sign Documents");
};

const mapDateFormat = (mongoDate) => {
  if (!mongoDate) return null;
  const monthNames = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  let date = new Date(mongoDate);
  let day = date.getDate();
  let monthIndex = date.getMonth();
  let year = date.getFullYear();
  return monthNames[monthIndex] + "/" + day + "/" + year;
};

const getSignSideLettterPowerFormId = () => {
  let powerFormID = process.env.REACT_APP_SIDE_LETTER_ID;
  return powerFormID;
};

const getUserCarry = (carry, type) => {
  let carryVal = carry;
  if (type == 2) {
    carryVal = 100 - carry;
  }
  carryVal = carryVal;
  return carryVal;
};

const Participate = (props) => {
  const [investmentData, setInvestmentData] = useState(null);

  const { invitation, fromToDo, cardHeader } = props;

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    axios
      .get(
        `${common.path.base_url}/getUserInvestmentProcessById/${invitation.userId}/${invitation.investmentId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        const investment = response.data.data.invitedInvestments[0] || null;
        setInvestmentData(investment); // Update the state with investment data
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message &&
          common.AUTH_EXPIRED === error.response.data.message
        ) {
          window.localStorage.clear();
          window.location.href = "/";
          return;
        } else if (error.response) {
          swal({
            title: "Error!",
            text: error.response.data.message,
            icon: "error",
            button: "Ok!",
          });
        } else {
          swal({
            title: "Network Error!",
            text:
              error.response &&
                error.response.data &&
                error.response.data.message
                ? error.response.data.message
                : error.message,
            icon: "error",
            button: "Ok!",
          });
        }
      });
  }, [invitation.userId, invitation.investmentId]);

  return (
    <div className={fromToDo ? '' : "card"} id={`invitation_${invitation.investmentId}`}>
      <div className="row card-content">
        <div className="img-left">
          <img
            src={getCompanyLogoFromS3(invitation)}
            // src="https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.1788614524.1703203200&semt=ais"
            alt=""
            className="image-size"
          />
        </div>
        <div className="col-lg-9 col-md-9 col-sm-9 col-9">
          <div className="card-header">
            {invitation.investmentName}
          </div>
          <h6>
            {investmentData && invitation.isDocSign && (
              <div style={{ fontSize: "12px", marginTop: 2 }}>
                {investmentData.minAmount && (
                  <>
                    Min:{" "}
                    <NumberFormat
                      value={investmentData.minAmount}
                      thousandSeparator={true}
                      prefix={"$"}
                      fixedDecimalScale={true}
                      displayType={"text"}
                    />
                  </>
                )}
                {investmentData.maxAmount && (
                  <>
                    {" "}
                    / Max:{" "}
                    <NumberFormat
                      value={investmentData.maxAmount}
                      thousandSeparator={true}
                      prefix={"$"}
                      fixedDecimalScale={true}
                      displayType={"text"}
                    />
                  </>
                )}
              </div>
            )}
          </h6>

          {investmentData && invitation.isDocSign && (
            <div style={{ fontSize: "12px" }}>
              (Variance Requests:{" "}
              <a href="mailto:platform@syndicategroup.com">
                <u>platform@syndicategroup.com</u>
              </a>
              )
            </div>
          )}

          <h5 style={{ color:"#000000"}}>
            {invitation.isShowToInvestor && "Investment Deadline :"}{" "}
            {invitation.isShowToInvestor &&
              mapDateFormat(invitation.investmentDeadlineDate)}
          </h5>
          {/* {invitation.isShowToInvestor && mapDateFormat(invitation.investmentDeadlineDate)} */}
          {/* {invitation.isShowToInvestor && (invitation.investmentDeadlineDate && (<h5>{new Date(invitation.investmentDeadlineDate)}</h5>))} */}
        </div>
      </div>

      <div className="row" style={{ marginTop: "10px" }}>
        <div className="col-lg-12 col-md-12 col-12" style={{ padding: 0 }}>
        {invitation &&
            (invitation.isQSAResend === true ||
              invitation.isW8Resend === true ||
              invitation.isW9Resend === true ||
              invitation.isSignSideLetterResend === true) && (
              <div className="resend-content">
                <button
                  className="btn btn-danger"
                  onClick={() => props.reSendDocClick(invitation)}
                >
                  {getText(invitation)}
                </button>
              </div>
            )}
          <div className="d-flex flex-row">

            <div
              className="col-sm-4 col-lg-4 col-md-4 col-3"
              style={{ display: "flex", alignItems: "center" }}
              onClick={() =>
                !props.adminAccess &&
                props.redirectToInvestmentProcess(invitation.investmentId)
              }
            >
              <img src={getActiveStatusClass(invitation, "document")} alt="" />
              <b style={{ paddingLeft: '5px' }}>Documents</b>
            </div>

            <div
              className="col-sm-4 col-lg-4 col-md-4 col-3"
              style={{ display: "flex", alignItems: "center" }}
              onClick={() =>
                !props.adminAccess &&
                props.redirectToInvestmentProcess(invitation.investmentId)
              }
            >
              <img src={getActiveStatusClass(invitation, "execute")} alt="" />
              <b style={{ paddingLeft: '5px' }}>Execute</b>
            </div>

            <div
              className="col-sm-4 col-lg-4 col-md-4 col-3"
              style={{ display: "flex", alignItems: "center" }}
              onClick={() =>
                !props.adminAccess &&
                props.redirectToInvestmentProcess(invitation.investmentId)
              }
            >
              <img src={getActiveStatusClass(invitation, "fund")} alt="" />
              <b style={{ paddingLeft: '5px' }}>Fund</b>
            </div>
          </div>
          {invitation.isSignSideletter &&
            invitation.sideletterEnvId &&
            invitation.isSignSideLetterResend ? (
            ""
          ) : !invitation.isSignSideletterComplete ? (
            ""
          ) : (
            <>
              <form
                action={`${process.env.REACT_APP_DOCUSIGN_HOST
                  }/Member/PowerFormSigning.aspx?PowerFormId=${getSignSideLettterPowerFormId()}`}
                method="POST"
              >
                <input
                  type="hidden"
                  name="env"
                  id="env"
                  value={process.env.REACT_APP_DOCUSIGN_ENV}
                />
                <input
                  type="hidden"
                  name="acct"
                  id="acct"
                  value={process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID}
                />
                <input type="hidden" name="v" id="v" value="2" />
                <input
                  type="hidden"
                  name="EnvelopeField_userId"
                  id="EnvelopeField_userId"
                  value={window.localStorage.getItem("id")}
                />
                <input
                  type="hidden"
                  name="EnvelopeField_webhookEnv"
                  id="EnvelopeField_webhookEnv"
                  value={process.env.REACT_APP_WEBHOOK_ENV}
                />
                <input
                  type="hidden"
                  name="EnvelopeField_investmentId"
                  id="EnvelopeField_investmentId"
                  value={invitation.investmentId}
                />
                <input
                  type="hidden"
                  name="EnvelopeField_companyId"
                  id="EnvelopeField_companyId"
                  value={invitation.companyId}
                />
                <input
                  type="hidden"
                  name="EnvelopeField_processId"
                  id="EnvelopeField_processId"
                  value={invitation._id}
                />
                <input
                  type="hidden"
                  name="EnvelopeField_type"
                  id="EnvelopeField_type"
                  value="sideletter"
                />
                <input
                  type="hidden"
                  name="investor_entityName"
                  id="investor_entityName"
                  value={
                    invitation.llcName
                      ? invitation.llcName
                      : invitation.investmentName
                  }
                />
                <input
                  type="hidden"
                  name="investor_UserName"
                  id="investor_UserName"
                  value={window.localStorage.getItem("username")}
                />
                <input
                  type="hidden"
                  name="investor_Email"
                  id="investor_Email"
                  value={window.localStorage.getItem("emailAddress")}
                />
                <input
                  type="hidden"
                  name="investor_address"
                  id="investor_address"
                  value={invitation.address1}
                />
                <input
                  type="hidden"
                  name="investor_address2"
                  id="investor_address2"
                  value={invitation.address2}
                />
                <input
                  type="hidden"
                  name="investor_managementFee"
                  id="investor_managementFee"
                  value={getUserCarry(invitation.managementFee, 1)}
                />
                <input
                  type="hidden"
                  name="investor_managementFeeValue"
                  id="investor_managementFeeValue"
                  value={invitation.managementFee}
                />
                <input
                  type="hidden"
                  name="investor_carry"
                  id="investor_carry"
                  value={getUserCarry(invitation.carry, 1)}
                />
                <input
                  type="hidden"
                  name="investor_carryAll"
                  id="investor_carryAll"
                  value={getUserCarry(invitation.carry, 2)}
                />
                <input
                  type="hidden"
                  name="EnvelopeField_reSend"
                  id="EnvelopeField_reSend"
                  value={false}
                />
                <div className="resend-content">
                  <button type="submit" className="btn btn-danger">
                    Sign Side Letter
                  </button>
                </div>
              </form>
              <br />
            </>
          )}
        </div>
      </div>
      <InvestorDashboardDocuments {...props} />
    </div>
  );
};
export default Participate;
